import ajax from "../plugins/ajax_request";

export default {
  getCountryStoresActive() {
    return ajax.getCountryStoresActive();
  },
  // eslint-disable-next-line no-unused-vars
  getStores({ state }, cc) {
    return ajax.getStores(cc);
  },
  // eslint-disable-next-line no-unused-vars
  async sendSendGridMail(state, { data, emailFrom, emailTo }) {
    // eslint-disable-next-line no-unused-vars
    let res = await ajax.sendSendGridMail(
      data,
      emailFrom,
      emailTo,
      process.env.VUE_APP_SENDGRID_SHARE_MAIL_TEMPLATEID,
      process.env.VUE_APP_SENDGRID_API_KEY
    );
    // console.log("SEND MAIL", res);
  },

  //TODO: attivare questa funzione e toglierla dal menu

  // makeQRCode(state, { id }) {
  //   let QRCode = require("qrcode");
  //   let url = window.location.href;
  //   let id1 = url.split("/");
  //   if (parseInt(id1[id1.length - 1]) > 0) {
  //     id1.pop();
  //     state.qrcodeUrl = id1.join("/") + "/" + id;
  //     return QRCode.toDataURL(`${id1.join("/")}/${id}`);
  //   }
  //   state.qrcodeUrl = window.location.href + id;
  //   return QRCode.toDataURL(window.location.href + id);
  // },

  async setIdForUrl({ state, getters }) {
    let json = JSON.stringify(getters.getActiveConfiguration);
    let res = await ajax.setCustomConfigurations(json);
    state.id_for_url = res.data;
    // state.qrcodeUrl = "http://localhost:8080/" + res.data;
    // state.qrcode = await getters.makeQRCode(res.data);
  },

  // eslint-disable-next-line no-unused-vars
  async getCustomConfigurations({ state, commit }, id) {
    let res = await ajax.getCustomConfigurations(id);
    // console.log("getCustomConfigurations", res.data);
    commit("restoreJsonConfiguration", res.data);
  },
};
