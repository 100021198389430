import _ from 'lodash';
import ajax from '../../plugins/ajax_request';

const state = () => ({
  allColors: []
})

const getters = {
  checkErrors(state, getters, rootState) {
    let errors = [];

    if (_.isEmpty(rootState.coloreAste)) {
      errors.push({
        label: 'color',
        message: 'Il colore è obbligatorio'
      })
    }

    if (_.isEmpty(rootState.finituraAste)) {
      errors.push({
        label: 'finishing',
        message: 'La finitura è obbligatoria'
      })
    }

    return errors;
  }
}

const mutations = {}

const actions = {
  async getAllColors({ state }) {
    let res = await ajax.getTemplesColors();
    if (res.status >= 200 && res.status < 300) {
      state.allColors = res.data;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}