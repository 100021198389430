import Vue from "vue";
import jslinq from "jslinq";
// import ajax from '../plugins/ajax_request'

export default {
  setFrontal(state, item) {
    state.loadingGeometry = true;
    state.frontale = item;
  },

  resetConfiguration(state, { forma, colore, sizeDescription }) {
    if (sizeDescription && state.configurator.frontali !== undefined) {

      let regularSize = state.configurator.frontali.itemsSizes.filter(size => {
        return size.size_description == sizeDescription &&
          size.U_THE_MODELLO == forma;
      })

      state.configurator.calibro = (regularSize[0].size_eye);
      state.configurator.naso = (regularSize[0].size_bridge);

    } else {
      state.configurator.calibro = '';
      state.configurator.naso = '';
    }

    state.configurator.asianFitting = false;
    if (state.configurator.frontali != undefined) {
      const c = jslinq(state.configurator.frontali.colors);
      const cfd = c.firstOrDefault((col) => {
        return col.U_THE_COLORE == colore;
      });
      state.configurator.colore = cfd;
    } else {
      state.configurator.colore = null;
    }
    state.configurator.finitura = "S";
    state.configurator.misuraAste = '145';
    if (state.configurator.aste != undefined) {
      const ca = jslinq(state.configurator.aste.allColors);
      let cad = ca.firstOrDefault((col) => {
        return (
          col.U_THE_COLORE == "PL0001" &&
          col.U_THE_LUNGASTA == state.configurator.misuraAste
        );
      });
      state.configurator.coloreAste = cad;
    } else {
      state.configurator.coloreAste = null;
    }
    state.configurator.coloreMetalloAste = "";
    state.configurator.finituraAste = "S";
    // TODO: verificare come resettare le variabili di seguito
    if (state.configurator.frontali) {
      state.configurator.frontali.name = "";
      state.configurator.frontali.nameFont = "Batang";
      state.configurator.frontali.nameColor = "";
    }
    if (state.configurator.aste) {

      state.configurator.aste.extSxName = '';
      state.configurator.aste.extSxNameColor = '';
      state.configurator.aste.extSxNameFont = 'Batang';
      state.configurator.aste.intSxName = '';
      state.configurator.aste.intSxNameColor = '';
      state.configurator.aste.intSxNameFont = 'Batang';
      state.configurator.aste.extDxName = '';
      state.configurator.aste.extDxNameColor = '';
      state.configurator.aste.extDxNameFont = 'Batang';
      state.configurator.aste.intDxName = '';
      state.configurator.aste.intDxNameColor = '';
      state.configurator.aste.intDxNameFont = 'Batang';
    }

    (state.configurator.note = "");

    if (state.configurator.frontali != undefined) {

      // if (forma.indexOf('S') >= 0 && state.configurator.glassType == 'V') {
      //   forma = forma.replace('S', '');
      // }

      const f = jslinq(state.configurator.frontali.items);
      let fd = f.firstOrDefault((front) => {
        return front.U_THE_MODELLO == forma;
      });
      state.configurator.frontale = fd;

    }
  },

  setFrontali(state, f) {
    state.frontali = f;
  },
  // setAste(state, a) {
  //   state.aste = a;
  // },
  setSize(state, size) {
    /*state.calibro = Math.ceil(size.size_eye);
    state.naso = Math.ceil(size.size_bridge);*/
    // let s = size.label.split("/");
    state.calibro = parseInt(size.size_eye);
    state.naso = parseInt(size.size_bridge);
  },
  setAsianFitting(state, af) {
    state.asianFitting = af;
  },
  setCatColore(state, cC) {
    state.catColore = cC.Category;
  },
  setFinishing(state, f) {
    state.finitura = f;
  },
  setColore(state, c) {
    state.colore = c;
  },
  clearSize: (state) => {
    state.naso = "";
    state.calibro = "";
  },
  setTemplesColor(state, tc) {
    state.coloreAste = tc;
  },
  setTemplesMetalColor(state, c) {
    state.coloreMetalloAste = c;
  },
  unsetTemplesMetalColor(state) {
    state.coloreMetalloAste = null;
  },
  // setFinituraAste(state, f) {
  //   state.finituraAste = f;
  // },
  setTemplesFinishing(state, f) {
    state.finituraAste = f;
  },
  setTemplesMaterial(state, m) {
    state.templesMaterial = m;
  },
  setTemplesSize: (state, size) => {
    state.misuraAste = size;
  },
  setRowNotes: (state, note) => {
    state.note = note;
  },
  resetRowNotes: (state) => {
    state.note = "";
  },
  setName: (state, name) => {
    state.nome = name;
  },
  setFontNome: (state, font) => {
    state.fontNome = font;
  },
  setColoreNome: (state, color) => {
    state.coloreNome = color;
  },
  setNameIntAstaSx: (state, name) => {
    state.nomeIntAstaSx = name;
  },
  setFontNameIntAstaSx: (state, font) => {
    state.fontNomeIntAstaSx = font;
  },
  setColorNameIntAstaSx: (state, color) => {
    state.coloreNomeIntAstaSx = color;
  },
  setNameExtAstaSx: (state, name) => {
    state.nomeExtAstaSx = name;
  },
  setFontNameExtAstaSx: (state, font) => {
    state.fontNomeExtAstaSx = font;
  },
  setColorNameExtAstaSx: (state, color) => {
    state.coloreNomeExtAstaSx = color;
  },
  setNameIntAstaDx: (state, name) => {
    state.nomeIntAstaDx = name;
  },
  setFontNameIntAstaDx: (state, font) => {
    state.fontNomeIntAstaDx = font;
  },
  setColorNameIntAstaDx: (state, color) => {
    state.coloreNomeIntAstaDx = color;
  },
  setNameExtAstaDx: (state, name) => {
    state.nomeExtAstaDx = name;
  },
  setFontNameExtAstaDx: (state, font) => {
    state.fontNomeExtAstaDx = font;
  },
  setColorNameExtAstaDx: (state, color) => {
    state.coloreNomeExtAstaDx = color;
  },
  startLoadingGeometry: (state) => {
    state.loadingGeometry = true;
  },
  stopLoadingGeometry: (state) => {
    state.loadingGeometry = false;
  },
  /*User mutations*/
  setUser(state, user) {
    state.user = user;
    Vue.cookie.set("_identity", state.user.auth_key, 30);
  },

  saveConfiguration: (state) => {
    let c = {
      frontale: state.frontale, //modello frontale
      calibro: state.calibro,
      naso: state.naso,
      asianFitting: state.asianFitting,
      colore: state.colore,
      finitura: state.finitura,
      coloreAste: state.coloreAste,
      coloreMetalloAste: state.coloreMetalloAste,
      finituraAste: state.finituraAste,
      misuraAste: state.misuraAste,
      nome: state.nome,
      coloreNome: state.coloreNome,
      fontNome: state.fontNome,
      nomeExtAstaSx: state.nomeExtAstaSx,
      coloreNomeExtAstaSx: state.coloreNomeExtAstaSx,
      fontNomeExtAstaSx: state.fontNomeExtAstaSx,
      nomeIntAstaSx: state.nomeIntAstaSx,
      coloreNomeIntAstaSx: state.coloreNomeIntAstaSx,
      fontNomeIntAstaSx: state.fontNomeIntAstaSx,
      nomeExtAstaDx: state.nomeExtAstaDx,
      coloreNomeExtAstaDx: state.coloreNomeExtAstaDx,
      fontNomeExtAstaDx: state.fontNomeExtAstaDx,
      nomeIntAstaDx: state.nomeIntAstaDx,
      coloreNomeIntAstaDx: state.coloreNomeIntAstaDx,
      fontNomeIntAstaDx: state.fontNomeIntAstaDx,
      note: state.note,
    };

    state.configurations.push(c);
  },
  restoreConfiguration: (state, id) => {
    let cfg = state.configurations[id];

    state.frontale = cfg.frontale; //modello frontale
    state.calibro = cfg.calibro;
    state.naso = cfg.naso;
    state.asianFitting = cfg.asianFitting;
    state.colore = cfg.colore;
    state.finitura = cfg.finitura;
    state.coloreAste = cfg.coloreAste;
    state.coloreMetalloAste = cfg.coloreMetalloAste;
    state.finituraAste = cfg.finituraAste;
    state.misuraAste = cfg.misuraAste;
    state.nome = cfg.nome;
    state.coloreNome = cfg.coloreNome;
    state.note = cfg.note;
  },
  restoreJsonConfiguration: (state, cfg) => {
    state.frontale = cfg.frontale; //modello frontale
    state.calibro = cfg.calibro;
    state.naso = cfg.naso;
    state.asianFitting = cfg.asianFitting;
    state.colore = cfg.colore;
    state.finitura = cfg.finitura;
    state.coloreAste = cfg.coloreAste;
    state.coloreMetalloAste = cfg.coloreMetalloAste;
    state.finituraAste = cfg.finituraAste;
    state.misuraAste = cfg.misuraAste;
    state.nome = cfg.nome;
    state.coloreNome = cfg.coloreNome;
    state.fontNome = cfg.fontNome;
    state.nomeExtAstaDx = cfg.nomeExtAstaDx;
    state.nomeExtAstaSx = cfg.nomeExtAstaSx;
    state.nomeIntAstaDx = cfg.nomeIntAstaDx;
    state.nomeIntAstaSx = cfg.nomeIntAstaSx;
    state.coloreNomeExtAstaDx = cfg.coloreNomeExtAstaDx;
    state.fontNomeExtAstaDx = cfg.fontNomeExtAstaDx;
    state.coloreNomeExtAstaSx = cfg.coloreNomeExtAstaSx;
    state.fontNomeExtAstaSx = cfg.fontNomeExtAstaSx;
    state.coloreNomeIntAstaDx = cfg.coloreNomeIntAstaDx;
    state.fontNomeIntAstaDx = cfg.fontNomeIntAstaDx;
    state.coloreNomeIntAstaSx = cfg.coloreNomeIntAstaSx;
    state.fontNomeIntAstaSx = cfg.fontNomeIntAstaSx;
    state.note = cfg.note;
  },
  removeConfiguration: (state, id) => {
    let fConfigurations = state.configurations.filter(function (cfg, index) {
      return index != id;
    });
    state.configurations = fConfigurations;
  },
  clearAllConfigurations(state) {
    state.configurations = [];
  },
  setConfirm(state) {
    state.confirm = !state.confirm;
  },
  setQuantity(state, q) {
    state.quantity = q;
  },
  setIsOnSite(state, s) {
    state.isOnSite = s;
  },
  setNavMenuVisible(state, nm) {
    state.navMenuVisible = nm;
  }
};
