import frontale from "./Frontale";
import colore from "./Colore";
import aste from "./Aste";
import testo from "./Testo";
// import lentiSole from "./LentiSole";
import riepilogo from "./Riepilogo";
import WindowClose from "vue-material-design-icons/WindowClose.vue";

export default {
  frontale,
  colore,
  aste,
  testo,
  // lentiSole,
  riepilogo,
  WindowClose,
};
