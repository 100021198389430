/**@module store/user */
import ajax from "../../plugins/ajax_request";
import _ from "lodash";
import Vue from "vue";
// import router from "../../router";
import axios from "axios";

const state = () => ({
  user: {},
  orderHistory: [],
  orderStatus: [],
  orderDetails: [],
  historyLoading: true,
  percentage: 0,
});

const getters = {
  isGuest: (state) => {
    return _.isEmpty(state.user);
  },
};

const mutations = {
  setLoginCookie(state) {
    ajax.setAuthHeader(state.user.auth_key);
    Vue.$cookies.set("_identity", state.user.auth_key, "30d");
  },
  removeLoginCookie() {
    Vue.$cookies.remove("_identity");
  },
};

const actions = {
  async login({ state, commit, dispatch }, { username, password }) {
    let response = await ajax.login(username, password);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      !response.data.error
    ) {
      state.user = response.data;
      commit("setLoginCookie");
      dispatch("getUserCart");
    }

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  getUserData: async function({ state, dispatch }) {
    if (Vue.$cookies.isKey("_identity")) {
      let identity = await Vue.$cookies.get("_identity");
      ajax.setAuthHeader(identity);
      let res = await ajax.getUserData();
      if (res.data) {
        state.user = res.data;
        dispatch("getUserCart");
        // commit('setLoginCookie');
      }
    }
  },
  getUserCart: ({ rootState }) => {
    if (!_.isEmpty(rootState.cart)) {
      ajax.getUserCart().then((response) => {
        rootState.cart.cartRows = response.data;
      });
    } else {
      console.warn("[Configurator] - Il modulo carrello non è disponibile");
    }
  },
  getUserHistory: async function({ state }) {
    if (state.user) {
      let history = await ajax.getUserHistory();
      // console.log("history from store", history.data);
      // for (let i = 0; i < history.data.length; i++) {
      //   const element = history.data[i];
      //   let status = await ajax.getOrdersStatus(history.data[i].ID);
      //   let details = await ajax.getOrderDetails(history.data[i].ID);
      //   element.status = [];
      //   element.status = status.data;
      //   element.details = [];
      //   element.details = details.data;
      //   let p = round(100 / history.data.length, 0);
      //   state.percentage = state.percentage + p;
      // }
      if (history.data) {
        state.orderHistory = history.data;
        state.historyLoading = false;
      }
    }
  },
  /**
   *
   * Recupera il country code dell'utente, se loggato viene recuperato dal database
   * altrimenti viene rilevato in base all'IP di connessione
   * @returns {string} country code dell'utente
   */
  getCountry: async function() {
    let a = axios.create(),
      country;
    delete a.defaults.headers.common["site-id"];
    delete a.defaults.headers.common["line-id"];
    delete a.defaults.headers.common["Authorization"];

    country = await ajax.getUserCountryInfo();

    return country.data[0];
  },
  /**
   * Effettua il logout cancellando dai cookies l'auth_key e inizializza
   * store.user ad un oggetto vuoto
   * @param {Object} store global state
   * @param {Function} function for commit mutations
   */
  logout: async ({ state, commit }) => {
    let res = await ajax.logout();
    if (res.data == 1) {
      state.user = {};
      commit("removeLoginCookie");
      // completare con eventuali azioni che riportano alla pagina di login
      // router.push("/");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
