var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"frontale"}},[_c('div',{staticClass:"arrow-left",class:{ hidden: _vm.windowWidth < 768 }},[_c('img',{attrs:{"src":require('../../../assets/images/icone/freccia.png')},on:{"click":function($event){return _vm.scrollLeft()}}})]),_c('div',{staticClass:"arrow-right",class:{ hidden: _vm.windowWidth < 768 }},[_c('img',{attrs:{"src":require('../../../assets/images/icone/freccia.png')},on:{"click":function($event){return _vm.scrollRight()}}})]),_c('el-row',{staticClass:"filtri"},[_c('el-col',{attrs:{"xs":{ span: 24, offset: 0 },"sm":{ span: 24, offset: 0 },"lg":{ span: 24, offset: 0 }}},[_c('el-col',{staticClass:"title",attrs:{"span":24}},[_vm._v(_vm._s(_vm.$t("message.Misura")))]),_vm._l((_vm.modelSizes),function(size){return _c('el-col',{key:size.size_description,staticClass:"misure",class:{
          active:
            ((size.size_eye) + "/" + (size.size_bridge)) == (_vm.calibro + "/" + _vm.naso),
        },attrs:{"span":8}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.scegliMisura(size)}}},[_c('span',[_vm._v(_vm._s(_vm._f("sizeLabel")(size)))])])],1)})],2)],1),_c('div',{staticClass:"modelli"},_vm._l((_vm.filteredFront),function(item){return _c('el-col',{key:item.U_THE_MODELLO,staticClass:"modello-singolo",class:{
        active:
          _vm.frontale != null
            ? item.U_THE_MODELLO == _vm.frontale.U_THE_MODELLO
            : '',
      },on:{"click":function($event){return _vm.setFrontal(item)}}},[_c('div',{on:{"click":function($event){return _vm.setFrontal(item)}}},[_c('model-image',{attrs:{"model":item.U_THE_MODELLO}})],1),_c('span',{on:{"click":function($event){return _vm.setFrontal(item)}}},[_vm._v(_vm._s(item.U_THE_MODELLO))])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }