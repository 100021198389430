import Vue from "vue";
import Vuex from "vuex";
// import VueCookie from "vue-cookie";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import cart from "./modules/cart";
import user from "./modules/user";
import aste from "./modules/aste";
import frontali from "./modules/frontali";

Vue.use(Vuex);
// Vue.use(VueCookie);

export default new Vuex.Store({
  state: {
    frontale: "", //modello frontale
    calibro: "",
    naso: "",
    asianFitting: false, //colore frontale
    colore: "",
    catColore: "SOLIDI",
    finitura: "S", //finitura frontale
    coloreAste: "",
    coloreMetalloAste: "", //codice items dell'asta gun o gold
    finituraAste: "S",
    templesMaterial: "PL",
    misuraAste: "145",
    nome: "",
    fontNome: "Batang", //nome personalizzato
    coloreNome: "nessun-colore", //colore nome personalizzato
    nomeExtAstaSx: "",
    fontNomeIntAstaSx: "Batang",
    coloreNomeExtAstaSx: "nessun-colore",
    nomeIntAstaSx: "",
    fontNomeExtAstaSx: "Batang",
    coloreNomeIntAstaSx: "nessun-colore",
    nomeExtAstaDx: "",
    fontNomeIntAstaDx: "Batang",
    coloreNomeExtAstaDx: "nessun-colore",
    nomeIntAstaDx: "",
    fontNomeExtAstaDx: "Batang",
    coloreNomeIntAstaDx: "nessun-colore",
    note: "",
    confirm: false,
    quantity: 1,
    id_for_url: "",
    qrcode: "",
    qrcodeUrl: "",
    //loadingGeometry: false, //si attiva durante il caricamento di una geometria
    // cartRows: [],
    configurations: [],
    animateToFront: false,
    animateToTemple: false,
    // user: {},
    // checkoutSuccess: false,
    // checkingOut: false, //true quando in attesa di risposta per checkout
    // frontali: [], // raccoglie i dati dei frontali comprensivi di prezzi
    // aste: [],
    isOnSite: false,
    navMenuVisible: false,
  },
  mutations: mutations,
  getters: getters,
  actions: actions,
  modules: {
    cart: cart,
    user: user,
    aste: aste,
    frontali: frontali,
  },
});
