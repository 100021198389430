<template>
  <div id="colore">
    <div class="arrow-left" :class="{ hidden: windowWidth < 768 }">
      <img :src="require('../../../assets/images/icone/freccia.png')" @click="scrollLeft()" />
    </div>
    <div class="arrow-right" :class="{ hidden: windowWidth < 768 }">
      <img :src="require('../../../assets/images/icone/freccia.png')" @click="scrollRight()" />
    </div>

    <el-row class="filtri">
      <el-col v-if="windowWidth > 1201" :span="24">
        <el-col class="finitura" :lg="{ span: 5, offset: 1 }">
          <el-col class="title" :span="24">{{
      $t("message.Finitura").toUpperCase()
    }}</el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'S' }">
            <el-button @click="setFin('S')" type="text">{{
      $t("message.lucido").toUpperCase()
    }}</el-button>
            <el-divider v-if="finitura === 'N'" direction="vertical"></el-divider>
          </el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'M' }">
            <el-button @click="setFin('M')" type="text">{{
      $t("message.opaco").toUpperCase()
    }}</el-button>
            <el-divider v-if="finitura === 'S'" direction="vertical"></el-divider>
          </el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'N' }">
            <el-button @click="setFin('N')" type="text">{{
      $t("message.Naturale") | upperCase
    }}</el-button>
          </el-col>
        </el-col>
        <el-col :lg="{ span: 10, offset: 1 }" style="height: 100%">
          <el-col :lg="{ span: 24 }" class="color-filter-wrapper">
            <img :class="{ activeFilter: filtroHavana }" class="color-filter" style="object-fit: contain"
              src="/images/icone/circlehavana.png" @click="() => {
        filtroColori = '';
        filtroHavana = !filtroHavana;
      }
      " />
            <img class="color-filter" :class="{ activeFilter: filter == filtroColori }" @click="() => {
        filtroHavana = false;
        filtroColori = filtroColori == filter ? '' : filter;
      }
      " v-for="(filter, index) in filtriColori" :key="index"
              :src="'/images/icone/circle' + filter.nome + '.svg'" alt="" />
          </el-col>
          <!-- <el-col
            class="valore cat"
            :class="{
              active:
                checkboxCategorie != ''
                  ? cat == checkboxCategorie
                  : cat == catColore,
            }"
            v-for="(cat, i) in categories"
            :key="i"
            :span="4"
          >
            <el-button
              @click="(category = { i, cat }), (checkboxCategorie = cat)"
              type="text"
              >{{ cat | demi }}</el-button
            >
            <el-divider
              v-if="
                i != category.i - 1 &&
                i != category.i &&
                i != categories.length - 1
              "
              direction="vertical"
            ></el-divider>
          </el-col> -->
        </el-col>
        <el-col style="
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          " :lg="{ span: 5, offset: 1 }">
          <el-input v-model="findColor" :placeholder="$t('message.Cerca')" prefix-icon="el-icon-search"></el-input>
        </el-col>
      </el-col>

      <el-col v-else :span="24">
        <div style="display: flex; justify-content: center">
          <el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 0 }">
            <el-input v-model="findColor" :placeholder="$t('message.Cerca')" prefix-icon="el-icon-search"></el-input>
          </el-col>
        </div>
        <el-col class="finitura" :xs="{ span: 24, offset: 0 }" :sm="{ span: 6, offset: 2 }">
          <el-col class="title" :span="24">{{
      $t("message.Finitura").toUpperCase()
    }}</el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'S' }">
            <el-button @click="setFin('S')" type="text">{{
      $t("message.lucido")
    }}</el-button>
            <el-divider v-if="finitura === 'N'" direction="vertical"></el-divider>
          </el-col>
          <!-- <el-divider direction="vertical"></el-divider> -->
          <el-col class="valore" :span="8" :class="{ active: finitura == 'M' }">
            <el-button @click="setFin('M')" type="text">{{
      $t("message.opaco")
    }}</el-button>
            <el-divider v-if="finitura === 'S'" direction="vertical"></el-divider>
          </el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'N' }">
            <el-button @click="setFin('N')" type="text">{{
      $t("message.Naturale") | upperCase
    }}</el-button>
          </el-col>
        </el-col>
        <el-col class="categoria" :xs="{ span: 24, offset: 0 }" :sm="{ span: 13, offset: 1 }">
          <el-col :xs="{ span: 24 }" :sm="{ span: 0 }" class="title" :span="24">{{
      $t("message.Categoria_colore").toUpperCase() }}</el-col>
          <el-col :lg="{ span: 24 }" class="color-filter-wrapper">
            <img :class="{ activeFilter: filtroHavana }" class="color-filter" style="object-fit: contain"
              src="/images/icone/circlehavana.png" @click="() => {
        filtroColori = '';
        filtroHavana = !filtroHavana;
      }
      " />
            <img class="color-filter" :class="{ activeFilter: filter == filtroColori }" @click="() => {
        filtroHavana = false;
        filtroColori = filtroColori == filter ? '' : filter;
      }
      " v-for="(filter, index) in filtriColori" :key="index"
              :src="'/images/icone/circle' + filter.nome + '.svg'" alt="" />
          </el-col>
        </el-col>
      </el-col>
    </el-row>
    <div class="colori">
      <div class="color" v-for="color in filteredColors" :key="color.U_THE_COLORE">
        <div class="img-colori" @click="setCol(color)">
          <el-avatar :class="{
      active:
        color.U_THE_COLORE ==
        $store.state.configurator.colore.U_THE_COLORE,
      white: color.U_THE_COLORE == 'EP0009',
    }" :src="baseUrlColor + color.U_THE_COLORE + '.jpg'" :size="100" />
          <span class="span-colori" :class="{ white: color.U_THE_COLORE == 'EP0009' }">{{ color.PLASTICA }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jslinq from "jslinq";
// import _ from "lodash";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      cercaColore: "",
      checkboxCategorie: [],
      category: "",
      windowWidth: window.innerWidth,
      // finitura: "S",
      filtroColori: "",
      filtroHavana: false,
      findColor: "",
      filtriColori: [
        {
          nome: "pink",
          max: 345,
          min: 300,
          style: {
            background: "#ff00ff",
          },
        },
        {
          nome: "purple",
          max: 299,
          min: 239,
          style: {
            background: "#8000ff",
          },
        },
        {
          nome: "blue",
          max: 238,
          min: 190,
          style: {
            background: "#0000ff",
          },
        },
        {
          nome: "green",
          max: 189,
          min: 75,
          style: {
            background: "#00ff00",
          },
        },
        {
          nome: "yellow",
          max: 74,
          min: 48,
          //background:'#ffff00',
          style: {
            background: "#ffff00",
          },
        },
        {
          nome: "orange",
          max: 47,
          min: 14,
          style: {
            background: "#ff8000",
          },
        },
        {
          nome: "red",
          max: 13,
          min: 0,
          style: {
            background: "#ff0000",
          },
        },
        {
          nome: "black&white",
          max: -1,
          min: -2,
          style: {
            background:
              "linear-gradient(to bottom right, #FFFFFF 50%, #000000 50%)",
            // "border-top-color": "black",
            // "border-left-color": "black",
          },
        },
      ],
    };
  },
  mounted: async function () {
    // await this.getColors();
  },

  methods: {
    // ...mapActions("frontali", ["getColors"]),
    getColors() {
      this.$store.dispatch("configurator/frontali/getColors");
    },
    ...mapMutations(["setCatColore"]),

    setColore(c) {
      this.$store.commit("configurator/setColore", c);
    },
    setFinishing(f) {
      this.$store.commit("configurator/setFinishing", f);
    },

    scrollLeft() {
      let container = document.getElementsByClassName("colori");
      container[0].scrollLeft -= 250;
    },

    scrollRight() {
      let container = document.getElementsByClassName("colori");
      container[0].scrollLeft += 250;
    },

    setCol(val) {
      this.setColore(val);
      this.setCatColore(val);
    },

    setFin(val) {
      this.finitura = val;
      this.setFinishing(val);
    },
  },
  computed: {
    // ...mapState("frontali", ["colors"]),
    colors() {
      return this.$store.state.configurator.frontali.colors;
    },
    // ...mapState(["catColore"]),
    baseUrlColor() {
      return `${process.env.VUE_APP_COLORS_URL}`;
    },

    queryColors() {
      return jslinq(this.colors);
    },

    filteredColors() {
      let q = this.findColor;
      let c = this.checkboxCategorie;
      let f = this.filtroColori;
      let result = this.colors;

      if (q) {
        result = result.filter((color) => {
          return color.PLASTICA.indexOf(q) > -1;
        });
      } else {
        if (c.length > 0) {
          if (result) {
            result = result.filter((color) => {
              return c.indexOf(color.Category) >= 0;
            });
          }
        }

        if (f != "") {
          result = result.filter((color) => {
            return color.Order <= f.max && color.Order >= f.min;
          });
        }

        if (this.filtroHavana) {
          result = result.filter((color) => {
            return color.Category == "HAVANA";
          });
        }
      }


      // if (this.cercaColore && this.cercaColore != "") {
      //   col = col.where((c) => {
      //     return c.U_THE_COLORE.indexOf(this.cercaColore) >= 0;
      //   });
      // }

      // return col.take(30).toList();
      return result;
    },

    categories() {
      return this.queryColors
        .select((col) => {
          return col.Category;
        })
        .distinct()
        .toList();
    },

    finitura: {
      get() {
        return this.$store.state.configurator.finitura;
      },

      set(val) {
        return this.setFinishing(val);
      },
    },

    catColore: {
      get() {
        return this.$store.state.catColore;
      },

      set(val) {
        return this.setCatColore(val);
      },
    },
  },
};
</script>

<style lang="less">
@media (max-width: 767px) {
  #colore {
    overflow: auto;
    background-color: @--color-text-secondary;
  }
}

#colore {
  .filtri {
    .finitura {
      position: relative;

      .el-divider {
        position: relative;
        left: 40px;

        &.hidden {
          display: none;
        }
      }
    }

    @media (max-width: 767px) {
      .categoria {
        height: auto !important;
        padding-top: 5%;
        padding-bottom: 5%;
      }
    }

    .havana {
      height: 100%;
      display: flex;
      align-items: center;

      .el-button {
        border: 1px solid gray;

        &.enabled {
          border: 1px solid #038eaa;
          color: #038eaa;
        }
      }
    }

    .color-filter-wrapper {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      &::before,
      &::after {
        display: none;
      }

      .color-filter {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 1em;
        display: flex;
        cursor: pointer;
        opacity: 50%;
        transition: all 0.4s ease;

        &.activeFilter {
          opacity: 100%;
        }
      }
    }

    .categoria {
      // padding-top: 5%;
      height: 100%;
      position: relative;

      .valore.cat {
        min-width: 150px;
        width: 180px;
      }

      .valore {
        min-width: 144px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;

        .el-select {
          width: 100%;

          .el-input--suffix {
            .el-select__caret::before {
              color: #fff;
            }

            .el-input__inner {
              text-align: center;
              border: 0px;
              background: #038eaa;
              color: #fff;
            }
          }
        }

        .el-divider {
          position: absolute;
          right: 0px;
          // float: right;
          top: 10px;
          margin: 0;
        }
      }
    }

    .title {
      border-bottom: 2px solid @--color-primary;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 991px) {
    .filtri {
      .finitura {
        position: relative;

        .el-divider {
          position: relative;
          left: 20px;

          &.hidden {
            display: none;
          }
        }
      }

      .color-filter-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .color-filter {
          padding: 0.4em;
          width: 46px;
          height: 46px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .colori {
      flex-wrap: wrap;
      height: 100%;
      overflow-x: unset !important;
      // justify-content: center;
      background-color: @--color-text-secondary;
      padding-bottom: 10px;
      padding-left: 1%;
      // padding-right: 25px;

      .color {
        margin-top: 10px;
        margin-bottom: 10px;

        .img-colori {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .colori {
      height: 80%;
      padding-left: 75px;
      padding-right: 75px;

      .color {
        justify-content: center;

        .img-colori {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }

  .colori::-webkit-scrollbar {
    display: none;
  }

  .colori {
    overflow-x: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    .color {
      display: flex;
      flex-direction: column;

      .img-colori {
        cursor: pointer;
        width: 100px;
        height: 100px;
        position: relative;

        span.el-avatar.el-avatar--circle {
          transition: all 0.125s ease;

          &.white {
            border: solid black 1px;
          }
        }

        span.el-avatar.el-avatar--circle.active {
          box-shadow: inset 0 0 0 4px @--color-white;
          border: solid @--color-warning 7px;
          // margin: 0 auto;
          padding: 3%;

          // width: 16em;
          // height: 16em;
          // position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .span-colori {
          position: relative;
          top: -60px;
          color: @--color-white;

          &.white {
            color: @--color-primary;
          }
        }
      }
    }

    img {
      width: 100px;
    }
  }
}

.el-select-dropdown {
  margin: 0 !important;

  .el-select-dropdown__item {
    border-bottom: 1px solid #000;
    height: 40px;
    line-height: 40px;
  }

  .el-select-dropdown__item.hover {
    background-color: #038eaa !important;
    color: #fff;
  }

  .el-scrollbar__view.el-select-dropdown__list {
    padding: 0;
  }

  .el-scrollbar__bar.is-horizontal {
    height: 0;
  }

  .el-popper {
    .popper__arrow {
      border-bottom-color: transparent;
    }

    .popper__arrow::after {
      border-bottom-color: transparent;
    }
  }
}
</style>