<template>
  <el-row id="lenti-sole">
    <!-- <el-row
      type="flex"
      align="middle"
      class="header_content-tabs-pane cust_header"
    >
      <el-col :xs="{ span: 24 }" :sm="{ span: 12 }">
        Non selezionando le lenti l'occhiale verrà prodotto con le lenti di
        presentazione biodegradabili
      </el-col>
      <el-col :xs="{ span: 24 }" :sm="{ span: 12 }">
        <el-input
          class="nome-cognome"
          v-model="note"
          :placeholder="$t('message.Riferimento')"
          size="small"
          clearable
          @change="rif"
        ></el-input>
        <p>{{$t('message.Inserire_un_riferimento')}}</p>
      </el-col>
    </el-row>-->
    <el-row class="cust_content">
      <div class="header"></div>

      <el-col
        :xs="{ span: 8 }"
        :sm="{ span: 6 }"
        :md="{ span: 4 }"
        v-for="lente in lentiSole"
        class="lente-sole"
        :key="lente.id"
      >
        <span
          v-if="lenteSole && lenteSole.ItemCode == lente.ItemCode"
          id="checkmark"
          :class="{ not_checked: lenteSole == null }"
        ></span>
        <img :src="lente.imageUrl" @click="setSunLensesModel(lente)" />
        <img
          :src="require('../../../assets/images/icone/GREY NON POLAR_REFLECITONS.png')"
          class="reflex"
          @click="setSunLensesModel(lente)"
        />
        <span class="lenti-nome">
          {{
          lente.ItemName.replace("LENTE SOLE", "")
          .replace("SOLID", "").replace("NON POLARIZZATA", "").replace("GRADIENT", "SFUMATA").replace("GRAD", "SFUMATA")
          }}
        </span>
      </el-col>
    </el-row>
  </el-row>
</template>

<script >
  // import Name from '@/components/Name.vue';

  export default {
    // components: {
    //   Name
    // },

    data() {
      return {
        // imagePath: process.env.VUE_APP_PUBLIC_PATH,
        rifCliente: "",
      };
    },

    methods: {
      // note() {
      //   this.$store.commit("configurator/setRowNotes", n);
      //   // this.$store.commit("configurator/setRowNotes", `Rif. ${n}`);
      // },

      // rif(val) {
      //   if (val.indexOf("Rif.") < 0 && this.note != "") {
      //     this.setRowNotes(`Rif. ${val}`);
      //   }
      // },

      // setRowNotes(n) {
      //   this.$store.commit("configurator/setRowNotes", n);
      // },

      setSunLensesModel(l) {
        this.$store.commit("configurator/setSunLensesModel", l);
      },

      resetRowNotes() {
        this.$store.commit("resetRowNotes");
      },
    },

    computed: {
      // tipoLenti() {
      //   return this.$store.state.configurator.glassType;
      // },
      lentiSole() {
        return this.$store.getters["configurator/lenti/sunLenses"];
      },
      lenteSole() {
        return this.$store.state.configurator.lenteSole;
      },
      // note: {
      //   get() {
      //     return this.$store.state.configurator.note;
      //   },
      //   set(n) {
      //     this.$store.commit("configurator/setRowNotes", n);
      //   },
      // },
      // lensImageUrl(lens) {
      //   return process.env.VUE_APP_COLORS_URL + lens.ItemCode + ".jpg";
      // },
    },

    watch: {
      // rifCliente(nVal) {
      //   if (nVal.length > 0) {
      //     this.setRowNotes(`Rif. ${this.rifCliente}`);
      //   } else {
      //     this.resetRowNotes();
      //   }
      // }
    },

    // @Watch("rifCliente")
    // rifClienteChanged(nVal: string): void {
    //   if (nVal.length > 0) {
    //     this.setRowNotes(`Rif. ${this.rifCliente}`);
    //   } else {
    //     this.resetRowNotes();
    //   }
    // }
  };
</script>

<style lang="less">
  #lenti-sole {
    height: 100%;

    // .cust_header {
    //   background-color: @--color-text-secondary !important;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   margin-top: 10px;
    //   margin-bottom: 5px;
    //   height: 25%;
    //   font-size: 11px;
    //   color: #8d8d8d;

    //   .nome-cognome.el-input {
    //     margin-top: 15px;
    //     border-bottom: 1px solid #ccc;
    //   }

    //   p {
    //     color: #6f6f6f;
    //     margin-top: 1px;
    //     padding: 0px;
    //     font-size: 11px;
    //   }
    // }

    .cust_content {
      height: 75%;
      //margin-top: 20px;
      flex-flow: column;
      overflow-y: scroll;

      .header {
        height: 30px;
        background-color: @--color-text-secondary;
      }

      .lente-sole {
        height: 90px;
        // width: 15%;
        // background-color: bisque;
        display: flex;
        flex-direction: column;
        // align-content: center;
        // justify-content: flex-start;
        background-color: @--color-text-secondary;
        position: relative;
        border-top: solid white 2px;
        // border-bottom: solid white 2px;
        // border-left: solid white 2px;
        border-right: solid white 2px;
        position: relative;

        #checkmark {
          position: absolute;
          top: 0.5em;
          left: 80%;
        }
        img {
          height: 55%;
          max-width: 100%;
          border-radius: 50px;
          cursor: pointer;
          padding: 3%;
          display: flex;
          align-self: center;
        }
        .reflex {
          position: absolute;
          //left: -20px;
        }
        .lenti-nome {
          // width: 45%;
          font-size: 10px;
          padding: 1%;
        }
      }
    }

    .cust_content::-webkit-scrollbar {
      display: none;
    }
  }

  @media @smallTablet, @tablet {
    #lenti-sole {
      height: 100%;

      .cust_header {
        height: 15%;
      }

      .cust_content {
        height: 60%;
      }
    }
  }

  @media @mobile {
    #lenti-sole {
      height: 100%;

      .cust_header {
        height: 15%;
      }

      .cust_content {
        height: 55%;
        top: 12%;
        .lente-sole {
          height: 85px;

          img {
            height: 62%;
          }

          .lenti-nome {
            // width: 45%;
            font-size: 8px;
            padding: 1%;
          }
        }
      }
    }
  }

  @media @monitor {
    #lenti-sole {
      height: 100%;

      .cust_header {
        height: 25%;
      }

      .cust_content {
        height: 70%;
      }
    }
  }
</style>
