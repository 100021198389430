<template>
  <div id="app">
    <div class="logo" style="z-index: 100">
      <img alt="si:ju" src="./assets/images/logo_siju.jpg" />
    </div>
    <!-- <div id="nav-menu" v-show="$store.state.navMenuVisible === true"></div> -->
    <div
      :class="{
        hidden:
          $store.state.navMenuVisible === false &&
          windowWidth < 768 &&
          $route.path == '/',
      }"
      id="nav-menu"
      v-show="isOnSite == false"
    >
      <ul class="list-menu-nav">
        <li>
          <div class="img-div" @click="$router.push('/')">
            <Pencil v-if="$route.path == '/'" fillColor="#038EAA" :size="32" />
            <Pencil v-else :size="32" />
          </div>
        </li>
        <li>
          <div class="img-div" @click="loginDialogVisible = true">
            <Account v-if="userIsGuest" :size="32" />
            <Account v-else fillColor="#038EAA" :size="32" />
          </div>
        </li>
        <li v-if="!userIsGuest">
          <div class="img-div" @click="$router.push('/carrello')">
            <el-badge :value="rowNumber" class="badge">
              <Cart
                :size="32"
                fillColor="#038EAA"
                v-if="$route.path == '/carrello'"
              />
              <Cart :size="32" v-else />
            </el-badge>
          </div>
        </li>
        <li v-if="!userIsGuest">
          <div class="img-div" @click="$router.push('/storico')">
            <History
              v-if="$route.path == '/storico'"
              :size="32"
              fillColor="#038EAA"
            />
            <History v-else :size="32" />
          </div>
        </li>
      </ul>
    </div>
    <div id="router-nav">
      <!-- Use keep-alive to maintain the state of all elements when navigating between views -->
      <!-- This allows the Canvas3D component not to be reinitialized every time the user goes back to the configurator page -->
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <login-dialog
      :visible="loginDialogVisible"
      @close="loginDialogVisible = false"
    />
  </div>
</template>

<script>
import LoginDialog from "./components/LoginDialog.vue";
// eslint-disable-next-line no-unused-vars
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";

import Pencil from "vue-material-design-icons/PencilOutline.vue";
import Account from "vue-material-design-icons/Account.vue";
import Cart from "vue-material-design-icons/CartOutline.vue";
import History from "vue-material-design-icons/History.vue";

export default {
  // props per visualizzare tasto aggiungi al carrello o menu di navigazione
  // impostata su index.html così:
  // <div id="app" data-site="true"></div> per non visualizzare
  props: ["on_site"],
  components: { LoginDialog, Cart, Pencil, Account, History },
  data() {
    return { loginDialogVisible: false, windowWidth: window.innerWidth };
  },
  mounted: async function () {
    await this.getUserData();
    console.log(this.on_site);
    if (this.on_site === "true") {
      this.setIsOnSite(true);
    } else {
      this.setIsOnSite(false);
    }
  },
  methods: {
    // ...mapActions("user", ["getUserData"]),
    getUserData() {
      this.$store.dispatch("configurator/user/getUserData");
    },
    ...mapMutations(["setIsOnSite"]),
  },
  computed: {
    // ...mapGetters("user", { userIsGuest: "isGuest" }),
    userIsGuest() {
      return this.$store.getters["configurator/user/isGuest"];
    },
    // ...mapGetters("cart", ["rowNumber"]),
    rowNumber() {
      return this.$store.getters["configurator/cart/rowNumber"];
    },
    ...mapState(["isOnSite"]),
  },
  name: "App",
};
</script>

<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  overflow: hidden;

  @media (max-width: 1200px) {
    .logo {
      img {
        height: 100px;
      }
    }
  }

  @media (max-width: 992px) {
    .logo {
      img {
        height: 80px;
      }
    }
  }
  @media (min-width: 1201px) {
    .logo {
      img {
        height: 150px;
      }
    }
  }

  .logo {
    position: fixed;
    left: 25px;
    top: 15px;
  }

  #nav-menu {
    height: 100px;
    position: absolute;
    z-index: 1;
    &.hidden {
      display: none;
    }
    .list-menu-nav {
      position: fixed;
      top: 30px;
      right: 30px;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      margin: 0px;
      padding: 0px;
      width: 100%;

      li {
        .img-div {
          height: 50px;
          padding: 0px 5px;
          margin: 0px 5px;
          cursor: pointer;
        }

        img {
          height: 30px;
          // &:hover {
          //   filter: drop-shadow(@--color-primary);
          // }
        }
        span {
          padding-top: 5px;
          color: @--color-primary;
        }
      }
    }
  }

  #router-nav > * {
    height: 100vh;
    // padding-bottom: 65px;
    // -webkit-overflow-scrolling: touch;
  }
}
</style>
