<template>
  <div id="configuratore">
    <el-main class="main-class" ref="canvas">
      <Canvas3d
        :shouldLoadScene="false"
        defaultFrontModel="SIU-001"
        defaultFrontColor="EP0001"
        defaultTemplesColor="PL0001"
      ></Canvas3d>
      <div id="popoverMultistrato">
        <el-popover
          visible-arrow="false"
          popper-class="pop-multistrato"
          trigger="manual"
          v-model="dialogMultistrato"
          placement="bottom"
        >
          <el-card>
            <div slot="header">
              <span>ANTEPRIMA NON DISPONIBILE</span>
            </div>
            <div class="colore-esterno">
              <span>COLORE ESTERNO</span>
              <el-image
                v-if="this.$store.state.configurator.colore"
                :src="
                  'https://cdn.thema-optical.com/3d-models/colori_acetato_sito/' +
                  this.$store.state.configurator.colore.U_THE_COLORE +
                  '.jpg'
                "
                fit="scale-down"
              ></el-image>
            </div>
            <div class="colore-interno">
              <span>COLORE INTERNO</span>
              <el-image
                v-if="this.$store.state.configurator.colore"
                :src="
                  'https://cdn.thema-optical.com/3d-models/colori_acetato_sito/' +
                  this.$store.state.configurator.colore.U_THE_COLORE +
                  '_b.jpg'
                "
                fit="scale-down"
              ></el-image>
            </div>
          </el-card>
        </el-popover>
      </div>

      <!-- <Canvas
        :setCanvasPos="setCanvasPos"
        :resetMaterial="resetMaterial"
        @settedPosition="setCanvasPos = false"
      />-->
    </el-main>
    <footer class="footer-class" :class="{ close: menuClosed }" ref="menu">
      <Menu
        @setPositionForPrint="setCanvasPos = true"
        @resettedMaterial="resetForMaterial"
        @close="closeMenu()"
        @open="openMenu()"
      />
    </footer>
  </div>
</template>

<script>
import componenti from "../components/configuratore";

export default {
  name: "Configuratore",
  components: componenti,
  data() {
    return {
      resetMaterial: false,
      menuClosed: false,
      setCanvasPos: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      // dialogMultistrato: false,
    };
  },
  methods: {
    resetForMaterial() {
      this.resetMaterial = true;
      setTimeout(() => {
        this.resetMaterial = false;
      }, 3000);
    },
    closeMenu() {
      let canvas = this.$refs.canvas;
      // canvas.$el.style.maxWidth = "100vh";
      // canvas.$el.style.width = "100vh";

      if (this.windowWidth < 768) {
        canvas.$el.style.maxHeight = "94vh";
        canvas.$el.style.height = "calc(100% - 55px)";
        // canvas.$el.style.maxHeight = window.innerHeight;
        // canvas.$el.style.height = `calc(${window.innerHeight} - 55px)`;
        // canvas.$el.style.marginTop = "25vh";
        // canvas.$el.style.marginBottom = "25vh";
        // canvas.$el.style.position = "relative";
        // canvas.$el.style.bottom = "0";
        // console.log("CANVAS", canvas);
      } else {
        canvas.$el.style.maxHeight = "100vh";
        canvas.$el.style.height = "100vh";
      }
      setTimeout(() => {
        // console.log(canvas.$children[0]);
        canvas.$children[0].onContainerResize();
      }, 600);
    },
    openMenu() {
      let canvas = this.$refs.canvas;

      // canvas.$el.style.maxHeight = "60vh";
      // canvas.$el.style.height = "60vh";

      if (this.windowWidth < 768) {
        canvas.$el.style.maxHeight = "0vh";
        canvas.$el.style.height = "0vh";
        canvas.$el.style.marginTop = "0vh";
        canvas.$el.style.marginBottom = "0vh";
      } else {
        canvas.$el.style.maxHeight = "60vh";
        canvas.$el.style.height = "60vh";
      }

      setTimeout(() => {
        canvas.$children[0].onContainerResize();
      }, 600);
    },
  },

  computed: {
    dialogMultistrato() {
      if (
        this.$store.state.configurator.colore &&
        this.$store.state.configurator.colore.Category == "MULTISTRATO"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less">
// @menu-height: 40vh;

// @media (max-width: 767px) {
//   .main-class {
//     max-height: 91vh !important;
//     height: 50vh !important;
//     // margin-top: 25% !important;
//     // margin-bottom: 25% !important;
//   }
// }

// @media (min-width: 768px) {
//   .main-class {
//     max-height: 60vh;
//     height: 60vh;
//   }
// }
.main-class {
  width: 100vw;
  max-height: 60vh;
  height: 60vh;
  padding: 0px !important;
  // margin: 0px !important;
  overflow: hidden;
  transition-duration: 0.4s;
}

@media (max-width: 767px) {
  .footer-class {
    height: 0vh !important;
    max-height: 0vh !important;
  }
}

@media (min-width: 768px) {
  .footer-class {
    height: 40vh;
    max-height: 40vh !important;
  }
}

.footer-class {
  width: 100%;
  height: 40vh;
  max-height: 40vh !important;
  min-height: 200px;
  padding: 0px !important;
  // border-top: solid @--color-primary 3px;

  transition-duration: 0.5s;

  // &.close {
  //   transform: translateY(40vh);
  // }
}
</style>