<template>
  <div id="riepilogo">
    <!-- <span class="conferma-per-procedere">CONFERMA PER PROCEDERE</span> -->

    <el-row class="title">
      <el-col class="riferimenti" :xs="{ span: 24, offset: 0 }" :lg="{ span: 18, offset: 4 }">
        <el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 7, offset: 0 }"
          :lg="{ span: 6, offset: 0 }">{{ $t('message.Questo_è_l*occhiale_di:') }}</el-col>
        <el-col class="first-name" :xs="{ span: 22, offset: 1 }" :sm="{ span: 3, offset: 0 }">
          <el-input placeholder="Nome*" v-model="firstName"></el-input>
          <!-- <el-input placeholder="Nome*" v-model="name[item.n]"></el-input> -->
        </el-col>
        <el-col class="last-name" :xs="{ span: 22, offset: 1 }" :sm="{ span: 3, offset: 0 }">
          <el-input placeholder="Cognome*" v-model="lastName"></el-input>
          <!-- <el-input placeholder="Cognome*" v-model="name[item.c]"></el-input> -->
        </el-col>
        <el-col :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 11, offset: 0 }">{{ $t('message.Conferma_per_procedere') }}</el-col>
      </el-col>
      <el-col class="divider" :lg="{ span: 20, offset: 2 }">
        <el-divider></el-divider>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="data" :sm="{ span: 4, offset: 0 }" :lg="{ span: 4, offset: 1 }">
        <el-row>
          <img :src="require('../../../assets/images/icone/frontale_primary.png')" class="image" />
        </el-row>
        <el-row>{{ $t("message.frontale").toUpperCase() }}</el-row>
        <div class="bottom clearfix">
          <el-row>
            <el-col :span="12" class="title1">
              <span>{{ $t('message.Modello').toUpperCase() }}:</span>
              <span>{{ $t("message.Misura").toUpperCase() }}:</span>
            </el-col>
            <el-col :span="12" class="title2">
              <span class="value">
                {{ frontale.U_THE_MODELLO }}
              </span>
              <span class="value">
                {{ parseInt(calibro) }}/{{ parseInt(naso) }}
              </span>
            </el-col>
          </el-row>
        </div>
        <el-divider :class="{ hidden: windowWidth < 768 }" direction="vertical"></el-divider>
      </el-col>
      <el-col class="data" :sm="{ span: 4, offset: 0 }" :lg="{ span: 4, offset: 0 }">
        <el-row>
          <img :src="require('../../../assets/images/icone/colore_primary.png')" class="image" />
        </el-row>
        <el-row>{{ $t('message.Colore') | upperCase }}</el-row>
        <div class="bottom clearfix">
          <el-row>
            <el-col :span="12" class="title1">
              <span>{{ $t("message.Finitura") }}:</span>
              <span>{{ $t('message.Colore') | upperCase }}:</span>
            </el-col>
            <el-col :span="12" class="title2">
              <span class="value" v-if="finitura == 'S'">{{ $t("message.lucido").toUpperCase() }}</span>
              <span class="value" v-else-if="finitura == 'N'">{{ $t('message.Naturale') | upperCase }}</span>
              <span class="value" v-else>{{ $t("message.opaco").toUpperCase() }}</span>
              <span class="value">
                {{ colore.U_THE_COLORE.replace("PL", "") }}
              </span>
            </el-col>
          </el-row>
        </div>
        <el-divider direction="vertical"></el-divider>
      </el-col>
      <el-col class="data" :sm="{ span: 4, offset: 0 }" :lg="{ span: 4, offset: 0 }">
        <el-row>
          <img :src="require('../../../assets/images/icone/aste_primary.png')" class="image" />
        </el-row>
        <el-row>{{ $t("message.Aste") | upperCase }}</el-row>
        <div class="bottom clearfix">
          <el-row>
            <el-col :span="12" class="title1">
              <span>{{ $t("message.Finitura") }}:</span>
              <span>{{ $t("message.Misura").toUpperCase() }}:</span>
              <span>{{ $t('message.Colore') | upperCase }}:</span>
            </el-col>
            <el-col :span="12" class="title2">
              <span class="value" v-if="finituraAste == 'S'">{{ $t("message.lucido").toUpperCase() }}</span>
              <span class="value" v-else>{{ $t("message.opaco").toUpperCase() }}</span>
              <span class="value">
                {{ misuraAste | misuraAste }} {{ misuraAste }}
              </span>
              <span class="value">
                {{ coloreAste.U_THE_COLORE.replace("PL", "") }}
              </span>
            </el-col>
          </el-row>
        </div>
        <el-divider direction="vertical"></el-divider>
      </el-col>
      <el-col class="data" :sm="{ span: 4, offset: 0 }" :lg="{ span: 5, offset: 0 }">
        <el-row>
          <img :src="require('../../../assets/images/icone/personalizzazione_primary.png')
        " class="image" />
        </el-row>
        <el-row>{{ $t('message.Incisione').toUpperCase() }}</el-row>
        <el-row v-if="windowWidth > 1201" class="riepilogo-incisione">
          <el-collapse v-model="activeItem" @change="handleChange">
            <el-collapse-item :class="{ isCompiled: nome != '' }" :title="$t('message.frontale').toUpperCase()"
              name="front">
              <span v-if="nome != ''" style="color: #038EAA !important">{{ $t('message.Destra').toUpperCase() }}</span>
              <el-row v-if="nome != ''">
                <el-col :span="12" class="title1">
                  <span>TESTO:</span>
                  <span>{{ $t('message.Colore') | upperCase }}:</span>
                </el-col>
                <el-col :span="12" class="title2">
                  <span class="value" :style="{
        fontFamily: fontNome,
        fontSize: '15px',
        fontWeight: 'bold',
      }">
                    {{ nome }}
                  </span>
                  <span class="value">
                    {{ coloreNome.toUpperCase() }}
                  </span>
                </el-col>
              </el-row>
            </el-collapse-item>
            <!-- <el-collapse-item
              :class="{isCompiled: nomeIntAstaSx != '' || nomeIntAstaDx != ''}"
              title="ASTE INTERNE"
              name="intTemples"
            >
              <el-col :span="24">
                <span
                  v-if="nomeIntAstaSx != ''"
                  style="color: #038EAA !important;"
                >SINISTRA</span>
                <el-row v-if="nomeIntAstaSx != ''">
                  <el-col :span="12" class="title1">
                    <span>TESTO:</span>
                    <span>COLORE:</span>
                  </el-col>
                  <el-col :span="12" class="title2">
                    <span
                      class="value"
                      :style="{fontFamily: fontNomeIntAstaSx, fontSize: '15px', fontWeight: 'bold'}"
                    >
                      {{nomeIntAstaSx
                      }}
                    </span>
                    <span class="value">
                      {{coloreNomeIntAstaSx.toUpperCase()
                      }}
                    </span>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="24">
                <span
                  v-if="nomeIntAstaDx != ''"
                  style="color: #038EAA !important;"
                >DESTRA</span>
                <el-row v-if="nomeIntAstaDx != ''">
                  <el-col :span="12" class="title1">
                    <span>TESTO:</span>
                    <span>COLORE:</span>
                  </el-col>
                  <el-col :span="12" class="title2">
                    <span
                      class="value"
                      :style="{fontFamily: fontNomeIntAstaDx, fontSize: '15px', fontWeight: 'bold'}"
                    >
                      {{nomeIntAstaDx
                      }}
                    </span>
                    <span class="value">
                      {{coloreNomeIntAstaDx.toUpperCase()
                      }}
                    </span>
                  </el-col>
                </el-row>
              </el-col>
            </el-collapse-item>
            <el-collapse-item
              :class="{isCompiled: nomeExtAstaSx != '' || nomeExtAstaDx != ''}"
              title="ASTE ESTERNE"
              name="estTemples"
            >
              <el-col :span="24">
                <span
                  v-if="nomeExtAstaSx != ''"
                  style="color: #038EAA !important;"
                >SINISTRA</span>
                <el-row v-if="nomeExtAstaSx != ''">
                  <el-col :span="8" class="title-1">
                    <span>TESTO:</span>
                    <span>COLORE:</span>
                  </el-col>
                  <el-col :span="16" class="title-2">
                    <span
                      class="value"
                      :style="{fontFamily: fontNomeExtAstaSx, fontSize: '15px', fontWeight: 'bold'}"
                    >
                      {{nomeExtAstaSx
                      }}
                    </span>
                    <span class="value">
                      {{coloreNomeExtAstaSx.toUpperCase()
                      }}
                    </span>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="24">
                <span
                  v-if="nomeExtAstaDx != ''"
                  style="color: #038EAA !important;"
                >DESTRA</span>
                <el-row v-if="nomeExtAstaDx != ''">
                  <el-col :span="8" class="title-1">
                    <span>TESTO:</span>
                    <span>COLORE:</span>
                  </el-col>
                  <el-col :span="16" class="title-2">
                    <span
                      class="value"
                      :style="{fontFamily: fontNomeExtAstaDx, fontSize: '15px', fontWeight: 'bold'}"
                    >
                      {{nomeExtAstaDx
                      }}
                    </span>
                    <span class="value">
                      {{coloreNomeExtAstaDx.toUpperCase()
                      }}
                    </span>
                  </el-col>
                </el-row>
              </el-col>
            </el-collapse-item> -->
          </el-collapse>
        </el-row>
        <el-row v-else>
          <el-col>
            <el-button v-if="nome != '' ||
        nomeExtAstaSx != '' ||
        nomeIntAstaSx != '' ||
        nomeExtAstaDx != '' ||
        nomeIntAstaDx != ''
        " type="text" @click="visPersDialog = true"
              style="color: #038EAA !important; padding-top: 10%">{{ $t('message.Visualizza').toUpperCase() }}</el-button>
            <el-button v-else type="text" style="color: #038EAA !important; padding-top: 10%">No</el-button>
          </el-col>
        </el-row>
        <el-divider :class="{ hidden: windowWidth < 768 }" direction="vertical"></el-divider>
      </el-col>
      <!-- <el-col
        class="data"
        :sm="{ span: 4, offset: 0 }"
        :lg="{ span: 4, offset: 0 }"
      >
        <el-row>
          <img
            :src="
              require('../../../assets/images/icone/frontale_sole_primary.png')
            "
            class="image"
          />
        </el-row>
        <el-row>LENTE SOLE</el-row>
        <div class="bottom clearfix">
          <el-row>
            <el-col :span="12" class="title1">
              <span>TIPO:</span>
            </el-col>
            <el-col :span="12" class="title2">
              <span
                v-if="$store.state.configurator.lenteSole != null"
                class="value-lente"
              >
                {{
                  $store.state.configurator.lenteSole.ItemName.replace(
                    "LENTE",
                    ""
                  )
                    .replace("SOLE", "")
                    .replace("SOLID", "")
                    .replace("NON POLARIZZATA", "")
                    .replace("GRADIENT", "SFUMATA")
                    .replace("GRAD", "SFUMATA")
                }}
              </span>
              <span v-else>-</span>
            </el-col>
          </el-row>
        </div>
      </el-col> -->
    </el-row>

    <!-- Dialog personalizzazioni su tablet -->
    <el-dialog class="details-dialog" :title="$t('message.Incisione').toUpperCase()" :visible.sync="visPersDialog"
      :show-close="false" :modal="false" width="75%">
      <el-table class="history-table" :data="personalizzazioni" border stripe
        :empty-text="$t('message.Nessuna_configurazione')" max-height="500" :row-class-name="getRowClass">
        <el-table-column prop="label" :label="$t('message.Posizione').toUpperCase()" align="center"></el-table-column>
        <!-- <el-table-column prop="nome" label="TESTO" align="center"></el-table-column> -->
        <el-table-column :label="$t('message.Testo').toUpperCase()" align="center">
          <template slot-scope="scope">
            <span :style="{ fontFamily: scope.row.fontTesto }">{{
        scope.row.nome
      }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('message.Colore') | upperCase" align="center">
          <template slot-scope="scope">{{
        scope.row.coloreTesto.toUpperCase()
      }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
// import { mapMutations } from "vuex";

export default {
  data() {
    return {
      // item: { n: "firstName", c: "lastName" },
      activeItem: [],
      selectedItem: "",
      firstName: "",
      lastName: "",
      windowWidth: window.innerWidth,
      visPersDialog: false,
      personalizzazioni: [],
    };
  },

  mounted: function () {
    this.personalizzazioni.push(
      {
        label: this.$t("message.frontale").toUpperCase(),
        nome: this.nome,
        coloreTesto: this.$t("message." + this.$options.filters.capitalize(this.coloreNome.replace("-", " "))),
        fontTesto: this.fontNome,
      },
      {
        label: "ESTERNO ASTA SINISTRA",
        nome: this.nomeExtAstaSx,
        coloreTesto: this.coloreNomeExtAstaSx,
        fontTesto: this.fontNomeExtAstaSx,
      },
      {
        label: "INTERNO ASTA SINISTRA",
        nome: this.nomeIntAstaSx,
        coloreTesto: this.coloreNomeIntAstaSx,
        fontTesto: this.fontNomeIntAstaSx,
      },
      {
        label: "ESTERNO ASTA DESTRA",
        nome: this.nomeExtAstaDx,
        coloreTesto: this.coloreNomeExtAstaDx,
        fontTesto: this.fontNomeExtAstaDx,
      },
      {
        label: "INTERNO ASTA DESTRA",
        nome: this.nomeIntAstaDx,
        coloreTesto: this.coloreNomeIntAstaDx,
        fontTesto: this.fontNomeIntAstaDx,
      }
    );
  },

  methods: {
    // ...mapMutations(["resetRowNotes"]),

    setRowNotes(rn) {
      this.$store.commit("configurator/setRowNotes", rn);
    },

    handleChange(val) {
      this.selectedItem = val;
    },

    getRowClass(data) {
      return data.row.nome === "" ? "hidden" : "";
    },
  },

  computed: {
    // ...mapState([
    //   "frontale",
    //   "calibro",
    //   "naso",
    //   "asianFitting",
    //   "colore",
    //   "finitura",
    //   "coloreAste",
    //   "coloreMetalloAste",
    //   "finituraAste",
    //   "templesMaterial",
    //   "misuraAste",
    //   "nome",
    //   "coloreNome",
    //   "fontNome",
    //   "nomeExtAstaSx",
    //   "coloreNomeExtAstaSx",
    //   "fontNomeIntAstaSx",
    //   "nomeIntAstaSx",
    //   "coloreNomeIntAstaSx",
    //   "fontNomeExtAstaSx",
    //   "nomeExtAstaDx",
    //   "coloreNomeExtAstaDx",
    //   "fontNomeIntAstaDx",
    //   "nomeIntAstaDx",
    //   "coloreNomeIntAstaDx",
    //   "fontNomeExtAstaDx",
    // ]),

    frontale() {
      return this.$store.state.configurator.frontale;
    },
    calibro() {
      return this.$store.state.configurator.calibro;
    },
    naso() {
      return this.$store.state.configurator.naso;
    },
    asianFitting() {
      return this.$store.state.configurator.asianFitting;
    },
    colore() {
      return this.$store.state.configurator.colore;
    },
    finitura() {
      return this.$store.state.configurator.finitura;
    },
    coloreAste() {
      return this.$store.state.configurator.coloreAste;
    },
    coloreMetalloAste() {
      return this.$store.state.configurator.coloreMetalloAste;
    },
    finituraAste() {
      return this.$store.state.configurator.finituraAste;
    },
    templesMaterial() {
      return this.$store.state.configurator.templesMaterial;
    },
    misuraAste() {
      return this.$store.state.configurator.misuraAste;
    },

    nome() {
      return this.$store.state.configurator.frontali?.name;
    },
    fontNome() {
      return this.$store.state.configurator.frontali?.nameFont;
    },
    coloreNome() {
      return this.$store.state.configurator.frontali?.nameColor;
    },

    nomeExtAstaSx() {
      return this.$store.state.configurator.aste?.extSxName;
    },
    fontNomeExtAstaSx() {
      return this.$store.state.configurator.aste?.extSxNameFont;
    },
    coloreNomeExtAstaSx() {
      return this.$store.state.configurator.aste?.extSxNameColor;
    },
    nomeIntAstaSx() {
      return this.$store.state.configurator.aste?.intSxName;
    },
    fontNomeIntAstaSx() {
      return this.$store.state.configurator.aste?.intSxNameFont;
    },
    coloreNomeIntAstaSx() {
      return this.$store.state.configurator.aste?.intSxNameColor;
    },
    nomeExtAstaDx() {
      return this.$store.state.configurator.aste?.extDxName;
    },
    fontNomeExtAstaDx() {
      return this.$store.state.configurator.aste?.extDxNameFont;
    },
    coloreNomeExtAstaDx() {
      return this.$store.state.configurator.aste?.extDxNameColor;
    },
    nomeIntAstaDx() {
      return this.$store.state.configurator.aste?.intDxName;
    },
    fontNomeIntAstaDx() {
      return this.$store.state.configurator.aste?.intDxNameFont;
    },
    coloreNomeIntAstaDx() {
      return this.$store.state.configurator.aste?.intDxNameColor;
    },

    fullName() {
      if (this.firstName != "" && this.lastName != "") {
        return this.firstName + " " + this.lastName;
      } else {
        return "";
      }
    },
  },
  watch: {
    fullName(nVal) {
      if (nVal.length > 0) {
        this.setRowNotes(`Rif. ${this.fullName}`);
      } else {
        this.$store.state.configurator.note = "";
      }
    },
    // firstName() {
    //   if (this.firstName.length > 3 && this.lastName.length > 3) {
    //     let name = { firstName: this.firstName, lastName: this.lastName };
    //     this.setRowNotes(name);
    //   } else {
    //     this.resetRowNotes();
    //   }
    // },
    // lastName() {
    //   if (this.firstName.length > 3 && this.lastName.length > 3) {
    //     let name = { firstName: this.firstName, lastName: this.lastName };
    //     this.setRowNotes(name);
    //   } else {
    //     this.resetRowNotes();
    //   }
    // },
  },
};
</script>

<style lang="less">
@media (max-width: 767px) {
  #riepilogo {
    height: 100%;
  }
}

@media (min-width: 768px) {
  #riepilogo {
    height: 35vh;
  }
}

#riepilogo {
  width: 100%;
  background-color: @--color-text-secondary;

  .title {
    padding-top: 20px;

    @media (max-width: 767px) {
      .riferimenti {
        .first-name {
          margin-top: 2%;
          margin-bottom: 2%;
        }

        .last-name {
          margin-top: 2%;
          margin-bottom: 4%;
        }
      }
    }

    @media (min-width: 768px) {
      .riferimenti {
        display: flex;
        align-items: center;
      }
    }

    .riferimenti {
      .first-name {
        margin-right: 20px;
      }

      .el-input__inner {
        height: 35px;
        // width: 90%;
      }
    }

    >.el-col {
      color: @--color-warning;
      font-size: 22px;
    }

    .divider {
      height: 20px;
    }
  }

  @media (max-width: 767px) {
    .data {
      height: 33vh;

      .el-divider {
        height: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .data {
      height: 25vh;

      .el-divider {
        height: 200px;
      }
    }
  }

  .data {
    position: relative;
    color: @--color-warning;

    img {
      width: 70px;
    }

    .el-divider {
      position: absolute;
      right: -8px;
      top: 20px;
    }

    .riepilogo-incisione::-webkit-scrollbar {
      display: none;
    }

    .riepilogo-incisione {
      width: 90%;
      height: 65%;
      position: absolute;
      top: 100px;
      left: 0px;
      margin: 0px 20px;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .el-collapse-item .el-collapse .el-collapse-item__header {
        padding-left: 45px !important;
      }

      .el-collapse {
        height: 75px !important;
      }

      .el-collapse-item .el-collapse .el-collapse-item__header {
        padding-left: 45px !important;
      }

      .el-collapse-item__arrow.el-icon-arrow-right::before {
        color: transparent;
      }

      .el-collapse-item__header {
        height: 25px;
        padding-left: 10px;
      }

      .el-collapse-item__content {
        padding: 0;
      }

      .isCompiled {
        .el-collapse-item__header {
          color: @--color-white;
          background-color: @--color-warning !important;

          .el-collapse-item__arrow.el-icon-arrow-right {
            transform: rotate(90deg) !important;

            &:before {
              color: @--color-white;
            }
          }

          .el-collapse-item__arrow.el-icon-arrow-right.is-active {
            transform: rotate(-90deg) !important;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .bottom {
      span {
        padding: 10px 10px;
      }
    }
  }

  @media (max-width: 992px) {
    .bottom {
      span {
        padding: 15px 0px;
      }
    }
  }

  @media (min-width: 1201px) {
    .bottom {
      span {
        padding: 15px 25px;
      }
    }
  }

  .bottom {
    margin-top: 10px;
    line-height: 12px;

    @media (max-width: 767px) {
      .title2 {
        padding-left: 25%;

        .value-lente {
          padding: 0;
          padding-top: 5px;
        }
      }
    }

    @media (min-width: 768px) {
      .title2 {
        padding-left: 5px;

        .value-lente {
          padding: 0;
          padding-top: 5px;
        }
      }
    }

    .title1 {
      padding-right: 15px;
    }

    // span {
    //   padding: 15px 20px;
    // }
  }

  .title-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // width: 50%;
    // padding-right: 5px;
    padding-left: 25px;

    span {
      color: @--color-text-primary !important;
    }
  }

  .title-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // width: 50%;
    padding-left: 5px;

    span {
      color: @--color-text-primary !important;
    }
  }

  .details-dialog {
    background-color: rgba(0, 0, 0, 0.59);
    padding-top: 5%;
  }

  .history-table {
    background-color: @--table-header-background-color;

    .el-table__header {
      height: 71px;

      tr {
        color: @--color-warning !important;
      }
    }

    .el-table__header,
    .el-table__body {
      div {
        word-break: normal !important;
      }
    }

    .el-table__empty-text {
      color: @--color-primary;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>