<template>
  <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 282.27534 125.07557" y="0px" x="0px"
    id="Livello_1" version="1.1">
    <metadata id="metadata4609">
      <rdf:RDF>
        <cc:Work rdf:about>
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <defs id="defs4607" />

    <g transform="translate(-156.22466,-358.92466)" id="g4602">
      <g id="g4598">
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4" y2="366.79999" x2="205.60001" y1="366.79999" x1="205.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line6" y2="366.79999" x2="205.8" y1="366.79999" x1="205.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line8" y2="366.79999" x2="205.8" y1="366.79999" x1="205.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line10" y2="366.79999" x2="205.89999" y1="366.79999" x1="206" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line12" y2="366.79999" x2="206" y1="366.79999" x1="206" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line14" y2="366.79999" x2="206" y1="366.79999" x1="206.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line16" y2="366.79999" x2="206.10001" y1="366.79999" x1="206.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line18" y2="366.79999" x2="206.10001" y1="366.79999" x1="206.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line20" y2="366.79999" x2="206.2" y1="366.79999" x1="206.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line22" y2="366.79999" x2="206.3" y1="366.79999" x1="206.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line24" y2="366.79999" x2="206.3" y1="366.79999" x1="206.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line26" y2="366.79999" x2="206.39999" y1="366.79999" x1="206.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line28" y2="366.79999" x2="206.39999" y1="366.79999" x1="206.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line30" y2="366.79999" x2="206.5" y1="366.79999" x1="206.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line32" y2="375.60001" x2="199.39999" y1="375.60001" x1="199.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line34" y2="375.60001" x2="199.5" y1="375.60001" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line36" y2="375.60001" x2="199.60001" y1="375.60001" x1="199.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line38" y2="375.60001" x2="199.8" y1="375.60001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line40" y2="375.60001" x2="199.89999" y1="375.60001" x1="200.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line42" y2="375.60001" x2="200.10001" y1="375.60001" x1="200.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line44" y2="375.60001" x2="200.2" y1="375.60001" x1="200.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line46" y2="375.60001" x2="200.3" y1="375.60001" x1="200.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line48" y2="375.60001" x2="200.5" y1="375.60001" x1="200.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line50" y2="375.60001" x2="200.60001" y1="375.60001" x1="200.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line52" y2="359" x2="172.10001" y1="359" x1="171.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line54" y2="375.60001" x2="200.8" y1="375.60001" x1="200.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line56" y2="359" x2="171.7" y1="359.20001" x1="171.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line58" y2="375.60001" x2="200.89999" y1="375.60001" x1="201" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path60" d="m 164.2,361.3 h -1.1 c 0,0 -3.9,0.3 -4.8,3.1 L 158,365" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line62" y2="375.60001" x2="201" y1="375.60001" x1="201.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line64" y2="482.70001" x2="186.39999" y1="482.29999" x1="186.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line66" y2="375.60001" x2="201.2" y1="375.60001" x1="201.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line68" y2="483.5" x2="187.5" y1="483.20001" x1="187" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line70" y2="375.60001" x2="201.3" y1="375.60001" x1="201.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line72" y2="362.79999" x2="196.60001" y1="362.70001" x1="196.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line74" y2="375.60001" x2="201.5" y1="375.60001" x1="201.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line76" y2="375.60001" x2="201.60001" y1="375.60001" x1="201.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line78" y2="480.5" x2="201" y1="479.79999" x1="201.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line80" y2="375.60001" x2="201.7" y1="375.60001" x1="201.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line82" y2="475" x2="204.5" y1="476" x1="204.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line84" y2="375.60001" x2="201.89999" y1="375.60001" x1="202" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line86" y2="375.60001" x2="202" y1="375.60001" x1="202.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line88" y2="375.60001" x2="202.2" y1="375.60001" x1="202.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line90" y2="375.60001" x2="202.3" y1="375.60001" x1="202.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line92" y2="367.5" x2="157.60001" y1="368.79999" x1="157.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line94" y2="375.60001" x2="202.39999" y1="375.60001" x1="202.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line96" y2="375.60001" x2="202.60001" y1="375.60001" x1="202.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line98" y2="375.60001" x2="202.7" y1="375.60001" x1="202.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line100" y2="375.60001" x2="202.89999" y1="375.60001" x1="203" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line102" y2="483.10001" x2="186.89999" y1="483.10001" x1="186.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line104" y2="375.60001" x2="203" y1="375.60001" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line106" y2="361.20001" x2="190.7" y1="361" x1="189.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line108" y2="375.60001" x2="203.10001" y1="375.60001" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line110" y2="362.70001" x2="196.3" y1="362.60001" x1="196" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line112" y2="375.60001" x2="203.3" y1="375.60001" x1="203.39999" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline114" points="199.6,379.3 196.4,383.9 196.1,384.4 195.6,386.4   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line116" y2="375.60001" x2="203.39999" y1="375.60001" x1="203.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line118" y2="375.60001" x2="203.60001" y1="375.60001" x1="203.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line120" y2="375.60001" x2="203.7" y1="375.60001" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line122" y2="375.60001" x2="203.8" y1="375.60001" x1="204" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line124" y2="483.10001" x2="186.8" y1="483.10001" x1="186.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line126" y2="375.60001" x2="204" y1="375.60001" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line128" y2="361.5" x2="192" y1="361.60001" x1="192.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line130" y2="375.60001" x2="204.10001" y1="375.60001" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line132" y2="375.60001" x2="204.3" y1="375.60001" x1="204.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line134" y2="375.60001" x2="204.39999" y1="375.60001" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line136" y2="366.89999" x2="203.2" y1="366.89999" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line138" y2="375.60001" x2="204.5" y1="375.60001" x1="204.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line140" y2="366.89999" x2="203.2" y1="366.89999" x1="203.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line142" y2="375.60001" x2="204.7" y1="375.60001" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line144" y2="375.60001" x2="204.8" y1="375.60001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line146" y2="375.60001" x2="205" y1="375.60001" x1="205.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line148" y2="375.60001" x2="205.10001" y1="375.60001" x1="205.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line150" y2="375.60001" x2="205.2" y1="375.60001" x1="205.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line152" y2="366.89999" x2="433.79999" y1="366.89999" x1="433.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line154" y2="375.60001" x2="205.39999" y1="375.60001" x1="205.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line156" y2="375.60001" x2="205.5" y1="375.60001" x1="205.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line158" y2="375.60001" x2="205.7" y1="375.60001" x1="205.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line160" y2="375.60001" x2="205.8" y1="375.60001" x1="205.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line162" y2="366.10001" x2="385.10001" y1="366.39999" x1="392.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line164" y2="375.60001" x2="205.89999" y1="375.60001" x1="206.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line166" y2="375.60001" x2="206.10001" y1="375.60001" x1="206.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line168" y2="375.60001" x2="206.2" y1="375.60001" x1="206.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line170" y2="375.60001" x2="206.39999" y1="375.60001" x1="206.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line172" y2="375.60001" x2="206.5" y1="375.60001" x1="206.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line174" y2="375.60001" x2="206.60001" y1="375.60001" x1="206.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line176" y2="476" x2="204.2" y1="476.89999" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line178" y2="375.60001" x2="206.8" y1="375.60001" x1="206.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line180" y2="361.5" x2="192" y1="361.20001" x1="190.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line182" y2="375.60001" x2="206.89999" y1="375.60001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line184" y2="366.89999" x2="203.2" y1="366.89999" x1="203.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line186" y2="366.79999" x2="206.60001" y1="366.79999" x1="206.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line188" y2="366.79999" x2="206.60001" y1="366.79999" x1="206.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line190" y2="465.10001" x2="180" y1="466.70001" x1="180.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line192" y2="366.79999" x2="206.7" y1="366.79999" x1="206.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line194" y2="366.79999" x2="206.8" y1="366.79999" x1="206.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line196" y2="379.5" x2="199.39999" y1="379.29999" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line198" y2="366.79999" x2="206.8" y1="366.79999" x1="206.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line200" y2="366.79999" x2="206.89999" y1="366.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line202" y2="366.89999" x2="203.3" y1="366.89999" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line204" y2="366.79999" x2="207" y1="366.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line206" y2="362.60001" x2="290.29999" y1="362.60001" x1="290" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line208" y2="362.60001" x2="290" y1="362.60001" x1="289.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line210" y2="362.60001" x2="289.89999" y1="362.60001" x1="289.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line212" y2="362.60001" x2="285.79999" y1="362.60001" x1="284.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line214" y2="362.60001" x2="284.5" y1="362.60001" x1="282" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line216" y2="362.60001" x2="282" y1="362.60001" x1="279.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line218" y2="362.60001" x2="279.39999" y1="362.60001" x1="276.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line220" y2="362.60001" x2="276.79999" y1="362.60001" x1="274.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line222" y2="362.60001" x2="274.20001" y1="362.60001" x1="271.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line224" y2="362.60001" x2="271.60001" y1="362.60001" x1="269.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line226" y2="362.60001" x2="269.10001" y1="362.60001" x1="266.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line228" y2="362.60001" x2="266.5" y1="362.60001" x1="263.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line230" y2="362.60001" x2="263.89999" y1="362.60001" x1="261.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line232" y2="362.60001" x2="261.29999" y1="362.60001" x1="258.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line234" y2="362.60001" x2="258.70001" y1="362.60001" x1="256.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line236" y2="362.60001" x2="256.20001" y1="362.60001" x1="253.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line238" y2="362.60001" x2="253.60001" y1="362.60001" x1="251" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line240" y2="362.60001" x2="251" y1="362.60001" x1="248.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line242" y2="362.60001" x2="248.39999" y1="362.60001" x1="245.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line244" y2="362.60001" x2="245.89999" y1="362.60001" x1="243.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line246" y2="362.60001" x2="243.3" y1="362.60001" x1="240.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line248" y2="362.60001" x2="240.7" y1="362.60001" x1="238.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line250" y2="362.60001" x2="238.10001" y1="362.60001" x1="235.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line252" y2="362.60001" x2="235.5" y1="362.60001" x1="233" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line254" y2="362.60001" x2="233" y1="362.60001" x1="230.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line256" y2="362.60001" x2="230.39999" y1="362.60001" x1="227.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line258" y2="362.60001" x2="227.8" y1="362.60001" x1="225.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line260" y2="362.60001" x2="225.2" y1="362.60001" x1="222.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line262" y2="362.60001" x2="222.60001" y1="362.60001" x1="220.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line264" y2="362.60001" x2="220.10001" y1="362.60001" x1="217.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line266" y2="362.60001" x2="217.5" y1="362.60001" x1="214.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line268" y2="362.60001" x2="214.89999" y1="362.60001" x1="212.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line270" y2="362.60001" x2="212.3" y1="362.60001" x1="209.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line272" y2="362.60001" x2="209.7" y1="362.60001" x1="207.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line274" y2="376.79999" x2="376.10001" y1="376.89999" x1="373.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line276" y2="376.89999" x2="373.60001" y1="377" x1="371.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line278" y2="377" x2="371.10001" y1="377.10001" x1="368.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line280" y2="377.10001" x2="368.60001" y1="377.20001" x1="366.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line282" y2="377.20001" x2="366.10001" y1="377.29999" x1="363.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line284" y2="377.29999" x2="363.60001" y1="377.5" x1="361" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line286" y2="377.5" x2="361" y1="377.60001" x1="358.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line288" y2="377.60001" x2="358.5" y1="377.70001" x1="356" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line290" y2="377.70001" x2="356" y1="377.79999" x1="353.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line292" y2="377.79999" x2="353.5" y1="377.89999" x1="351" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line294" y2="377.89999" x2="351" y1="378" x1="348.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line296" y2="378" x2="348.5" y1="378.10001" x1="345.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line298" y2="378.10001" x2="345.89999" y1="378.29999" x1="343.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line300" y2="378.29999" x2="343.39999" y1="378.39999" x1="340.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line302" y2="366.89999" x2="203.2" y1="366.89999" x1="203.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line304" y2="366.89999" x2="203.2" y1="366.89999" x1="203.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line306" y2="378.39999" x2="340.89999" y1="378.5" x1="338.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line308" y2="378.5" x2="338.39999" y1="378.60001" x1="335.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line310" y2="378.60001" x2="335.89999" y1="378.70001" x1="333.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line312" y2="378.70001" x2="333.29999" y1="378.79999" x1="330.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line314" y2="378.79999" x2="330.79999" y1="378.89999" x1="328.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line316" y2="378.89999" x2="328.29999" y1="379" x1="325.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line318" y2="379" x2="325.79999" y1="379.10001" x1="323.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line320" y2="379.10001" x2="323.29999" y1="379.20001" x1="320.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line322" y2="379.20001" x2="320.79999" y1="379.29999" x1="318.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line324" y2="379.29999" x2="318.20001" y1="379.39999" x1="315.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line326" y2="379.39999" x2="315.70001" y1="379.5" x1="313.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line328" y2="379.5" x2="313.20001" y1="379.5" x1="310.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line330" y2="379.5" x2="310.70001" y1="379.60001" x1="308.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line332" y2="379.60001" x2="308.10001" y1="379.70001" x1="305.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line334" y2="379.70001" x2="305.60001" y1="379.70001" x1="303.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line336" y2="359" x2="173.60001" y1="359" x1="173.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line338" y2="359" x2="173.60001" y1="359" x1="173.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line340" y2="359" x2="173.3" y1="359" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line342" y2="359" x2="173" y1="359" x1="172.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line344" y2="359" x2="172.7" y1="359" x1="172.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line346" y2="359" x2="172.5" y1="359" x1="172.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line348" y2="359" x2="172.10001" y1="359" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line350" y2="359" x2="173" y1="359" x1="174.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line352" y2="379.70001" x2="303.10001" y1="379.79999" x1="300.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line354" y2="379.79999" x2="300.60001" y1="379.79999" x1="298.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line356" y2="379.79999" x2="298.10001" y1="379.79999" x1="295.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line358" y2="379.79999" x2="295.5" y1="379.79999" x1="293" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line360" y2="379.79999" x2="293" y1="379.89999" x1="290.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line362" y2="379.89999" x2="290.5" y1="379.89999" x1="288" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line364" y2="379.89999" x2="288" y1="379.89999" x1="285.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line366" y2="481.29999" x2="199.5" y1="481.20001" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line368" y2="481.20001" x2="199.60001" y1="481.10001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line370" y2="481.10001" x2="199.89999" y1="480.89999" x1="200.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line372" y2="480.89999" x2="200.2" y1="480.5" x1="201" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line374" y2="379.89999" x2="285.5" y1="379.89999" x1="282.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line376" y2="379.89999" x2="282.89999" y1="379.89999" x1="280.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line378" y2="366.89999" x2="435.5" y1="366.89999" x1="437.20001" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path380" d="M 437.2,366.9" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line382" y2="379.29999" x2="156.89999" y1="379.70001" x1="156.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line384" y2="379.70001" x2="156.5" y1="380.29999" x1="156.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line386" y2="380.29999" x2="156.3" y1="380.79999" x1="156.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line388" y2="380.79999" x2="156.39999" y1="387.60001" x1="158.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line390" y2="387.60001" x2="158.2" y1="394.5" x1="159.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line392" y2="394.5" x2="159.89999" y1="394.89999" x1="160.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line394" y2="394.89999" x2="160.2" y1="395.29999" x1="160.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line396" y2="395.29999" x2="160.60001" y1="395.5" x1="161.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line398" y2="395.5" x2="161.10001" y1="395.5" x1="162.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line400" y2="395.5" x2="162.39999" y1="395.70001" x1="163.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line402" y2="379.89999" x2="280.39999" y1="379.89999" x1="277.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line404" y2="379.89999" x2="277.89999" y1="379.89999" x1="275.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line406" y2="379.89999" x2="275.39999" y1="379.89999" x1="272.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line408" y2="379.89999" x2="272.79999" y1="379.89999" x1="270.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line410" y2="367.39999" x2="157.60001" y1="367" x1="157.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line412" y2="367" x2="157.60001" y1="366.39999" x1="157.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line414" y2="379.89999" x2="270.29999" y1="379.89999" x1="266.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line416" y2="379.89999" x2="266.39999" y1="379.89999" x1="262.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line418" y2="376.60001" x2="158.89999" y1="376.60001" x1="158.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line420" y2="376.60001" x2="158.89999" y1="377.5" x1="159.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line422" y2="377.5" x2="159.10001" y1="378.5" x1="159.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line424" y2="379.89999" x2="262.39999" y1="379.89999" x1="258.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line426" y2="379.89999" x2="258.5" y1="379.89999" x1="254.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line428" y2="379.89999" x2="254.5" y1="379.89999" x1="250.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line430" y2="466.70001" x2="180.5" y1="469" x1="181.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line432" y2="469" x2="181.2" y1="471.39999" x1="181.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line434" y2="471.39999" x2="181.89999" y1="472.5" x1="182.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line436" y2="379.89999" x2="250.5" y1="379.89999" x1="246.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line438" y2="379.89999" x2="246.60001" y1="379.89999" x1="242.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line440" y2="379.89999" x2="242.60001" y1="379.79999" x1="238.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line442" y2="478.5" x2="184.2" y1="477.70001" x1="183.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line444" y2="477.70001" x2="183.89999" y1="476.89999" x1="183.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line446" y2="476.89999" x2="183.7" y1="476" x1="183.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line448" y2="476" x2="183.39999" y1="475.10001" x1="183.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line450" y2="475.10001" x2="183.10001" y1="474.20001" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line452" y2="474.20001" x2="182.8" y1="473.10001" x1="182.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line454" y2="473.10001" x2="182.5" y1="473.10001" x1="182.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line456" y2="379.79999" x2="238.7" y1="379.79999" x1="234.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line458" y2="379.79999" x2="234.7" y1="379.79999" x1="230.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line460" y2="379.79999" x2="230.8" y1="379.79999" x1="226.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line462" y2="362.89999" x2="305" y1="362.89999" x1="306.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line464" y2="362.89999" x2="306.29999" y1="362.89999" x1="307.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line466" y2="379.79999" x2="226.8" y1="379.79999" x1="222.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line468" y2="379.79999" x2="222.89999" y1="379.79999" x1="218.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line470" y2="480.79999" x2="185.10001" y1="480.29999" x1="184.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line472" y2="480.29999" x2="184.89999" y1="479.79999" x1="184.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line474" y2="479.79999" x2="184.7" y1="478.60001" x1="184.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line476" y2="478.60001" x2="184.3" y1="478.5" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line478" y2="362.60001" x2="291.5" y1="362.60001" x1="292.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line480" y2="362.60001" x2="292.10001" y1="362.70001" x1="293.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line482" y2="362.60001" x2="207.2" y1="362.60001" x1="207.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line484" y2="362.60001" x2="207.2" y1="362.60001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line486" y2="362.60001" x2="207.10001" y1="362.60001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line488" y2="362.60001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line490" y2="362.70001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line492" y2="362.70001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line494" y2="362.70001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line496" y2="362.70001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line498" y2="362.70001" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line500" y2="362.79999" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line502" y2="362.79999" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line504" y2="362.79999" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line506" y2="362.79999" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line508" y2="362.79999" x2="207.10001" y1="362.89999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line510" y2="362.89999" x2="207.10001" y1="362.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line512" y2="362.89999" x2="207" y1="362.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line514" y2="362.89999" x2="207" y1="362.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line516" y2="362.89999" x2="207" y1="363" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line518" y2="363" x2="207" y1="363.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line520" y2="363.10001" x2="207" y1="363.29999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line522" y2="363.29999" x2="207" y1="363.60001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line524" y2="363.60001" x2="207" y1="363.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line526" y2="363.79999" x2="207" y1="364.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line528" y2="364.10001" x2="207" y1="364.29999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line530" y2="364.29999" x2="207" y1="365.20001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line532" y2="365.20001" x2="207" y1="366.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line534" y2="366.10001" x2="207" y1="367" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line536" y2="367" x2="207" y1="367.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line538" y2="367.89999" x2="207" y1="368.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line540" y2="368.79999" x2="207" y1="369.60001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line542" y2="369.60001" x2="207" y1="370.5" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line544" y2="370.5" x2="207" y1="371.39999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line546" y2="371.39999" x2="207" y1="372.29999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line548" y2="372.29999" x2="207" y1="373.20001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line550" y2="373.20001" x2="207" y1="374.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line552" y2="374.10001" x2="207" y1="374.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line554" y2="374.89999" x2="207" y1="375.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line556" y2="375.79999" x2="207" y1="376.70001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line558" y2="376.70001" x2="207" y1="377.60001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line560" y2="377.60001" x2="207" y1="378.5" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line562" y2="378.5" x2="207" y1="378.70001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line564" y2="378.70001" x2="207" y1="379" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line566" y2="379" x2="207" y1="379.5" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line568" y2="379.5" x2="207" y1="379.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line570" y2="379.79999" x2="207" y1="379.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line572" y2="362.70001" x2="293.39999" y1="362.70001" x1="294.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line574" y2="362.70001" x2="294.70001" y1="362.70001" x1="297.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line576" y2="362.70001" x2="297.20001" y1="362.79999" x1="299.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line578" y2="362.79999" x2="299.79999" y1="362.79999" x1="302.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line580" y2="379.79999" x2="218.89999" y1="379.79999" x1="214.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line582" y2="379.79999" x2="214.89999" y1="379.79999" x1="211" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line584" y2="482.29999" x2="186.10001" y1="481.70001" x1="185.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line586" y2="481.70001" x2="185.7" y1="480.79999" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line588" y2="480.79999" x2="185.10001" y1="481.29999" x1="185.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line590" y2="379.79999" x2="211" y1="379.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line592" y2="367" x2="199.3" y1="367" x1="199.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line594" y2="367" x2="199.5" y1="367" x1="199.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line596" y2="367" x2="199.7" y1="367" x1="199.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line598" y2="367" x2="199.8" y1="367" x1="200" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line600" y2="367" x2="200" y1="367" x1="200.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line602" y2="440.20001" x2="173.60001" y1="442.10001" x1="174.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line604" y2="442.10001" x2="174.10001" y1="445.70001" x1="175" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line606" y2="445.70001" x2="175" y1="449.10001" x1="175.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line608" y2="449.10001" x2="175.8" y1="452.29999" x1="176.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line610" y2="452.29999" x2="176.60001" y1="455.20001" x1="177.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line612" y2="455.20001" x2="177.3" y1="457.70001" x1="178" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line614" y2="457.70001" x2="178" y1="460.20001" x1="178.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line616" y2="460.20001" x2="178.60001" y1="461.79999" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line618" y2="461.79999" x2="179.10001" y1="463.5" x1="179.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line620" y2="463.5" x2="179.5" y1="465.10001" x1="180" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line622" y2="363" x2="309.10001" y1="363" x1="308.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line624" y2="363" x2="308.39999" y1="362.89999" x1="307.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line626" y2="367" x2="200.2" y1="367" x1="200.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line628" y2="367" x2="200.3" y1="367" x1="200.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line630" y2="362.89999" x2="305" y1="362.79999" x1="303.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line632" y2="362.79999" x2="303.70001" y1="362.79999" x1="302.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line634" y2="367" x2="200.5" y1="367" x1="200.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line636" y2="367" x2="200.60001" y1="367" x1="200.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line638" y2="366.89999" x2="435.5" y1="366.89999" x1="433.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line640" y2="366.89999" x2="433.79999" y1="366.89999" x1="432" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line642" y2="437.5" x2="173" y1="440.10001" x1="173.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line644" y2="440.10001" x2="173.60001" y1="440.20001" x1="173.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line646" y2="440.20001" x2="173.60001" y1="437.5" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line648" y2="366.89999" x2="432" y1="366.89999" x1="430.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line650" y2="367" x2="200.8" y1="367" x1="200.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line652" y2="367" x2="200.89999" y1="367" x1="201.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line654" y2="392.89999" x2="162.7" y1="394.89999" x1="163.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line656" y2="394.89999" x2="163.2" y1="397.10001" x1="163.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line658" y2="397.10001" x2="163.7" y1="399.5" x1="164.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line660" y2="399.5" x2="164.3" y1="401.89999" x1="164.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line662" y2="401.89999" x2="164.89999" y1="404.39999" x1="165.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line664" y2="404.39999" x2="165.39999" y1="406.89999" x1="166" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line666" y2="406.89999" x2="166" y1="410.79999" x1="166.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line668" y2="410.79999" x2="166.89999" y1="414.70001" x1="167.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line670" y2="414.70001" x2="167.7" y1="418.10001" x1="168.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line672" y2="418.10001" x2="168.5" y1="421.39999" x1="169.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line674" y2="421.39999" x2="169.2" y1="425.10001" x1="170" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line676" y2="425.10001" x2="170" y1="428.70001" x1="170.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line678" y2="428.70001" x2="170.89999" y1="430.89999" x1="171.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line680" y2="430.89999" x2="171.39999" y1="433.10001" x1="171.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line682" y2="433.10001" x2="171.89999" y1="435.29999" x1="172.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line684" y2="435.29999" x2="172.39999" y1="437.5" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line686" y2="367" x2="201.10001" y1="367" x1="201.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line688" y2="367" x2="201.2" y1="367" x1="201.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line690" y2="367" x2="201.39999" y1="367" x1="201.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line692" y2="367" x2="201.5" y1="367" x1="201.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line694" y2="367" x2="201.60001" y1="367" x1="201.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line696" y2="367" x2="201.8" y1="367" x1="201.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line698" y2="367" x2="201.89999" y1="367" x1="202" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line700" y2="367" x2="202" y1="367" x1="202.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line702" y2="367" x2="202.2" y1="367" x1="202.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line704" y2="367" x2="202.3" y1="367" x1="202.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line706" y2="367" x2="202.39999" y1="367" x1="202.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line708" y2="367" x2="202.5" y1="367" x1="202.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line710" y2="367" x2="202.60001" y1="367" x1="202.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line712" y2="367" x2="202.7" y1="367" x1="202.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line714" y2="367" x2="202.8" y1="367" x1="202.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line716" y2="366.20001" x2="386.70001" y1="366.10001" x1="384.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line718" y2="366.10001" x2="384.70001" y1="366" x1="382.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line720" y2="366" x2="382.79999" y1="366" x1="380.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line722" y2="366" x2="380.89999" y1="365.89999" x1="378.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line724" y2="365.89999" x2="378.89999" y1="365.79999" x1="377" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line726" y2="365.79999" x2="377" y1="365.70001" x1="375.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line728" y2="365.70001" x2="375.10001" y1="365.60001" x1="373.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line730" y2="365.60001" x2="373.10001" y1="365.60001" x1="371.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line732" y2="365.60001" x2="371.20001" y1="365.5" x1="369.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line734" y2="365.5" x2="369.20001" y1="365.39999" x1="367.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line736" y2="365.39999" x2="367.29999" y1="365.29999" x1="365.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line738" y2="365.29999" x2="365.39999" y1="365.20001" x1="363.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line740" y2="365.20001" x2="363.39999" y1="365.10001" x1="361.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line742" y2="365.10001" x2="361.5" y1="365" x1="359.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line744" y2="365" x2="359.5" y1="365" x1="357.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line746" y2="365" x2="357.60001" y1="364.89999" x1="355.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line748" y2="364.89999" x2="355.70001" y1="364.79999" x1="353.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line750" y2="364.79999" x2="353.70001" y1="364.70001" x1="351.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line752" y2="364.70001" x2="351.79999" y1="364.60001" x1="349.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line754" y2="364.60001" x2="349.79999" y1="364.5" x1="347.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line756" y2="364.5" x2="347.89999" y1="364.39999" x1="346" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line758" y2="364.39999" x2="346" y1="364.29999" x1="344" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line760" y2="364.29999" x2="344" y1="364.29999" x1="342.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line762" y2="364.29999" x2="342.10001" y1="364.20001" x1="340.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line764" y2="364.20001" x2="340.10001" y1="364.10001" x1="338.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line766" y2="364.10001" x2="338.20001" y1="364" x1="336.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line768" y2="364" x2="336.29999" y1="363.89999" x1="334.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line770" y2="363.89999" x2="334.29999" y1="363.79999" x1="331.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line772" y2="363.79999" x2="331.29999" y1="363.70001" x1="328.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line774" y2="363.70001" x2="328.39999" y1="363.60001" x1="325.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line776" y2="363.60001" x2="325.39999" y1="363.39999" x1="322.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line778" y2="363.39999" x2="322.39999" y1="363.29999" x1="319.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line780" y2="363.29999" x2="319.5" y1="363.20001" x1="316.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line782" y2="363.20001" x2="316.5" y1="363.20001" x1="315" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line784" y2="363.20001" x2="315" y1="363.10001" x1="313.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line786" y2="363.10001" x2="313.5" y1="363.10001" x1="312" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line788" y2="367" x2="202.89999" y1="366.89999" x1="203" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line790" y2="366.89999" x2="203" y1="366.89999" x1="203" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line792" y2="366.89999" x2="203" y1="366.89999" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line794" y2="366.89999" x2="203.10001" y1="366.89999" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line796" y2="483.79999" x2="188.89999" y1="483.70001" x1="188" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line798" y2="483.70001" x2="188" y1="483.5" x1="187.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line800" y2="483.70001" x2="190" y1="483.60001" x1="190.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line802" y2="373" x2="158.39999" y1="372.10001" x1="158.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line804" y2="372.10001" x2="158.10001" y1="370.29999" x1="157.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line806" y2="370.29999" x2="157.8" y1="368.79999" x1="157.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line808" y2="478.10001" x2="203.2" y1="479.10001" x1="202.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line810" y2="479.10001" x2="202.39999" y1="479.29999" x1="202.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line812" y2="479.29999" x2="202.2" y1="479.39999" x1="202.10001" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline814" points="157.4,379.1 158.4,379 159.2,378.9 159.4,378.9   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line816" y2="479.79999" x2="201.8" y1="479.5" x1="202.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line818" y2="479.5" x2="202.10001" y1="479.39999" x1="202.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line820" y2="469" x2="181.2" y1="467.70001" x1="180.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line822" y2="467.70001" x2="180.8" y1="466.70001" x1="180.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line824" y2="379.10001" x2="157.39999" y1="379.29999" x1="156.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line826" y2="359.20001" x2="171.10001" y1="359.10001" x1="171.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line828" y2="475.10001" x2="183.10001" y1="476.89999" x1="183.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line830" y2="476.89999" x2="183.7" y1="478.5" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line832" y2="474.79999" x2="204.5" y1="474.5" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line834" y2="474.5" x2="204.60001" y1="474.5" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line836" y2="482.70001" x2="186.39999" y1="482.89999" x1="186.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line838" y2="482.89999" x2="186.60001" y1="483.20001" x1="187" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line840" y2="359.10001" x2="171.39999" y1="359" x1="171.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line842" y2="367" x2="197.8" y1="367" x1="197.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line844" y2="367" x2="197.8" y1="367" x1="198" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line846" y2="361.79999" x2="193.39999" y1="360.70001" x1="187.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line848" y2="360.70001" x2="187.89999" y1="360.39999" x1="186.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line850" y2="360.39999" x2="186.39999" y1="360.29999" x1="185.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line852" y2="360.29999" x2="185.7" y1="360.29999" x1="185.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line854" y2="360.29999" x2="185.39999" y1="359.39999" x1="178.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line856" y2="359.39999" x2="178.39999" y1="359.39999" x1="177.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line858" y2="359.39999" x2="177.89999" y1="359.20001" x1="177" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line860" y2="359.20001" x2="177" y1="359" x1="175.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line862" y2="359" x2="175.5" y1="359" x1="174.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line864" y2="367" x2="198" y1="367" x1="198.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line866" y2="367" x2="198.10001" y1="367" x1="198.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line868" y2="367" x2="198.2" y1="367" x1="198.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line870" y2="367" x2="198.3" y1="367" x1="198.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line872" y2="367" x2="198.5" y1="367" x1="198.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line874" y2="367" x2="198.7" y1="367" x1="198.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line876" y2="367" x2="198.8" y1="367" x1="199" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line878" y2="367" x2="199" y1="367" x1="199.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line880" y2="367" x2="199.2" y1="367" x1="199.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line882" y2="362.79999" x2="196.60001" y1="363" x1="196.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line884" y2="363" x2="196.8" y1="363.20001" x1="197" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line886" y2="363.20001" x2="197" y1="362.79999" x1="196.60001" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path888" d="M 436.5,375.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line890" y2="375.5" x2="436.5" y1="375.5" x1="434" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line892" y2="375.5" x2="434" y1="375.5" x1="431.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line894" y2="362.70001" x2="196.3" y1="362.60001" x1="196.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line896" y2="362.60001" x2="196.10001" y1="361.79999" x1="193.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line898" y2="375.5" x2="431.5" y1="375.5" x1="429" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line900" y2="375.5" x2="429" y1="375.5" x1="426.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line902" y2="363.20001" x2="197" y1="363.70001" x1="197.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line904" y2="363.70001" x2="197.2" y1="378.20001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line906" y2="378.20001" x2="199.89999" y1="378.70001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line908" y2="375.5" x2="426.39999" y1="375.5" x1="423.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line910" y2="375.5" x2="423.89999" y1="375.60001" x1="421.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line912" y2="375.60001" x2="421.39999" y1="375.60001" x1="418.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line914" y2="375.60001" x2="418.89999" y1="375.60001" x1="416.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line916" y2="375.60001" x2="416.39999" y1="375.60001" x1="413.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line918" y2="375.60001" x2="413.89999" y1="375.70001" x1="411.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line920" y2="375.70001" x2="411.39999" y1="375.70001" x1="408.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line922" y2="375.70001" x2="408.79999" y1="375.70001" x1="406.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line924" y2="375.70001" x2="406.29999" y1="375.79999" x1="403.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line926" y2="375.79999" x2="403.79999" y1="375.89999" x1="401.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line928" y2="375.89999" x2="401.29999" y1="375.89999" x1="398.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line930" y2="375.89999" x2="398.70001" y1="376" x1="396.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line932" y2="376" x2="396.20001" y1="376.10001" x1="393.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line934" y2="465.5" x2="205.39999" y1="463.5" x1="205.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line936" y2="463.5" x2="205.3" y1="459.60001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line938" y2="459.60001" x2="205" y1="459.60001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line940" y2="366.39999" x2="392.5" y1="366.5" x1="394.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line942" y2="366.5" x2="394.39999" y1="366.5" x1="396.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line944" y2="366.5" x2="396.29999" y1="366.60001" x1="399.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line946" y2="366.60001" x2="399.20001" y1="366.70001" x1="402.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line948" y2="366.70001" x2="402.10001" y1="366.70001" x1="405" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line950" y2="366.70001" x2="405" y1="366.79999" x1="407.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line952" y2="366.79999" x2="407.89999" y1="366.79999" x1="409.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line954" y2="366.79999" x2="409.79999" y1="366.79999" x1="411.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line956" y2="366.79999" x2="411.70001" y1="366.79999" x1="413.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line958" y2="366.79999" x2="413.70001" y1="366.79999" x1="415.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line960" y2="366.79999" x2="415.60001" y1="366.89999" x1="417.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line962" y2="366.89999" x2="417.5" y1="366.89999" x1="419.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line964" y2="366.89999" x2="419.5" y1="366.89999" x1="421.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line966" y2="366.89999" x2="421.39999" y1="366.89999" x1="423.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line968" y2="379" x2="199.7" y1="378.70001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line970" y2="378.70001" x2="199.89999" y1="379" x1="199.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line972" y2="379" x2="199.7" y1="379.29999" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line974" y2="379.29999" x2="199.60001" y1="379.10001" x1="199.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line976" y2="366.89999" x2="423.29999" y1="366.89999" x1="425.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line978" y2="366.89999" x2="425.10001" y1="366.89999" x1="426.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line980" y2="366.89999" x2="426.79999" y1="366.89999" x1="428.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line982" y2="366.89999" x2="428.5" y1="366.89999" x1="430.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line984" y2="376.10001" x2="393.60001" y1="376.20001" x1="391" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line986" y2="376.20001" x2="391" y1="376.29999" x1="388.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line988" y2="376.29999" x2="388.60001" y1="376.39999" x1="386.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line990" y2="376.39999" x2="386.10001" y1="376.5" x1="383.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line992" y2="376.5" x2="383.60001" y1="376.60001" x1="381.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line994" y2="376.60001" x2="381.10001" y1="376.70001" x1="378.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line996" y2="376.70001" x2="378.60001" y1="376.79999" x1="376.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line998" y2="362.60001" x2="289.70001" y1="362.60001" x1="289.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1000" y2="362.60001" x2="289.39999" y1="362.60001" x1="289.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1002" y2="362.60001" x2="289.10001" y1="362.60001" x1="288.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1004" y2="362.60001" x2="288.39999" y1="362.60001" x1="287.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1006" y2="362.60001" x2="287.10001" y1="362.60001" x1="285.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1008" y2="362.60001" x2="291.5" y1="362.60001" x1="290.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1010" y2="362.60001" x2="290.89999" y1="362.60001" x1="290.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1012" y2="366.89999" x2="203.3" y1="366.89999" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1014" y2="366.89999" x2="203.3" y1="366.89999" x1="203.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1016" y2="366.89999" x2="203.39999" y1="366.89999" x1="203.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1018" y2="366.89999" x2="203.5" y1="366.89999" x1="203.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1020" y2="366.89999" x2="203.5" y1="366.89999" x1="203.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1022" y2="366.89999" x2="203.60001" y1="366.89999" x1="203.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1024" y2="366.89999" x2="203.60001" y1="366.89999" x1="203.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1026" y2="366.89999" x2="203.7" y1="366.89999" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1028" y2="366.89999" x2="203.8" y1="366.89999" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1030" y2="366.89999" x2="203.8" y1="366.89999" x1="203.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1032" y2="366.89999" x2="203.89999" y1="366.89999" x1="204" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1034" y2="366.89999" x2="204" y1="366.89999" x1="204" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1036" y2="474.39999" x2="204.60001" y1="473.79999" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1038" y2="473.79999" x2="204.8" y1="474.5" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1040" y2="474.5" x2="204.60001" y1="475" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1042" y2="475" x2="204.5" y1="474.79999" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1044" y2="366.89999" x2="204" y1="366.89999" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1046" y2="366.89999" x2="204.10001" y1="366.89999" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1048" y2="366.89999" x2="204.10001" y1="366.89999" x1="204.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1050" y2="366.89999" x2="204.2" y1="366.89999" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1052" y2="366.89999" x2="204.3" y1="366.89999" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1054" y2="366.89999" x2="204.3" y1="366.89999" x1="204.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1056" y2="366.89999" x2="204.39999" y1="366.89999" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1058" y2="366.89999" x2="204.5" y1="366.89999" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1060" y2="366.89999" x2="204.5" y1="366.89999" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1062" y2="366.89999" x2="204.60001" y1="366.89999" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1064" y2="366.89999" x2="204.60001" y1="366.89999" x1="204.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1066" y2="366.89999" x2="204.7" y1="366.89999" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1068" y2="366.89999" x2="204.8" y1="366.89999" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1070" y2="366.89999" x2="204.8" y1="366.89999" x1="204.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1072" y2="366.89999" x2="204.89999" y1="366.89999" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1074" y2="366.89999" x2="205" y1="366.89999" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1076" y2="366.89999" x2="205" y1="366.89999" x1="205.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1078" y2="366.89999" x2="205.10001" y1="366.89999" x1="205.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1080" y2="366.89999" x2="205.10001" y1="366.89999" x1="205.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1082" y2="366.89999" x2="205.2" y1="366.89999" x1="205.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1084" y2="366.89999" x2="205.3" y1="366.89999" x1="205.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1086" y2="366.89999" x2="205.3" y1="366.89999" x1="205.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1088" y2="366.89999" x2="205.39999" y1="366.89999" x1="205.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1090" y2="366.89999" x2="205.5" y1="366.79999" x1="205.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1092" y2="366.79999" x2="205.5" y1="366.79999" x1="205.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1094" y2="366.79999" x2="205.60001" y1="366.79999" x1="205.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1096" y2="366.79999" x2="205.7" y1="366.79999" x1="205.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1098" y2="367.10001" x2="201.5" y1="367.10001" x1="201.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1100" y2="367.10001" x2="201.60001" y1="367.10001" x1="201.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1102" y2="367.10001" x2="201.7" y1="367.10001" x1="201.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1104" y2="367.10001" x2="201.89999" y1="367.10001" x1="202" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1106" y2="367.10001" x2="202" y1="367.10001" x1="202.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1108" y2="367.10001" x2="202.2" y1="367.10001" x1="202.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1110" y2="367.10001" x2="202.3" y1="367.10001" x1="202.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1112" y2="367.10001" x2="202.39999" y1="367.10001" x1="202.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1114" y2="367.10001" x2="202.60001" y1="367.10001" x1="202.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1116" y2="367.10001" x2="202.7" y1="367.10001" x1="202.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1118" y2="367.10001" x2="202.89999" y1="367.10001" x1="203" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1120" y2="367.10001" x2="203" y1="367.10001" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1122" y2="367.10001" x2="203.10001" y1="367.10001" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1124" y2="367.10001" x2="203.3" y1="367.10001" x1="203.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1126" y2="367.10001" x2="203.39999" y1="367.10001" x1="203.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1128" y2="367.10001" x2="203.60001" y1="367.10001" x1="203.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1130" y2="367.10001" x2="203.7" y1="367.10001" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1132" y2="367.10001" x2="203.8" y1="367.10001" x1="204" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1134" y2="367.10001" x2="204" y1="367.10001" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1136" y2="367.10001" x2="204.10001" y1="367.10001" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1138" y2="367.10001" x2="204.3" y1="367.10001" x1="204.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1140" y2="367.10001" x2="204.39999" y1="367.10001" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1142" y2="367.10001" x2="204.5" y1="367.10001" x1="204.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1144" y2="367.10001" x2="204.7" y1="367.10001" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1146" y2="367.10001" x2="204.8" y1="367.10001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1148" y2="367.10001" x2="205" y1="367.10001" x1="205.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1150" y2="367.10001" x2="205.10001" y1="367.10001" x1="205.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1152" y2="367.10001" x2="205.2" y1="367.10001" x1="205.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1154" y2="367.10001" x2="205.39999" y1="367.10001" x1="205.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1156" y2="367.10001" x2="205.5" y1="367.10001" x1="205.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1158" y2="367.10001" x2="205.7" y1="367.10001" x1="205.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1160" y2="367.10001" x2="205.8" y1="367.10001" x1="205.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1162" y2="367.10001" x2="205.89999" y1="367.10001" x1="206.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1164" y2="367.10001" x2="206.10001" y1="367.10001" x1="206.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1166" y2="367.10001" x2="206.2" y1="367.10001" x1="206.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1168" y2="367.10001" x2="206.39999" y1="367.10001" x1="206.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1170" y2="367.10001" x2="206.5" y1="367.10001" x1="206.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1172" y2="367.10001" x2="206.60001" y1="367.10001" x1="206.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1174" y2="367.10001" x2="206.8" y1="367.10001" x1="206.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1176" y2="367.10001" x2="206.89999" y1="367.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1178" y2="374.10001" x2="438.10001" y1="374.10001" x1="436.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1180" y2="374.10001" x2="436.60001" y1="374.10001" x1="434.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1182" y2="374.10001" x2="434.10001" y1="374.10001" x1="431.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1184" y2="374.10001" x2="431.60001" y1="374.20001" x1="429" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1186" y2="374.20001" x2="429" y1="374.20001" x1="426.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1188" y2="374.20001" x2="426.5" y1="374.20001" x1="424" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1190" y2="374.20001" x2="424" y1="374.20001" x1="421.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1192" y2="374.20001" x2="421.5" y1="374.20001" x1="419" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1194" y2="374.20001" x2="419" y1="374.20001" x1="416.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1196" y2="374.20001" x2="416.39999" y1="374.29999" x1="413.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1198" y2="374.29999" x2="413.89999" y1="374.29999" x1="411.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1200" y2="374.29999" x2="411.39999" y1="374.29999" x1="408.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1202" y2="374.29999" x2="408.89999" y1="374.39999" x1="406.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1204" y2="374.39999" x2="406.29999" y1="374.39999" x1="403.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1206" y2="374.39999" x2="403.79999" y1="374.5" x1="401.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1208" y2="374.5" x2="401.29999" y1="374.60001" x1="398.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1210" y2="374.60001" x2="398.70001" y1="374.60001" x1="396.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1212" y2="374.60001" x2="396.20001" y1="374.70001" x1="393.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1214" y2="374.70001" x2="393.60001" y1="374.79999" x1="391" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1216" y2="374.79999" x2="391" y1="374.89999" x1="388.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1218" y2="374.89999" x2="388.5" y1="375" x1="386" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1220" y2="375" x2="386" y1="375.10001" x1="383.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1222" y2="375.10001" x2="383.5" y1="375.20001" x1="381" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1224" y2="375.20001" x2="381" y1="375.29999" x1="378.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1226" y2="375.29999" x2="378.5" y1="375.39999" x1="376" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1228" y2="375.39999" x2="376" y1="375.5" x1="373.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1230" y2="375.5" x2="373.60001" y1="375.60001" x1="371.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1232" y2="375.60001" x2="371.10001" y1="375.70001" x1="368.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1234" y2="375.70001" x2="368.5" y1="375.89999" x1="366" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1236" y2="375.89999" x2="366" y1="376" x1="363.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1238" y2="376" x2="363.5" y1="376.10001" x1="361" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1240" y2="376.10001" x2="361" y1="376.20001" x1="358.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1242" y2="376.20001" x2="358.5" y1="376.29999" x1="355.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1244" y2="376.29999" x2="355.89999" y1="376.39999" x1="353.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1246" y2="376.39999" x2="353.39999" y1="376.5" x1="350.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1248" y2="376.5" x2="350.89999" y1="376.70001" x1="348.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1250" y2="376.70001" x2="348.39999" y1="376.79999" x1="345.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1252" y2="376.79999" x2="345.89999" y1="376.89999" x1="343.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1254" y2="376.89999" x2="343.29999" y1="377" x1="340.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1256" y2="377" x2="340.79999" y1="377.10001" x1="338.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1258" y2="377.10001" x2="338.29999" y1="377.20001" x1="335.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1260" y2="377.20001" x2="335.79999" y1="377.29999" x1="333.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1262" y2="377.29999" x2="333.29999" y1="377.39999" x1="330.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1264" y2="377.39999" x2="330.79999" y1="377.5" x1="328.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1266" y2="377.5" x2="328.20001" y1="377.60001" x1="325.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1268" y2="377.60001" x2="325.70001" y1="377.70001" x1="323.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1270" y2="377.70001" x2="323.20001" y1="377.79999" x1="320.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1272" y2="377.79999" x2="320.70001" y1="377.89999" x1="318.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1274" y2="377.89999" x2="318.20001" y1="378" x1="315.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1276" y2="378" x2="315.60001" y1="378.10001" x1="313.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1278" y2="378.10001" x2="313.10001" y1="378.20001" x1="310.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1280" y2="378.20001" x2="310.60001" y1="378.20001" x1="308.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1282" y2="378.20001" x2="308.10001" y1="378.29999" x1="305.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1284" y2="378.29999" x2="305.60001" y1="378.39999" x1="303.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1286" y2="378.39999" x2="303.10001" y1="378.39999" x1="300.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1288" y2="378.39999" x2="300.5" y1="378.39999" x1="298" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1290" y2="378.39999" x2="298" y1="378.39999" x1="295.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1292" y2="378.39999" x2="295.5" y1="378.5" x1="293" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1294" y2="378.5" x2="293" y1="378.5" x1="290.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1296" y2="378.5" x2="290.5" y1="378.5" x1="288" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1298" y2="378.5" x2="288" y1="378.5" x1="285.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1300" y2="378.5" x2="285.39999" y1="378.5" x1="282.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1302" y2="378.5" x2="282.89999" y1="378.5" x1="280.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1304" y2="378.5" x2="280.39999" y1="378.5" x1="277.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1306" y2="378.5" x2="277.89999" y1="378.5" x1="275.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1308" y2="378.5" x2="275.39999" y1="378.5" x1="272.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1310" y2="378.5" x2="272.79999" y1="378.5" x1="270.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1312" y2="378.5" x2="270.29999" y1="378.5" x1="266.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1314" y2="378.5" x2="266.39999" y1="378.5" x1="262.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1316" y2="378.5" x2="262.39999" y1="378.5" x1="258.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1318" y2="378.5" x2="258.39999" y1="378.5" x1="254.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1320" y2="378.5" x2="254.5" y1="378.5" x1="250.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1322" y2="378.5" x2="250.5" y1="378.5" x1="246.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1324" y2="378.5" x2="246.60001" y1="378.5" x1="242.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1326" y2="378.5" x2="242.60001" y1="378.5" x1="238.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1328" y2="378.5" x2="238.7" y1="378.5" x1="234.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1330" y2="378.5" x2="234.7" y1="378.5" x1="230.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1332" y2="378.5" x2="230.8" y1="378.5" x1="226.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1334" y2="378.5" x2="226.8" y1="378.5" x1="222.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1336" y2="378.5" x2="222.8" y1="378.5" x1="218.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1338" y2="378.5" x2="218.89999" y1="378.5" x1="214.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1340" y2="378.5" x2="214.89999" y1="378.5" x1="211" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1342" y2="378.5" x2="211" y1="378.5" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1344" y2="465.5" x2="205.39999" y1="463.5" x1="205.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1346" y2="463.5" x2="205.3" y1="459.60001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1348" y2="459.60001" x2="205" y1="459.60001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1350" y2="379.60001" x2="182.7" y1="379.60001" x1="182.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1352" y2="379.60001" x2="182.7" y1="379.39999" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1354" y2="372.5" x2="160.7" y1="372.79999" x1="160.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1356" y2="372.79999" x2="160.60001" y1="373.20001" x1="160.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1358" y2="373.20001" x2="160.5" y1="373.60001" x1="160.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1360" y2="373.60001" x2="160.39999" y1="374.20001" x1="160.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1362" y2="374.20001" x2="160.3" y1="374.70001" x1="160.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1364" y2="374.70001" x2="160.3" y1="375.20001" x1="160.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1366" y2="375.20001" x2="160.39999" y1="375.70001" x1="160.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1368" y2="375.70001" x2="160.39999" y1="376.20001" x1="160.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1370" y2="376.20001" x2="160.5" y1="376.79999" x1="160.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1372" y2="376.79999" x2="160.5" y1="377.60001" x1="160.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1374" y2="377.60001" x2="160.7" y1="378.60001" x1="160.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1376" y2="378.60001" x2="160.8" y1="379.60001" x1="161.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1378" y2="379.60001" x2="161.10001" y1="380.20001" x1="161.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1380" y2="380.20001" x2="161.2" y1="381" x1="161.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1382" y2="381" x2="161.39999" y1="381.79999" x1="161.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1384" y2="381.79999" x2="161.60001" y1="383.10001" x1="161.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1386" y2="383.10001" x2="161.89999" y1="384.5" x1="162.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1388" y2="384.5" x2="162.2" y1="385.39999" x1="162.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1390" y2="385.39999" x2="162.5" y1="387.39999" x1="162.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1392" y2="387.39999" x2="162.89999" y1="389.60001" x1="163.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1394" y2="389.60001" x2="163.39999" y1="390.79999" x1="163.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1396" y2="390.79999" x2="163.7" y1="392.10001" x1="164" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1398" y2="392.10001" x2="164" y1="393.20001" x1="164.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1400" y2="393.20001" x2="164.3" y1="395.20001" x1="164.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1402" y2="395.20001" x2="164.8" y1="397.29999" x1="165.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1404" y2="397.29999" x2="165.2" y1="399.5" x1="165.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1406" y2="399.5" x2="165.8" y1="401.79999" x1="166.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1408" y2="401.79999" x2="166.3" y1="404.20001" x1="166.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1410" y2="404.20001" x2="166.8" y1="404.70001" x1="166.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1412" y2="366.89999" x2="203.3" y1="366.89999" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1414" y2="366.89999" x2="203.3" y1="366.89999" x1="203.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1416" y2="366.89999" x2="203.39999" y1="366.89999" x1="203.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1418" y2="366.89999" x2="203.5" y1="366.89999" x1="203.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1420" y2="366.89999" x2="203.5" y1="366.89999" x1="203.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1422" y2="366.89999" x2="203.60001" y1="366.89999" x1="203.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1424" y2="366.89999" x2="203.60001" y1="366.89999" x1="203.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1426" y2="366.89999" x2="203.7" y1="366.89999" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1428" y2="366.89999" x2="203.8" y1="366.89999" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1430" y2="366.89999" x2="203.8" y1="366.89999" x1="203.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1432" y2="366.89999" x2="203.89999" y1="366.89999" x1="204" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1434" y2="366.89999" x2="204" y1="366.89999" x1="204" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1436" y2="366.89999" x2="204" y1="366.89999" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1438" y2="366.89999" x2="204.10001" y1="366.89999" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1440" y2="366.89999" x2="204.10001" y1="366.89999" x1="204.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1442" y2="366.89999" x2="204.2" y1="366.89999" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1444" y2="366.89999" x2="204.3" y1="366.89999" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1446" y2="366.89999" x2="204.3" y1="366.89999" x1="204.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1448" y2="366.89999" x2="204.39999" y1="366.89999" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1450" y2="366.89999" x2="204.5" y1="366.89999" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1452" y2="366.89999" x2="204.5" y1="366.89999" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1454" y2="366.89999" x2="204.60001" y1="366.89999" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1456" y2="366.89999" x2="204.60001" y1="366.89999" x1="204.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1458" y2="366.89999" x2="204.7" y1="366.89999" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1460" y2="366.89999" x2="204.8" y1="366.89999" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1462" y2="366.89999" x2="204.8" y1="366.89999" x1="204.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1464" y2="366.89999" x2="204.89999" y1="366.89999" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1466" y2="366.89999" x2="205" y1="366.89999" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1468" y2="366.89999" x2="205" y1="366.89999" x1="205.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1470" y2="366.89999" x2="205.10001" y1="366.89999" x1="205.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1472" y2="366.89999" x2="205.10001" y1="366.89999" x1="205.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1474" y2="366.89999" x2="205.2" y1="366.89999" x1="205.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1476" y2="366.89999" x2="205.3" y1="366.89999" x1="205.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1478" y2="366.89999" x2="205.3" y1="366.89999" x1="205.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1480" y2="366.89999" x2="205.39999" y1="366.89999" x1="205.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1482" y2="366.89999" x2="205.5" y1="366.79999" x1="205.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1484" y2="366.79999" x2="205.5" y1="366.79999" x1="205.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1486" y2="366.79999" x2="205.60001" y1="366.79999" x1="205.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1488" y2="366.79999" x2="205.60001" y1="366.79999" x1="205.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1490" y2="366.79999" x2="205.7" y1="366.79999" x1="205.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1492" y2="366.79999" x2="205.8" y1="366.79999" x1="205.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1494" y2="366.79999" x2="205.8" y1="366.79999" x1="205.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1496" y2="366.79999" x2="205.89999" y1="366.79999" x1="206" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1498" y2="366.79999" x2="206" y1="366.79999" x1="206" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1500" y2="366.79999" x2="206" y1="366.79999" x1="206.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1502" y2="366.79999" x2="206.10001" y1="366.79999" x1="206.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1504" y2="366.79999" x2="206.10001" y1="366.79999" x1="206.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1506" y2="366.79999" x2="206.2" y1="366.79999" x1="206.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1508" y2="366.79999" x2="206.3" y1="366.79999" x1="206.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1510" y2="366.79999" x2="206.3" y1="366.79999" x1="206.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1512" y2="366.79999" x2="206.39999" y1="366.79999" x1="206.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1514" y2="366.79999" x2="206.39999" y1="366.79999" x1="206.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1516" y2="366.79999" x2="206.5" y1="366.79999" x1="206.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1518" y2="366.79999" x2="206.60001" y1="366.79999" x1="206.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1520" y2="366.79999" x2="206.60001" y1="366.79999" x1="206.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1522" y2="366.79999" x2="206.7" y1="366.79999" x1="206.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1524" y2="366.79999" x2="206.8" y1="366.79999" x1="206.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1526" y2="366.79999" x2="206.8" y1="366.79999" x1="206.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1528" y2="366.79999" x2="206.89999" y1="366.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1530" y2="366.79999" x2="207" y1="366.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1532" y2="359" x2="173.60001" y1="359" x1="173.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1534" y2="359" x2="173.60001" y1="359" x1="173.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1536" y2="359" x2="173.3" y1="359" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1538" y2="359" x2="173" y1="359" x1="172.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1540" y2="359" x2="172.7" y1="359" x1="172.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1542" y2="359" x2="172.5" y1="359" x1="172.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1544" y2="359" x2="172.10001" y1="359" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1546" y2="359" x2="173" y1="359" x1="174.2" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline1548" points="199.6,481.2 199.5,481.3 190.5,483.5 190.7,483.4   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1550" y2="481.20001" x2="199.60001" y1="481.10001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1552" y2="481.10001" x2="199.89999" y1="480.89999" x1="200.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1554" y2="480.89999" x2="200.2" y1="480.5" x1="201" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1556" y2="359" x2="173" y1="359" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1558" y2="359" x2="173" y1="359" x1="172.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1560" y2="379.29999" x2="156.89999" y1="379.70001" x1="156.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1562" y2="379.70001" x2="156.5" y1="380.29999" x1="156.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1564" y2="380.29999" x2="156.3" y1="380.79999" x1="156.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1566" y2="380.79999" x2="156.39999" y1="387.60001" x1="158.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1568" y2="387.60001" x2="158.2" y1="394.5" x1="159.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1570" y2="394.5" x2="159.89999" y1="394.89999" x1="160.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1572" y2="394.89999" x2="160.2" y1="395.29999" x1="160.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1574" y2="395.29999" x2="160.60001" y1="395.5" x1="161.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1576" y2="395.5" x2="161.10001" y1="395.5" x1="162.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1578" y2="395.5" x2="162.39999" y1="395.70001" x1="163.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1580" y2="363.60001" x2="159.5" y1="364.10001" x1="158.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1582" y2="364.10001" x2="158.89999" y1="365" x1="158" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1584" y2="365" x2="158" y1="366.39999" x1="157.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1586" y2="366.39999" x2="157.7" y1="367.5" x1="157.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1588" y2="367.39999" x2="157.60001" y1="367" x1="157.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1590" y2="367" x2="157.60001" y1="366.39999" x1="157.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1592" y2="376.60001" x2="158.89999" y1="376.60001" x1="158.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1594" y2="377.5" x2="159.10001" y1="378.5" x1="159.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1596" y2="466.70001" x2="180.5" y1="469" x1="181.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1598" y2="469" x2="181.2" y1="471.39999" x1="181.89999" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline1600" points="181.9,471.4 182.3,472.5 182.5,473.1 182.8,474.2   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1602" y2="478.5" x2="184.2" y1="477.70001" x1="183.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1604" y2="477.70001" x2="183.89999" y1="476.89999" x1="183.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1606" y2="476.89999" x2="183.7" y1="476" x1="183.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1608" y2="476" x2="183.39999" y1="475.10001" x1="183.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1610" y2="475.10001" x2="183.10001" y1="474.20001" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1612" y2="480.79999" x2="185.10001" y1="480.29999" x1="184.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1614" y2="480.29999" x2="184.89999" y1="479.79999" x1="184.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1616" y2="479.79999" x2="184.7" y1="478.60001" x1="184.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1618" y2="478.60001" x2="184.3" y1="478.5" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1620" y2="478.5" x2="184.2" y1="479.60001" x1="184.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1622" y2="479.60001" x2="184.60001" y1="480.10001" x1="184.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1624" y2="480.10001" x2="184.89999" y1="480.60001" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1626" y2="480.60001" x2="185.10001" y1="480.60001" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1628" y2="482.29999" x2="186.10001" y1="481.70001" x1="185.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1630" y2="481.70001" x2="185.7" y1="480.79999" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1632" y2="480.79999" x2="185.10001" y1="481.29999" x1="185.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1634" y2="417.20001" x2="183.39999" y1="421.10001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1636" y2="421.10001" x2="184.2" y1="421.10001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1638" y2="421.10001" x2="184.2" y1="417.20001" x1="183.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1640" y2="440.20001" x2="173.60001" y1="442.10001" x1="174.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1642" y2="442.10001" x2="174.10001" y1="445.70001" x1="175" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1644" y2="445.70001" x2="175" y1="449.10001" x1="175.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1646" y2="449.10001" x2="175.8" y1="452.29999" x1="176.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1648" y2="452.29999" x2="176.60001" y1="455.20001" x1="177.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1650" y2="455.20001" x2="177.3" y1="457.70001" x1="178" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1652" y2="457.70001" x2="178" y1="460.20001" x1="178.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1654" y2="460.20001" x2="178.60001" y1="461.79999" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1656" y2="461.79999" x2="179.10001" y1="463.5" x1="179.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1658" y2="463.5" x2="179.5" y1="465.10001" x1="180" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1660" y2="437.5" x2="173" y1="440.10001" x1="173.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1662" y2="440.10001" x2="173.60001" y1="440.20001" x1="173.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1664" y2="440.20001" x2="173.60001" y1="437.5" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1666" y2="392.89999" x2="162.7" y1="394.89999" x1="163.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1668" y2="394.89999" x2="163.2" y1="397.10001" x1="163.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1670" y2="397.10001" x2="163.7" y1="399.5" x1="164.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1672" y2="399.5" x2="164.3" y1="401.89999" x1="164.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1674" y2="401.89999" x2="164.89999" y1="404.39999" x1="165.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1676" y2="404.39999" x2="165.39999" y1="406.89999" x1="166" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1678" y2="406.89999" x2="166" y1="410.79999" x1="166.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1680" y2="410.79999" x2="166.89999" y1="414.70001" x1="167.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1682" y2="414.70001" x2="167.7" y1="418.10001" x1="168.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1684" y2="418.10001" x2="168.5" y1="421.39999" x1="169.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1686" y2="421.39999" x2="169.2" y1="425.10001" x1="170" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1688" y2="425.10001" x2="170" y1="428.70001" x1="170.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1690" y2="428.70001" x2="170.89999" y1="430.89999" x1="171.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1692" y2="430.89999" x2="171.39999" y1="433.10001" x1="171.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1694" y2="433.10001" x2="171.89999" y1="435.29999" x1="172.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1696" y2="435.29999" x2="172.39999" y1="437.5" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1698" y2="391.70001" x2="162.5" y1="390.29999" x1="162.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1700" y2="390.29999" x2="162.10001" y1="389" x1="161.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1702" y2="389" x2="161.8" y1="386.70001" x1="161.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1704" y2="386.70001" x2="161.3" y1="389.10001" x1="161.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1706" y2="389.10001" x2="161.8" y1="390.39999" x1="162.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1708" y2="390.39999" x2="162.10001" y1="392.89999" x1="162.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1710" y2="378.5" x2="159.3" y1="380.10001" x1="159.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1712" y2="380.10001" x2="159.7" y1="381" x1="159.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1714" y2="381" x2="159.89999" y1="382.39999" x1="160.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1716" y2="382.39999" x2="160.3" y1="382.10001" x1="160.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1718" y2="382.10001" x2="160.2" y1="381" x1="159.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1720" y2="379" x2="182.89999" y1="376.70001" x1="182.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1722" y2="376.70001" x2="182.89999" y1="374.39999" x1="182.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1724" y2="374.39999" x2="182.89999" y1="374.10001" x1="182.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1726" y2="374.10001" x2="182.89999" y1="373.79999" x1="182.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1728" y2="373.79999" x2="182.89999" y1="373.5" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1730" y2="373.5" x2="182.8" y1="373.20001" x1="182.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1732" y2="373.20001" x2="182.7" y1="372.20001" x1="182.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1734" y2="372.20001" x2="182.3" y1="371.10001" x1="181.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1736" y2="371.10001" x2="181.89999" y1="370.10001" x1="181.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1738" y2="370.10001" x2="181.39999" y1="369" x1="181" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1740" y2="369" x2="181" y1="368.70001" x1="180.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1742" y2="368.70001" x2="180.8" y1="368.5" x1="180.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1744" y2="368.5" x2="180.60001" y1="368.5" x1="180.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1746" y2="379.60001" x2="182.7" y1="379.79999" x1="181.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1748" y2="379.79999" x2="181.8" y1="380" x1="180.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1750" y2="469" x2="181.2" y1="467.70001" x1="180.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1752" y2="467.70001" x2="180.8" y1="466.70001" x1="180.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1754" y2="475.10001" x2="183.10001" y1="476.89999" x1="183.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1756" y2="476.89999" x2="183.7" y1="478.5" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1758" y2="482.70001" x2="186.39999" y1="482.89999" x1="186.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1760" y2="482.89999" x2="186.60001" y1="483.20001" x1="187" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1762" y2="395.5" x2="161.7" y1="395.5" x1="162.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1764" y2="395.5" x2="162.39999" y1="395.60001" x1="163.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1766" y2="395.60001" x2="163.10001" y1="395.60001" x1="163.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1768" y2="361.79999" x2="193.39999" y1="360.70001" x1="187.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1770" y2="360.70001" x2="187.89999" y1="360.39999" x1="186.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1772" y2="360.39999" x2="186.39999" y1="360.29999" x1="185.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1774" y2="360.29999" x2="185.7" y1="360.29999" x1="185.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1776" y2="360.29999" x2="185.39999" y1="359.39999" x1="178.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1778" y2="359.39999" x2="178.39999" y1="359.39999" x1="177.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1780" y2="359.39999" x2="177.89999" y1="359.20001" x1="177" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1782" y2="359.20001" x2="177" y1="359" x1="175.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1784" y2="359" x2="175.5" y1="359" x1="174.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1786" y2="362.79999" x2="196.60001" y1="363" x1="196.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1788" y2="363" x2="196.8" y1="363.20001" x1="197" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1790" y2="363.20001" x2="197" y1="362.79999" x1="196.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1792" y2="362.70001" x2="196.3" y1="362.60001" x1="196.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1794" y2="362.60001" x2="196.10001" y1="361.79999" x1="193.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1796" y2="363.20001" x2="197" y1="363.70001" x1="197.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1798" y2="363.70001" x2="197.2" y1="378.20001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1800" y2="378.20001" x2="199.89999" y1="378.70001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1802" y2="473.79999" x2="204.8" y1="472.70001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1804" y2="472.70001" x2="205" y1="471.60001" x1="205.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1806" y2="471.60001" x2="205.2" y1="470.60001" x1="205.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1808" y2="470.60001" x2="205.3" y1="468.39999" x1="205.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1810" y2="468.39999" x2="205.39999" y1="466.10001" x1="205.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1812" y2="466.10001" x2="205.39999" y1="465.5" x1="205.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1814" y2="465.5" x2="205.39999" y1="459.60001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1816" y2="459.60001" x2="205" y1="458.39999" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1818" y2="458.39999" x2="204.8" y1="454.10001" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1820" y2="454.10001" x2="204.3" y1="451.70001" x1="203.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1822" y2="451.70001" x2="203.89999" y1="439.60001" x1="201.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1824" y2="439.60001" x2="201.89999" y1="436.60001" x1="201.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1826" y2="436.60001" x2="201.39999" y1="427.70001" x1="199.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1828" y2="427.70001" x2="199.8" y1="423.60001" x1="199" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1830" y2="423.60001" x2="199" y1="417.70001" x1="198" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1832" y2="417.70001" x2="198" y1="411.5" x1="197" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1834" y2="411.5" x2="197" y1="404.10001" x1="196" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1836" y2="404.10001" x2="196" y1="402.29999" x1="195.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1838" y2="402.29999" x2="195.8" y1="399.29999" x1="195.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1840" y2="399.29999" x2="195.5" y1="398.5" x1="195.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1842" y2="398.5" x2="195.39999" y1="395.70001" x1="195.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1844" y2="395.70001" x2="195.2" y1="394.29999" x1="195.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1846" y2="394.29999" x2="195.10001" y1="392.89999" x1="195.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1848" y2="392.89999" x2="195.10001" y1="390.10001" x1="195.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1850" y2="390.10001" x2="195.2" y1="388.79999" x1="195.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1852" y2="388.79999" x2="195.2" y1="387.60001" x1="195.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1854" y2="387.60001" x2="195.39999" y1="386.39999" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1856" y2="380" x2="198.89999" y1="380.79999" x1="198.2" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path1858" d="m 196.1,384.4 0.9,-1.9 c 0,0 0.2,-0.4 0.4,-0.6 l 0.8,-1.1" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1860" y2="379" x2="199.7" y1="378.70001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1862" y2="378.70001" x2="199.89999" y1="379" x1="199.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1864" y2="379" x2="199.7" y1="379.29999" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1866" y2="379.29999" x2="199.60001" y1="379.10001" x1="199.7" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path1868" d="M 190.5,474.9" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1870" y2="377" x2="171" y1="378.29999" x1="171.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1872" y2="378.29999" x2="171.8" y1="380.20001" x1="172.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1874" y2="380.20001" x2="172.8" y1="384" x1="174.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1876" y2="384" x2="174.5" y1="386.10001" x1="175.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1878" y2="386.10001" x2="175.3" y1="386.70001" x1="175.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1880" y2="386.70001" x2="175.5" y1="390.89999" x1="176.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1882" y2="390.89999" x2="176.89999" y1="397.5" x1="178.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1884" y2="397.5" x2="178.7" y1="405.29999" x1="180.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1886" y2="405.29999" x2="180.60001" y1="421.10001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1888" y2="421.10001" x2="184.2" y1="423.39999" x1="184.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1890" y2="423.39999" x2="184.7" y1="438.39999" x1="187.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1892" y2="438.39999" x2="187.8" y1="446.29999" x1="189.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1894" y2="446.29999" x2="189.3" y1="449.10001" x1="189.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1896" y2="449.10001" x2="189.8" y1="460.20001" x1="191.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1898" y2="460.20001" x2="191.39999" y1="466.60001" x1="191.89999" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path1900" d="m 191.9,466.6 v 0.4 c 0,0 0,5.7 -1.2,7.9 0,0 -1,2.8 -3.1,2.6 h -0.3" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path1902" d="M 165.6,371.6" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path1904"
          d="m 162.9,371 0.2,-0.1 c 1.2,-0.4 2.5,0.6 2.5,0.6 l 1.3,0.9 c 0.1,0.1 0.2,0.1 0.3,0.2 0.5,0.4 2.1,1.8 2.2,2 l 1.6,2.2"
          class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1906" y2="474.39999" x2="204.60001" y1="473.79999" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1908" y2="473.79999" x2="204.8" y1="474.5" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1910" y2="474.5" x2="204.60001" y1="475" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1912" y2="475" x2="204.5" y1="474.79999" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1914" y2="478.10001" x2="203.2" y1="476.89999" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1916" y2="476.89999" x2="203.8" y1="475" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1918" y2="399.29999" x2="195.5" y1="395.70001" x1="195.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1920" y2="395.70001" x2="195.2" y1="388.79999" x1="195.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1922" y2="364" x2="195.8" y1="363.70001" x1="195.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1924" y2="363.70001" x2="195.8" y1="363.5" x1="195.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1926" y2="363.5" x2="195.7" y1="363.29999" x1="195.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1928" y2="363.29999" x2="195.7" y1="363.10001" x1="195.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1930" y2="363.10001" x2="195.7" y1="362.89999" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1932" y2="362.89999" x2="195.60001" y1="362.79999" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1934" y2="362.79999" x2="195.60001" y1="362.70001" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1936" y2="362.70001" x2="195.60001" y1="362.60001" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1938" y2="362.60001" x2="195.60001" y1="362.60001" x1="195.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1940" y2="362.60001" x2="195.8" y1="362.60001" x1="196.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1942" y2="366" x2="184.39999" y1="365.79999" x1="184.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1944" y2="365.79999" x2="184.39999" y1="365.60001" x1="184.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1946" y2="365.60001" x2="184.39999" y1="365.29999" x1="184.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1948" y2="365.29999" x2="184.3" y1="365.10001" x1="184.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1950" y2="365.10001" x2="184.3" y1="364.89999" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1952" y2="364.89999" x2="184.2" y1="364.79999" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1954" y2="364.79999" x2="184.2" y1="364.70001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1956" y2="364.70001" x2="184.2" y1="364.60001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1958" y2="364.60001" x2="184.2" y1="364.70001" x1="184" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1960" y2="364.70001" x2="184" y1="364.79999" x1="183.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1962" y2="364.79999" x2="183.8" y1="365" x1="183.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1964" y2="365" x2="183.60001" y1="365.20001" x1="183.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1966" y2="365.20001" x2="183.39999" y1="365.5" x1="183.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1968" y2="365.5" x2="183.2" y1="365.70001" x1="183.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1970" y2="365.70001" x2="183.2" y1="366" x1="183.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1972" y2="366" x2="183.10001" y1="366.20001" x1="183.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1974" y2="359.39999" x2="177.89999" y1="359.39999" x1="177.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1976" y2="359.39999" x2="177.89999" y1="359.39999" x1="177.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1978" y2="359.39999" x2="177.39999" y1="359.5" x1="177.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1980" y2="359.5" x2="177.8" y1="359.70001" x1="179.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1982" y2="359.70001" x2="179.3" y1="359.89999" x1="181" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1984" y2="359.89999" x2="181" y1="360.10001" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1986" y2="360.10001" x2="182.8" y1="360.29999" x1="184.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1988" y2="360.29999" x2="184.89999" y1="360.29999" x1="185.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1990" y2="483.60001" x2="190.2" y1="483.5" x1="190.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1992" y2="483.39999" x2="190.7" y1="483.29999" x1="190.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1994" y2="483.29999" x2="190.89999" y1="483.10001" x1="191.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1996" y2="483.10001" x2="191.2" y1="482.89999" x1="191.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line1998" y2="482.89999" x2="191.39999" y1="482.70001" x1="191.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2000" y2="482.70001" x2="191.60001" y1="482.5" x1="191.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2002" y2="482.5" x2="191.89999" y1="482.20001" x1="192.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2004" y2="482.20001" x2="192.10001" y1="482" x1="192.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2006" y2="482" x2="192.39999" y1="481.70001" x1="192.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2008" y2="481.70001" x2="192.60001" y1="481.39999" x1="192.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2010" y2="481.39999" x2="192.8" y1="481" x1="193.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2012" y2="481" x2="193.10001" y1="480.60001" x1="193.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2014" y2="480.60001" x2="193.3" y1="480.20001" x1="193.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2016" y2="480.20001" x2="193.5" y1="479.70001" x1="193.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2018" y2="479.70001" x2="193.7" y1="479.20001" x1="193.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2020" y2="479.20001" x2="193.89999" y1="478.60001" x1="194.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2022" y2="478.60001" x2="194.10001" y1="478" x1="194.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2024" y2="478" x2="194.3" y1="477.20001" x1="194.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2026" y2="477.20001" x2="194.5" y1="476.5" x1="194.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2028" y2="476.5" x2="194.60001" y1="475.70001" x1="194.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2030" y2="475.70001" x2="194.7" y1="474.89999" x1="194.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2032" y2="474.89999" x2="194.8" y1="473.89999" x1="194.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2034" y2="473.89999" x2="194.89999" y1="472.89999" x1="195" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2036" y2="472.89999" x2="195" y1="471.89999" x1="195" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2038" y2="471.89999" x2="195" y1="470.70001" x1="195" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2040" y2="470.70001" x2="195" y1="469.5" x1="195" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2042" y2="469.5" x2="195" y1="468" x1="195" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2044" y2="468" x2="195" y1="466.60001" x1="194.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2046" y2="466.60001" x2="194.89999" y1="465.20001" x1="194.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2048" y2="465.20001" x2="194.8" y1="463.79999" x1="194.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2050" y2="463.79999" x2="194.60001" y1="462.29999" x1="194.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2052" y2="462.29999" x2="194.5" y1="460.79999" x1="194.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2054" y2="460.79999" x2="194.3" y1="459.29999" x1="194.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2056" y2="459.29999" x2="194.10001" y1="457.70001" x1="193.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2058" y2="457.70001" x2="193.89999" y1="456.10001" x1="193.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2060" y2="456.10001" x2="193.60001" y1="454.5" x1="193.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2062" y2="454.5" x2="193.39999" y1="452.79999" x1="193.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2064" y2="452.79999" x2="193.10001" y1="451.20001" x1="192.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2066" y2="451.20001" x2="192.89999" y1="449.5" x1="192.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2068" y2="449.5" x2="192.60001" y1="447.79999" x1="192.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2070" y2="447.79999" x2="192.3" y1="446.10001" x1="192" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2072" y2="446.10001" x2="192" y1="444.39999" x1="191.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2074" y2="444.39999" x2="191.8" y1="442.70001" x1="191.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2076" y2="442.70001" x2="191.5" y1="441" x1="191.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2078" y2="441" x2="191.2" y1="439.29999" x1="190.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2080" y2="439.29999" x2="190.89999" y1="437.60001" x1="190.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2082" y2="437.60001" x2="190.5" y1="435.89999" x1="190.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2084" y2="435.89999" x2="190.2" y1="434.10001" x1="189.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2086" y2="434.10001" x2="189.89999" y1="432.39999" x1="189.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2088" y2="432.39999" x2="189.60001" y1="430.60001" x1="189.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2090" y2="430.60001" x2="189.3" y1="428.79999" x1="189" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2092" y2="428.79999" x2="189" y1="427.10001" x1="188.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2094" y2="427.10001" x2="188.60001" y1="425.29999" x1="188.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2096" y2="425.29999" x2="188.3" y1="423.5" x1="188" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2098" y2="423.5" x2="188" y1="421.70001" x1="187.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2100" y2="421.70001" x2="187.60001" y1="419.89999" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2102" y2="419.89999" x2="187.3" y1="418.10001" x1="186.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2104" y2="418.10001" x2="186.89999" y1="416.39999" x1="186.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2106" y2="416.39999" x2="186.60001" y1="414.60001" x1="186.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2108" y2="414.60001" x2="186.3" y1="412.79999" x1="185.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2110" y2="412.79999" x2="185.89999" y1="411" x1="185.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2112" y2="411" x2="185.60001" y1="409.20001" x1="185.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2114" y2="409.20001" x2="185.3" y1="407.5" x1="185" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2116" y2="407.5" x2="185" y1="405.20001" x1="184.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2118" y2="405.20001" x2="184.60001" y1="403" x1="184.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2120" y2="403" x2="184.3" y1="401.20001" x1="184" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2122" y2="401.20001" x2="184" y1="399.39999" x1="183.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2124" y2="399.39999" x2="183.8" y1="397.70001" x1="183.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2126" y2="397.70001" x2="183.60001" y1="396" x1="183.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2128" y2="396" x2="183.5" y1="393.10001" x1="183.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2130" y2="393.10001" x2="183.39999" y1="391.60001" x1="183.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2132" y2="391.60001" x2="183.39999" y1="390.10001" x1="183.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2134" y2="390.10001" x2="183.60001" y1="389" x1="183.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2136" y2="389" x2="183.7" y1="387.79999" x1="184" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2138" y2="387.79999" x2="184" y1="386.70001" x1="184.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2140" y2="386.70001" x2="184.3" y1="385.70001" x1="184.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2142" y2="385.70001" x2="184.7" y1="384.89999" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2144" y2="384.89999" x2="185.10001" y1="384" x1="185.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2146" y2="384" x2="185.60001" y1="383.29999" x1="186.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2148" y2="383.29999" x2="186.10001" y1="382.60001" x1="186.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2150" y2="382.60001" x2="186.60001" y1="381.79999" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2152" y2="380.5" x2="187.10001" y1="380.70001" x1="187.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2154" y2="380.70001" x2="187.10001" y1="380.89999" x1="187.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2156" y2="380.89999" x2="187.2" y1="381.10001" x1="187.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2158" y2="381.10001" x2="187.2" y1="381.29999" x1="187.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2160" y2="381.29999" x2="187.2" y1="381.39999" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2162" y2="381.39999" x2="187.3" y1="381.60001" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2164" y2="381.60001" x2="187.3" y1="381.70001" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2166" y2="381.70001" x2="187.3" y1="381.79999" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2168" y2="381.79999" x2="187.3" y1="381.79999" x1="187.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2170" y2="381.79999" x2="187.10001" y1="381.79999" x1="186.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2172" y2="381.79999" x2="186.89999" y1="381.70001" x1="186.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2174" y2="381.70001" x2="186.60001" y1="381.5" x1="186.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2176" y2="381.5" x2="186.39999" y1="381.39999" x1="186.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2178" y2="381.39999" x2="186.2" y1="381.20001" x1="186" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2180" y2="381.20001" x2="186" y1="380.89999" x1="185.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2182" y2="380.89999" x2="185.89999" y1="380.70001" x1="185.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2184" y2="399.29999" x2="195.5" y1="399.5" x1="195.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2186" y2="399.5" x2="195.3" y1="399.60001" x1="194.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2188" y2="399.60001" x2="194.7" y1="399.70001" x1="194.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2190" y2="399.70001" x2="194.10001" y1="402.70001" x1="194.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2192" y2="402.70001" x2="194.39999" y1="404.5" x1="194.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2194" y2="404.5" x2="194.60001" y1="408.20001" x1="195.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2196" y2="408.20001" x2="195.10001" y1="410.10001" x1="195.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2198" y2="410.10001" x2="195.39999" y1="411.89999" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2200" y2="411.89999" x2="195.60001" y1="415" x1="196.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2202" y2="415" x2="196.10001" y1="418.20001" x1="196.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2204" y2="418.20001" x2="196.7" y1="421.10001" x1="197.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2206" y2="421.10001" x2="197.2" y1="424.10001" x1="197.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2208" y2="378.5" x2="198.5" y1="378.79999" x1="198.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2210" y2="378.79999" x2="198.5" y1="379" x1="198.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2212" y2="379" x2="198.60001" y1="379.29999" x1="198.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2214" y2="379.29999" x2="198.60001" y1="379.39999" x1="198.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2216" y2="379.39999" x2="198.60001" y1="379.60001" x1="198.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2218" y2="379.60001" x2="198.7" y1="379.70001" x1="198.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2220" y2="379.70001" x2="198.7" y1="379.79999" x1="198.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2222" y2="379.79999" x2="198.7" y1="379.79999" x1="199" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2224" y2="379.79999" x2="199" y1="379.70001" x1="199.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2226" y2="379.70001" x2="199.2" y1="379.5" x1="199.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2228" y2="423.60001" x2="199" y1="423.79999" x1="198.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2230" y2="423.79999" x2="198.8" y1="424" x1="198.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2232" y2="424" x2="198.3" y1="424.10001" x1="197.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2234" y2="424.10001" x2="197.7" y1="426.20001" x1="198.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2236" y2="426.20001" x2="198.10001" y1="428.29999" x1="198.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2238" y2="428.29999" x2="198.5" y1="430.5" x1="198.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2240" y2="430.5" x2="198.89999" y1="432.79999" x1="199.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2242" y2="432.79999" x2="199.3" y1="435" x1="199.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2244" y2="435" x2="199.7" y1="437.20001" x1="200.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2246" y2="437.20001" x2="200.10001" y1="438.79999" x1="200.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2248" y2="438.79999" x2="200.39999" y1="440.29999" x1="200.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2250" y2="440.29999" x2="200.7" y1="441.79999" x1="200.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2252" y2="441.79999" x2="200.89999" y1="443.39999" x1="201.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2254" y2="443.39999" x2="201.2" y1="444.89999" x1="201.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2256" y2="444.89999" x2="201.5" y1="446.39999" x1="201.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2258" y2="446.39999" x2="201.7" y1="448" x1="202" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2260" y2="448" x2="202" y1="449.5" x1="202.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2262" y2="449.5" x2="202.2" y1="451" x1="202.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2264" y2="451" x2="202.39999" y1="452.39999" x1="202.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2266" y2="452.39999" x2="202.7" y1="454.89999" x1="203" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2268" y2="454.89999" x2="203" y1="457.10001" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2270" y2="457.10001" x2="203.3" y1="459.29999" x1="203.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2272" y2="459.29999" x2="203.60001" y1="460.5" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2274" y2="460.5" x2="203.8" y1="462.10001" x1="203.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2276" y2="462.10001" x2="203.89999" y1="463.70001" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2278" y2="463.70001" x2="204.10001" y1="465.10001" x1="204.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2280" y2="465.10001" x2="204.2" y1="466.60001" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2282" y2="466.60001" x2="204.3" y1="467.10001" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2284" y2="467.10001" x2="204.3" y1="468.29999" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2286" y2="468.29999" x2="204.3" y1="469.5" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2288" y2="469.5" x2="204.3" y1="470.60001" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2290" y2="470.60001" x2="204.3" y1="471.70001" x1="204.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2292" y2="471.70001" x2="204.2" y1="472.79999" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2294" y2="472.79999" x2="204.10001" y1="473.79999" x1="203.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2296" y2="473.79999" x2="203.89999" y1="475" x1="203.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2298" y2="475" x2="203.7" y1="475.70001" x1="203.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2300" y2="475.70001" x2="203.5" y1="476.70001" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2302" y2="476.70001" x2="203.3" y1="477.5" x1="202.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2304" y2="477.5" x2="202.89999" y1="478.20001" x1="202.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2306" y2="478.20001" x2="202.60001" y1="478.79999" x1="202.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2308" y2="478.79999" x2="202.3" y1="479.29999" x1="201.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2310" y2="479.29999" x2="201.89999" y1="479.79999" x1="201.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2312" y2="479.79999" x2="201.60001" y1="480" x1="201.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2314" y2="480" x2="201.3" y1="480.20001" x1="201.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2316" y2="480.20001" x2="201.10001" y1="480.39999" x1="200.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2318" y2="480.39999" x2="200.89999" y1="480.60001" x1="200.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2320" y2="480.60001" x2="200.7" y1="480.79999" x1="200.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2322" y2="480.79999" x2="200.5" y1="480.89999" x1="200.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2324" y2="366.20001" x2="183.10001" y1="373.5" x1="184.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2326" y2="373.5" x2="184.39999" y1="380.70001" x1="185.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2328" y2="380.5" x2="187.10001" y1="380.5" x1="186.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2330" y2="380.5" x2="186.89999" y1="380.5" x1="186.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2332" y2="380.5" x2="186.7" y1="380.60001" x1="186.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2334" y2="380.60001" x2="186.5" y1="380.60001" x1="186.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2336" y2="380.60001" x2="186.3" y1="380.70001" x1="186.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2338" y2="380.70001" x2="186.2" y1="380.70001" x1="186" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2340" y2="380.70001" x2="186" y1="380.70001" x1="185.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2342" y2="380.70001" x2="185.89999" y1="380.70001" x1="185.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2344" y2="380.70001" x2="185.8" y1="381.60001" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2346" y2="381.60001" x2="185.10001" y1="382.20001" x1="184.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2348" y2="382.20001" x2="184.5" y1="383" x1="184" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2350" y2="383" x2="184" y1="383.89999" x1="183.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2352" y2="383.89999" x2="183.60001" y1="384.79999" x1="183.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2354" y2="384.79999" x2="183.10001" y1="385.79999" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2356" y2="385.79999" x2="182.8" y1="387" x1="182.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2358" y2="387" x2="182.5" y1="388.29999" x1="182.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2360" y2="388.29999" x2="182.2" y1="389.5" x1="182" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2362" y2="389.5" x2="182" y1="391" x1="181.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2364" y2="391" x2="181.89999" y1="392.70001" x1="181.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2366" y2="392.70001" x2="181.89999" y1="394.20001" x1="181.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2368" y2="394.20001" x2="181.89999" y1="395.79999" x1="182" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2370" y2="395.79999" x2="182" y1="397.5" x1="182.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2372" y2="397.5" x2="182.2" y1="399.29999" x1="182.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2374" y2="399.29999" x2="182.39999" y1="401.10001" x1="182.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2376" y2="401.10001" x2="182.60001" y1="403" x1="182.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2378" y2="403" x2="182.89999" y1="405.20001" x1="183.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2380" y2="405.20001" x2="183.2" y1="407.5" x1="183.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2382" y2="407.5" x2="183.60001" y1="409.29999" x1="183.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2384" y2="409.29999" x2="183.89999" y1="411.10001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2386" y2="411.10001" x2="184.2" y1="412.89999" x1="184.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2388" y2="412.89999" x2="184.60001" y1="414.70001" x1="184.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2390" y2="414.70001" x2="184.89999" y1="416.5" x1="185.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2392" y2="416.5" x2="185.2" y1="418.20001" x1="185.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2394" y2="418.20001" x2="185.60001" y1="420" x1="185.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2396" y2="420" x2="185.89999" y1="421.79999" x1="186.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2398" y2="421.79999" x2="186.2" y1="423.5" x1="186.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2400" y2="423.5" x2="186.60001" y1="425.29999" x1="186.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2402" y2="425.29999" x2="186.89999" y1="427.10001" x1="187.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2404" y2="427.10001" x2="187.2" y1="428.79999" x1="187.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2406" y2="428.79999" x2="187.60001" y1="430.60001" x1="187.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2408" y2="430.60001" x2="187.89999" y1="432.29999" x1="188.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2410" y2="432.29999" x2="188.2" y1="434.10001" x1="188.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2412" y2="434.10001" x2="188.5" y1="435.79999" x1="188.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2414" y2="435.79999" x2="188.8" y1="437.5" x1="189.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2416" y2="437.5" x2="189.10001" y1="439.20001" x1="189.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2418" y2="439.20001" x2="189.39999" y1="440.89999" x1="189.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2420" y2="440.89999" x2="189.7" y1="442.60001" x1="190" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2422" y2="442.60001" x2="190" y1="444.29999" x1="190.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2424" y2="444.29999" x2="190.3" y1="446" x1="190.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2426" y2="446" x2="190.60001" y1="447.70001" x1="190.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2428" y2="447.70001" x2="190.89999" y1="449.29999" x1="191.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2430" y2="449.29999" x2="191.2" y1="451" x1="191.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2432" y2="451" x2="191.39999" y1="452.60001" x1="191.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2434" y2="452.60001" x2="191.7" y1="454.20001" x1="191.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2436" y2="454.20001" x2="191.89999" y1="455.79999" x1="192.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2438" y2="455.79999" x2="192.2" y1="457.39999" x1="192.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2440" y2="457.39999" x2="192.39999" y1="458.89999" x1="192.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2442" y2="458.89999" x2="192.60001" y1="460.5" x1="192.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2444" y2="460.5" x2="192.8" y1="461.89999" x1="192.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2446" y2="461.89999" x2="192.89999" y1="463.39999" x1="193.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2448" y2="463.39999" x2="193.10001" y1="464.79999" x1="193.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2450" y2="464.79999" x2="193.2" y1="466.10001" x1="193.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2452" y2="466.10001" x2="193.3" y1="467.39999" x1="193.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2454" y2="467.39999" x2="193.39999" y1="468.89999" x1="193.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2456" y2="468.89999" x2="193.5" y1="470.10001" x1="193.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2458" y2="470.10001" x2="193.5" y1="471.20001" x1="193.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2460" y2="471.20001" x2="193.39999" y1="472.20001" x1="193.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2462" y2="472.20001" x2="193.39999" y1="473.20001" x1="193.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2464" y2="473.20001" x2="193.3" y1="474.10001" x1="193.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2466" y2="474.10001" x2="193.2" y1="474.89999" x1="193.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2468" y2="474.89999" x2="193.10001" y1="475.70001" x1="193" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2470" y2="475.70001" x2="193" y1="476.39999" x1="192.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2472" y2="476.39999" x2="192.8" y1="477.10001" x1="192.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2474" y2="477.10001" x2="192.60001" y1="477.70001" x1="192.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2476" y2="477.70001" x2="192.5" y1="478.29999" x1="192.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2478" y2="478.29999" x2="192.3" y1="478.79999" x1="192.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2480" y2="478.79999" x2="192.10001" y1="479.29999" x1="191.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2482" y2="479.29999" x2="191.8" y1="479.70001" x1="191.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2484" y2="479.70001" x2="191.60001" y1="480.10001" x1="191.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2486" y2="480.10001" x2="191.39999" y1="480.39999" x1="191.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2488" y2="480.39999" x2="191.2" y1="480.79999" x1="190.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2490" y2="480.79999" x2="190.89999" y1="481.10001" x1="190.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2492" y2="481.10001" x2="190.7" y1="481.29999" x1="190.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2494" y2="481.29999" x2="190.5" y1="481.60001" x1="190.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2496" y2="481.60001" x2="190.2" y1="481.79999" x1="190" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2498" y2="481.79999" x2="190" y1="482" x1="189.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2500" y2="482" x2="189.7" y1="482.20001" x1="189.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2502" y2="482.20001" x2="189.5" y1="482.29999" x1="189.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2504" y2="482.29999" x2="189.3" y1="482.5" x1="189" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2506" y2="482.5" x2="189" y1="482.60001" x1="188.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2508" y2="482.60001" x2="188.8" y1="482.70001" x1="188.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2510" y2="482.70001" x2="188.60001" y1="482.79999" x1="188.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2512" y2="482.79999" x2="188.3" y1="482.79999" x1="188.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2514" y2="482.79999" x2="188.10001" y1="482.79999" x1="187.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2516" y2="482.79999" x2="187.89999" y1="482.89999" x1="187.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2518" y2="482.89999" x2="187.7" y1="482.79999" x1="187.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2520" y2="482.79999" x2="187.5" y1="482.79999" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2522" y2="482.79999" x2="187.3" y1="482.79999" x1="187.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2524" y2="482.79999" x2="187.10001" y1="482.70001" x1="186.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2526" y2="482.70001" x2="186.89999" y1="482.60001" x1="186.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2528" y2="482.60001" x2="186.60001" y1="482.39999" x1="186.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2530" y2="482.39999" x2="186.39999" y1="482.29999" x1="186.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2532" y2="482.29999" x2="186.2" y1="482.10001" x1="186" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2534" y2="482.10001" x2="186" y1="481.89999" x1="185.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2536" y2="481.89999" x2="185.8" y1="481.60001" x1="185.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2538" y2="366" x2="184.39999" y1="366.10001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2540" y2="366.10001" x2="184.2" y1="366.10001" x1="184" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2542" y2="366.10001" x2="184" y1="366.10001" x1="183.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2544" y2="366.10001" x2="183.8" y1="366.20001" x1="183.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2546" y2="366.20001" x2="183.7" y1="366.20001" x1="183.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2548" y2="366.20001" x2="183.5" y1="366.20001" x1="183.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2550" y2="366.20001" x2="183.3" y1="366.20001" x1="183.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2552" y2="366.20001" x2="183.2" y1="366.20001" x1="183.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2554" y2="366.20001" x2="183.10001" y1="365.39999" x1="178.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2556" y2="365.39999" x2="178.89999" y1="364.60001" x1="175.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2558" y2="364.60001" x2="175.10001" y1="364.20001" x1="173" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2560" y2="364.20001" x2="173" y1="363.89999" x1="171" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2562" y2="363.89999" x2="171" y1="363.60001" x1="169.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2564" y2="363.60001" x2="169.2" y1="363.39999" x1="167.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2566" y2="363.39999" x2="167.5" y1="363.10001" x1="166" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2568" y2="363.10001" x2="166" y1="363" x1="164.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2570" y2="363" x2="164.60001" y1="362.89999" x1="163.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2572" y2="362.89999" x2="163.3" y1="362.89999" x1="162.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2574" y2="362.89999" x2="162.10001" y1="363" x1="161.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2576" y2="363" x2="161.2" y1="363.20001" x1="160.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2578" y2="363.20001" x2="160.3" y1="363.60001" x1="159.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2580" y2="473.39999" x2="182.60001" y1="475.10001" x1="183.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2582" y2="475.10001" x2="183.2" y1="476" x1="183.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2584" y2="476" x2="183.60001" y1="476.5" x1="183.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2586" y2="476.5" x2="183.8" y1="477.29999" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2588" y2="477.29999" x2="184.2" y1="477.89999" x1="184.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2590" y2="477.89999" x2="184.60001" y1="478.29999" x1="184.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2592" y2="478.29999" x2="184.89999" y1="478.60001" x1="185.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2594" y2="478.60001" x2="185.2" y1="478.89999" x1="185.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2596" y2="478.89999" x2="185.60001" y1="479.10001" x1="185.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2598" y2="479.10001" x2="185.89999" y1="479.20001" x1="186.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2600" y2="479.20001" x2="186.10001" y1="479.29999" x1="186.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2602" y2="479.29999" x2="186.39999" y1="479.29999" x1="186.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2604" y2="479.29999" x2="186.7" y1="479.29999" x1="187.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2606" y2="479.29999" x2="187.10001" y1="479.10001" x1="187.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2608" y2="479.10001" x2="187.5" y1="478.89999" x1="188" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2610" y2="478.89999" x2="188" y1="478.60001" x1="188.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2612" y2="478.60001" x2="188.39999" y1="478.20001" x1="188.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2614" y2="478.20001" x2="188.7" y1="477.79999" x1="189.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2616" y2="477.79999" x2="189.10001" y1="477.39999" x1="189.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2618" y2="477.39999" x2="189.5" y1="476.89999" x1="189.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2620" y2="476.89999" x2="189.8" y1="476.5" x1="190.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2622" y2="476.5" x2="190.10001" y1="476" x1="190.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2624" y2="476" x2="190.39999" y1="475.20001" x1="190.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2626" y2="475.20001" x2="190.89999" y1="474.10001" x1="191.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2628" y2="474.10001" x2="191.2" y1="473.29999" x1="191.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2630" y2="473.29999" x2="191.5" y1="471.60001" x1="191.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2632" y2="471.60001" x2="191.8" y1="469.60001" x1="192" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2634" y2="469.60001" x2="192" y1="467.39999" x1="192" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2636" y2="467.39999" x2="192" y1="467.10001" x1="192" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2638" y2="467.10001" x2="192" y1="465.60001" x1="192" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2640" y2="465.60001" x2="192" y1="464" x1="191.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2642" y2="464" x2="191.89999" y1="462.29999" x1="191.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2644" y2="462.29999" x2="191.7" y1="460.5" x1="191.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2646" y2="460.5" x2="191.5" y1="457.79999" x1="191.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2648" y2="457.79999" x2="191.2" y1="455.10001" x1="190.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2650" y2="455.10001" x2="190.8" y1="452.20001" x1="190.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2652" y2="452.20001" x2="190.39999" y1="449.29999" x1="189.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2654" y2="449.29999" x2="189.89999" y1="446.5" x1="189.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2656" y2="446.5" x2="189.39999" y1="442.60001" x1="188.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2658" y2="442.60001" x2="188.60001" y1="438.60001" x1="187.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2660" y2="438.60001" x2="187.89999" y1="434.79999" x1="187.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2662" y2="434.79999" x2="187.10001" y1="431.10001" x1="186.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2664" y2="431.10001" x2="186.3" y1="427.29999" x1="185.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2666" y2="427.29999" x2="185.60001" y1="423.5" x1="184.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2668" y2="423.5" x2="184.7" y1="421.10001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2670" y2="421.10001" x2="184.2" y1="417.20001" x1="183.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2672" y2="417.20001" x2="183.39999" y1="413.20001" x1="182.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2674" y2="413.20001" x2="182.5" y1="409.20001" x1="181.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2676" y2="409.20001" x2="181.60001" y1="405.20001" x1="180.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2678" y2="405.20001" x2="180.7" y1="401.29999" x1="179.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2680" y2="401.29999" x2="179.7" y1="397.39999" x1="178.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2682" y2="397.39999" x2="178.7" y1="394" x1="177.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2684" y2="394" x2="177.8" y1="390.70001" x1="176.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2686" y2="390.70001" x2="176.89999" y1="388.60001" x1="176.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2688" y2="388.60001" x2="176.2" y1="386.5" x1="175.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2690" y2="386.5" x2="175.5" y1="385.89999" x1="175.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2692" y2="385.89999" x2="175.3" y1="383.60001" x1="174.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2694" y2="383.60001" x2="174.39999" y1="381.60001" x1="173.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2696" y2="381.60001" x2="173.5" y1="379.70001" x1="172.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2698" y2="379.70001" x2="172.7" y1="377.79999" x1="171.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2700" y2="377.79999" x2="171.7" y1="376.5" x1="170.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2702" y2="376.5" x2="170.89999" y1="375.29999" x1="170" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2704" y2="375.29999" x2="170" y1="374.20001" x1="169.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2706" y2="374.20001" x2="169.2" y1="373.20001" x1="168.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2708" y2="373.20001" x2="168.3" y1="372.39999" x1="167.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2710" y2="372.39999" x2="167.5" y1="371.70001" x1="166.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2712" y2="371.70001" x2="166.7" y1="371.10001" x1="165.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2714" y2="371.10001" x2="165.8" y1="370.60001" x1="165" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2716" y2="370.60001" x2="165" y1="370.29999" x1="164.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2718" y2="370.29999" x2="164.3" y1="370.10001" x1="163.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2720" y2="370.10001" x2="163.7" y1="369.89999" x1="163.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2722" y2="369.89999" x2="163.2" y1="369.79999" x1="162.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2724" y2="369.79999" x2="162.60001" y1="369.79999" x1="162" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2726" y2="369.79999" x2="162" y1="369.79999" x1="161.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2728" y2="369.79999" x2="161.60001" y1="369.89999" x1="161.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2730" y2="369.89999" x2="161.2" y1="370" x1="160.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2732" y2="370" x2="160.8" y1="370.10001" x1="160.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2734" y2="370.10001" x2="160.39999" y1="370.20001" x1="160.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2736" y2="370.20001" x2="160.10001" y1="370.39999" x1="159.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2738" y2="370.39999" x2="159.8" y1="370.60001" x1="159.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2740" y2="370.60001" x2="159.60001" y1="370.79999" x1="159.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2742" y2="370.79999" x2="159.3" y1="371" x1="159.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2744" y2="371" x2="159.10001" y1="371.29999" x1="159" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2746" y2="371.29999" x2="159" y1="371.70001" x1="158.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2748" y2="371.70001" x2="158.8" y1="372.10001" x1="158.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2750" y2="372.10001" x2="158.7" y1="372.5" x1="158.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2752" y2="372.5" x2="158.60001" y1="373.10001" x1="158.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2754" y2="373.10001" x2="158.60001" y1="373.60001" x1="158.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2756" y2="373.60001" x2="158.60001" y1="374.10001" x1="158.60001" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline2758" points="158.6,374.1 158.7,374.6 158.9,376.6 159.1,377.5   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2760" y2="375.29999" x2="179" y1="375.29999" x1="178.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2762" y2="375.29999" x2="178.8" y1="375" x1="178.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2764" y2="375" x2="178.7" y1="374.70001" x1="178.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2766" y2="374.70001" x2="178.7" y1="374.5" x1="178.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2768" y2="374.5" x2="178.60001" y1="374.20001" x1="178.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2770" y2="374.20001" x2="178.60001" y1="374.10001" x1="178.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2772" y2="378.5" x2="198.5" y1="379" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2774" y2="379" x2="195.60001" y1="379.5" x1="192.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2776" y2="379.5" x2="192.8" y1="380" x1="189.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2778" y2="380" x2="189.89999" y1="380.5" x1="187.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2780" y2="380.5" x2="187.10001" y1="376.89999" x1="186.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2782" y2="376.89999" x2="186.39999" y1="373.29999" x1="185.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2784" y2="373.29999" x2="185.7" y1="369.60001" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2786" y2="369.60001" x2="185.10001" y1="366" x1="184.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2788" y2="366" x2="184.39999" y1="365.5" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2790" y2="365.5" x2="187.3" y1="365" x1="190.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2792" y2="365" x2="190.10001" y1="364.5" x1="193" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2794" y2="364.5" x2="193" y1="364" x1="195.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2796" y2="378.20001" x2="199.89999" y1="378.20001" x1="199.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2798" y2="378.20001" x2="199.8" y1="378.29999" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2800" y2="378.29999" x2="199.60001" y1="378.29999" x1="199.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2802" y2="378.29999" x2="199.39999" y1="378.39999" x1="199.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2804" y2="378.39999" x2="199.10001" y1="378.39999" x1="198.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2806" y2="378.39999" x2="198.8" y1="378.5" x1="198.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2808" y2="378.5" x2="198.5" y1="374.79999" x1="197.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2810" y2="374.79999" x2="197.8" y1="371.20001" x1="197.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2812" y2="371.20001" x2="197.10001" y1="367.60001" x1="196.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2814" y2="367.60001" x2="196.5" y1="364" x1="195.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2816" y2="364" x2="195.8" y1="363.89999" x1="196.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2818" y2="363.89999" x2="196.10001" y1="363.89999" x1="196.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2820" y2="363.89999" x2="196.39999" y1="363.79999" x1="196.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2822" y2="363.79999" x2="196.7" y1="363.79999" x1="197" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2824" y2="363.79999" x2="197" y1="363.70001" x1="197.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2826" y2="363.70001" x2="197.10001" y1="363.70001" x1="197.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2828" y2="379.10001" x2="199.7" y1="379" x1="199.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2830" y2="379" x2="199.7" y1="379" x1="199.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2832" y2="375.60001" x2="199.39999" y1="375.60001" x1="199.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2834" y2="375.60001" x2="199.5" y1="375.60001" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2836" y2="375.60001" x2="199.60001" y1="375.60001" x1="199.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2838" y2="375.60001" x2="199.8" y1="375.60001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2840" y2="375.60001" x2="199.89999" y1="375.60001" x1="200.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2842" y2="375.60001" x2="200.10001" y1="375.60001" x1="200.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2844" y2="375.60001" x2="200.2" y1="375.60001" x1="200.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2846" y2="375.60001" x2="200.3" y1="375.60001" x1="200.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2848" y2="375.60001" x2="200.5" y1="375.60001" x1="200.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2850" y2="375.60001" x2="200.60001" y1="375.60001" x1="200.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2852" y2="375.60001" x2="200.8" y1="375.60001" x1="200.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2854" y2="375.60001" x2="200.89999" y1="375.60001" x1="201" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2856" y2="375.60001" x2="201" y1="375.60001" x1="201.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2858" y2="375.60001" x2="201.2" y1="375.60001" x1="201.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2860" y2="375.60001" x2="201.3" y1="375.60001" x1="201.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2862" y2="375.60001" x2="201.5" y1="375.60001" x1="201.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2864" y2="375.60001" x2="201.60001" y1="375.60001" x1="201.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2866" y2="375.60001" x2="201.7" y1="375.60001" x1="201.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2868" y2="375.60001" x2="201.89999" y1="375.60001" x1="202" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2870" y2="375.60001" x2="202" y1="375.60001" x1="202.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2872" y2="375.60001" x2="202.2" y1="375.60001" x1="202.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2874" y2="375.60001" x2="202.3" y1="375.60001" x1="202.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2876" y2="375.60001" x2="202.39999" y1="375.60001" x1="202.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2878" y2="375.60001" x2="202.60001" y1="375.60001" x1="202.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2880" y2="375.60001" x2="202.7" y1="375.60001" x1="202.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2882" y2="375.60001" x2="202.89999" y1="375.60001" x1="203" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2884" y2="375.60001" x2="203" y1="375.60001" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2886" y2="375.60001" x2="203.10001" y1="375.60001" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2888" y2="375.60001" x2="203.3" y1="375.60001" x1="203.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2890" y2="375.60001" x2="203.39999" y1="375.60001" x1="203.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2892" y2="375.60001" x2="203.60001" y1="375.60001" x1="203.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2894" y2="375.60001" x2="203.7" y1="375.60001" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2896" y2="375.60001" x2="203.8" y1="375.60001" x1="204" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2898" y2="375.60001" x2="204" y1="375.60001" x1="204.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2900" y2="375.60001" x2="204.10001" y1="375.60001" x1="204.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2902" y2="375.60001" x2="204.3" y1="375.60001" x1="204.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2904" y2="375.60001" x2="204.39999" y1="375.60001" x1="204.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2906" y2="375.60001" x2="204.5" y1="375.60001" x1="204.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2908" y2="375.60001" x2="204.7" y1="375.60001" x1="204.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2910" y2="375.60001" x2="204.8" y1="375.60001" x1="205" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2912" y2="375.60001" x2="205" y1="375.60001" x1="205.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2914" y2="375.60001" x2="205.10001" y1="375.60001" x1="205.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2916" y2="375.60001" x2="205.2" y1="375.60001" x1="205.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2918" y2="375.60001" x2="205.39999" y1="375.60001" x1="205.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2920" y2="375.60001" x2="205.5" y1="375.60001" x1="205.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2922" y2="375.60001" x2="205.7" y1="375.60001" x1="205.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2924" y2="375.60001" x2="205.8" y1="375.60001" x1="205.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2926" y2="375.60001" x2="205.89999" y1="375.60001" x1="206.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2928" y2="375.60001" x2="206.10001" y1="375.60001" x1="206.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2930" y2="375.60001" x2="206.2" y1="375.60001" x1="206.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2932" y2="375.60001" x2="206.39999" y1="375.60001" x1="206.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2934" y2="375.60001" x2="206.5" y1="375.60001" x1="206.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2936" y2="375.60001" x2="206.60001" y1="375.60001" x1="206.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2938" y2="375.60001" x2="206.8" y1="375.60001" x1="206.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2940" y2="375.60001" x2="206.89999" y1="375.60001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2942" y2="366.89999" x2="203.2" y1="366.89999" x1="203.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2944" y2="366.89999" x2="203.2" y1="366.89999" x1="203.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2946" y2="375.39999" x2="179" y1="375.29999" x1="179" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2948" y2="375.29999" x2="179" y1="375" x1="178.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2950" y2="375" x2="178.89999" y1="374.70001" x1="178.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2952" y2="374.70001" x2="178.8" y1="374.39999" x1="178.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2954" y2="374.39999" x2="178.8" y1="374.10001" x1="178.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2956" y2="374.10001" x2="178.7" y1="374" x1="178.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2958" y2="380.10001" x2="180.7" y1="380.10001" x1="180.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2960" y2="380.10001" x2="180.60001" y1="380" x1="180.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2962" y2="380" x2="180.39999" y1="379.79999" x1="180.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2964" y2="379.79999" x2="180.3" y1="379.60001" x1="180.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2966" y2="379.60001" x2="180.2" y1="376.5" x1="178.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2968" y2="376.5" x2="178.89999" y1="375.89999" x1="179" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2970" y2="375.89999" x2="179" y1="375.29999" x1="179" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2972" y2="375.29999" x2="179" y1="374.60001" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2974" y2="374.60001" x2="179.10001" y1="374.60001" x1="179.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2976" y2="374.60001" x2="179.39999" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2978" y2="369" x2="178.60001" y1="369" x1="178.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2980" y2="369" x2="178.5" y1="369.20001" x1="178.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2982" y2="369.20001" x2="178.39999" y1="369.39999" x1="178.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2984" y2="369.39999" x2="178.3" y1="369.60001" x1="178.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2986" y2="369.60001" x2="178.3" y1="370.39999" x1="178.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2988" y2="370.39999" x2="178.3" y1="371.29999" x1="178.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2990" y2="371.29999" x2="178.3" y1="372.10001" x1="178.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2992" y2="372.10001" x2="178.3" y1="373" x1="178.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2994" y2="373" x2="178.3" y1="373.5" x1="178.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2996" y2="373.5" x2="178.5" y1="374.10001" x1="178.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line2998" y2="374.10001" x2="178.8" y1="374.60001" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3000" y2="374.60001" x2="179.10001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3002" y2="368.5" x2="180.60001" y1="368.5" x1="180.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3004" y2="368.5" x2="180.39999" y1="369" x1="178.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3006" y2="377.79999" x2="181.8" y1="377.70001" x1="181.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3008" y2="377.70001" x2="181.89999" y1="377.60001" x1="182" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3010" y2="377.60001" x2="182" y1="377.39999" x1="182" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3012" y2="377.39999" x2="182" y1="377.20001" x1="182" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3014" y2="377.20001" x2="182" y1="376.29999" x1="182" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3016" y2="376.29999" x2="182" y1="375.39999" x1="182.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3018" y2="375.39999" x2="182.10001" y1="374.5" x1="182.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3020" y2="374.5" x2="182.10001" y1="374.29999" x1="182.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3022" y2="374.29999" x2="182.10001" y1="374" x1="182" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3024" y2="374" x2="182" y1="373.39999" x1="181.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3026" y2="373.39999" x2="181.89999" y1="372.60001" x1="181.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3028" y2="372.60001" x2="181.5" y1="371.79999" x1="181.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3030" y2="371.79999" x2="181.2" y1="371" x1="180.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3032" y2="371" x2="180.89999" y1="370.79999" x1="180.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3034" y2="370.79999" x2="180.8" y1="370.70001" x1="180.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3036" y2="370.70001" x2="180.60001" y1="370.60001" x1="180.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3038" y2="377.79999" x2="181.8" y1="377.89999" x1="181.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3040" y2="377.89999" x2="181.39999" y1="378" x1="181.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3042" y2="378" x2="181.10001" y1="378.10001" x1="180.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3044" y2="374.5" x2="179.60001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3046" y2="374.5" x2="179.60001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3048" y2="374.5" x2="179.60001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3050" y2="374.5" x2="179.60001" y1="374" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3052" y2="375.10001" x2="179.8" y1="374.79999" x1="179.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3054" y2="374.79999" x2="179.7" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3056" y2="374.5" x2="179.60001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3058" y2="374.5" x2="179.60001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3060" y2="374.5" x2="179.60001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3062" y2="373.20001" x2="182.7" y1="371.10001" x1="181.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3064" y2="371.10001" x2="181.8" y1="369" x1="180.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3066" y2="368.5" x2="180.39999" y1="368.5" x1="180.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3068" y2="368.5" x2="180.60001" y1="368.60001" x1="180.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3070" y2="368.60001" x2="180.7" y1="368.79999" x1="180.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3072" y2="368.79999" x2="180.8" y1="369" x1="180.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3074" y2="369" x2="180.89999" y1="369" x1="181" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3076" y2="374.39999" x2="182.89999" y1="373.79999" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3078" y2="373.79999" x2="182.8" y1="373.20001" x1="182.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3080" y2="373.20001" x2="182.7" y1="373.20001" x1="182.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3082" y2="379" x2="182.8" y1="376.70001" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3084" y2="376.70001" x2="182.8" y1="374.39999" x1="182.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3086" y2="374.39999" x2="182.89999" y1="374.39999" x1="182.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3088" y2="379.60001" x2="182.5" y1="379.39999" x1="182.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3090" y2="379.39999" x2="182.7" y1="379.20001" x1="182.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3092" y2="379.20001" x2="182.8" y1="379" x1="182.8" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline3094" points="182.8,379 182.9,379 182.8,379.4   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3096" y2="371.29999" x2="179.60001" y1="371.39999" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3098" y2="371.39999" x2="179.10001" y1="372.29999" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3100" y2="372.29999" x2="179.10001" y1="373.20001" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3102" y2="373.20001" x2="179.10001" y1="374.10001" x1="179" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline3104" points="179.8,370.8 179.9,370.7 180.4,370.6 180.5,370.6   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3106" y2="370.79999" x2="179.8" y1="370.89999" x1="179.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3108" y2="370.89999" x2="179.7" y1="371.29999" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3110" y2="371.29999" x2="179.60001" y1="372.70001" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3112" y2="372.70001" x2="179.60001" y1="374" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3114" y2="374" x2="179.60001" y1="374.10001" x1="179" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3116" y2="374.10001" x2="179" y1="374.60001" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3118" y2="377.60001" x2="180.3" y1="376.79999" x1="179.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3120" y2="376.79999" x2="179.89999" y1="376" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3122" y2="376" x2="179.60001" y1="375.20001" x1="179.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3124" y2="377.5" x2="180.8" y1="376.29999" x1="180.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3126" y2="376.29999" x2="180.3" y1="375.10001" x1="179.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3128" y2="375.10001" x2="179.8" y1="375.10001" x1="179.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3130" y2="375.10001" x2="179.5" y1="375.20001" x1="179.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3132" y2="375.20001" x2="179.3" y1="374.60001" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3134" y2="377.89999" x2="181.2" y1="377.89999" x1="181.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3136" y2="377.89999" x2="181.10001" y1="377.89999" x1="181" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3138" y2="377.89999" x2="181" y1="377.70001" x1="180.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3140" y2="377.70001" x2="180.89999" y1="377.5" x1="180.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3142" y2="377.5" x2="180.8" y1="377.60001" x1="180.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3144" y2="377.60001" x2="180.3" y1="377.79999" x1="180.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3146" y2="377.79999" x2="180.39999" y1="377.89999" x1="180.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3148" y2="377.89999" x2="180.5" y1="378" x1="180.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3150" y2="378" x2="180.60001" y1="378.10001" x1="180.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3152" y2="362.60001" x2="207.2" y1="362.60001" x1="207.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3154" y2="362.60001" x2="207.2" y1="362.60001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3156" y2="362.60001" x2="207.10001" y1="362.60001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3158" y2="362.60001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3160" y2="362.70001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3162" y2="362.70001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3164" y2="362.70001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3166" y2="362.70001" x2="207.10001" y1="362.70001" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3168" y2="362.70001" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3170" y2="362.79999" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3172" y2="362.79999" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3174" y2="362.79999" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3176" y2="362.79999" x2="207.10001" y1="362.79999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3178" y2="362.79999" x2="207.10001" y1="362.89999" x1="207.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3180" y2="362.89999" x2="207.10001" y1="362.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3182" y2="362.89999" x2="207" y1="362.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3184" y2="362.89999" x2="207" y1="362.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3186" y2="362.89999" x2="207" y1="363" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3188" y2="363" x2="207" y1="363.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3190" y2="363.10001" x2="207" y1="363.29999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3192" y2="363.29999" x2="207" y1="363.60001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3194" y2="363.60001" x2="207" y1="363.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3196" y2="363.79999" x2="207" y1="364.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3198" y2="364.10001" x2="207" y1="364.29999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3200" y2="364.29999" x2="207" y1="365.20001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3202" y2="365.20001" x2="207" y1="366.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3204" y2="366.10001" x2="207" y1="367" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3206" y2="367" x2="207" y1="367.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3208" y2="367.89999" x2="207" y1="368.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3210" y2="368.79999" x2="207" y1="369.60001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3212" y2="369.60001" x2="207" y1="370.5" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3214" y2="370.5" x2="207" y1="371.39999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3216" y2="371.39999" x2="207" y1="372.29999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3218" y2="372.29999" x2="207" y1="373.20001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3220" y2="373.20001" x2="207" y1="374.10001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3222" y2="374.10001" x2="207" y1="374.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3224" y2="374.89999" x2="207" y1="375.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3226" y2="375.79999" x2="207" y1="376.70001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3228" y2="376.70001" x2="207" y1="377.60001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3230" y2="377.60001" x2="207" y1="378.5" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3232" y2="378.5" x2="207" y1="378.70001" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3234" y2="378.70001" x2="207" y1="379" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3236" y2="379" x2="207" y1="379.5" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3238" y2="379.5" x2="207" y1="379.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3240" y2="379.79999" x2="207" y1="379.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3242" y2="366.20001" x2="386.70001" y1="366.10001" x1="384.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3244" y2="366.10001" x2="384.70001" y1="366" x1="382.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3246" y2="366" x2="382.79999" y1="366" x1="380.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3248" y2="366" x2="380.89999" y1="365.89999" x1="378.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3250" y2="365.89999" x2="378.89999" y1="365.79999" x1="377" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3252" y2="365.79999" x2="377" y1="365.70001" x1="375.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3254" y2="365.70001" x2="375.10001" y1="365.60001" x1="373.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3256" y2="365.60001" x2="373.10001" y1="365.60001" x1="371.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3258" y2="365.60001" x2="371.20001" y1="365.5" x1="369.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3260" y2="365.5" x2="369.20001" y1="365.39999" x1="367.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3262" y2="365.39999" x2="367.29999" y1="365.29999" x1="365.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3264" y2="365.29999" x2="365.39999" y1="365.20001" x1="363.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3266" y2="365.20001" x2="363.39999" y1="365.10001" x1="361.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3268" y2="365.10001" x2="361.5" y1="365" x1="359.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3270" y2="365" x2="359.5" y1="365" x1="357.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3272" y2="365" x2="357.60001" y1="364.89999" x1="355.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3274" y2="364.89999" x2="355.70001" y1="364.79999" x1="353.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3276" y2="364.79999" x2="353.70001" y1="364.70001" x1="351.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3278" y2="364.70001" x2="351.79999" y1="364.60001" x1="349.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3280" y2="364.60001" x2="349.79999" y1="364.5" x1="347.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3282" y2="364.5" x2="347.89999" y1="364.39999" x1="346" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3284" y2="364.39999" x2="346" y1="364.29999" x1="344" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3286" y2="364.29999" x2="344" y1="364.29999" x1="342.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3288" y2="364.29999" x2="342.10001" y1="364.20001" x1="340.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3290" y2="364.20001" x2="340.10001" y1="364.10001" x1="338.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3292" y2="364.10001" x2="338.20001" y1="364" x1="336.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3294" y2="364" x2="336.29999" y1="363.89999" x1="334.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3296" y2="363.89999" x2="334.29999" y1="363.79999" x1="331.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3298" y2="363.79999" x2="331.29999" y1="363.70001" x1="328.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3300" y2="363.70001" x2="328.39999" y1="363.60001" x1="325.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3302" y2="363.60001" x2="325.39999" y1="363.39999" x1="322.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3304" y2="363.39999" x2="322.39999" y1="363.29999" x1="319.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3306" y2="363.29999" x2="319.5" y1="363.20001" x1="316.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3308" y2="363.20001" x2="316.5" y1="363.20001" x1="315" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3310" y2="363.20001" x2="315" y1="363.10001" x1="313.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3312" y2="363.10001" x2="313.5" y1="363.10001" x1="312" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3314" y2="359" x2="172.10001" y1="359" x1="171.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3316" y2="359" x2="171.7" y1="359.20001" x1="171.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3318" y2="482.70001" x2="186.39999" y1="482.29999" x1="186.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3320" y2="483.5" x2="187.5" y1="483.20001" x1="187" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3322" y2="362.79999" x2="196.60001" y1="362.70001" x1="196.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3324" y2="480.5" x2="201" y1="479.79999" x1="201.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3326" y2="475" x2="204.5" y1="476" x1="204.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3328" y2="481.29999" x2="185.39999" y1="480.60001" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3330" y2="483.60001" x2="190.2" y1="483.60001" x1="190.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3332" y2="359" x2="173.60001" y1="359" x1="173.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3334" y2="367.5" x2="157.60001" y1="368.79999" x1="157.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3336" y2="395.5" x2="163.39999" y1="395.5" x1="163.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3338" y2="483.10001" x2="186.89999" y1="483.10001" x1="186.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3340" y2="361.20001" x2="190.7" y1="361" x1="189.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3342" y2="362.70001" x2="196.3" y1="362.60001" x1="196" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3344" y2="386.60001" x2="195.60001" y1="386" x1="195.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3346" y2="384.70001" x2="160.8" y1="386.70001" x1="161.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3348" y2="483.10001" x2="186.8" y1="483.10001" x1="186.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3350" y2="361.5" x2="192" y1="361.60001" x1="192.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3352" y2="373.70001" x2="178.60001" y1="374.20001" x1="178.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3354" y2="375.70001" x2="179" y1="375.29999" x1="178.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3356" y2="366.89999" x2="203.2" y1="366.89999" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3358" y2="366.89999" x2="203.2" y1="366.89999" x1="203.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3360" y2="366.89999" x2="433.79999" y1="366.89999" x1="433.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3362" y2="366.10001" x2="385.10001" y1="366.39999" x1="392.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3364" y2="476" x2="204.2" y1="476.89999" x1="203.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3366" y2="361.5" x2="192" y1="361.20001" x1="190.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3368" y2="366.89999" x2="203.2" y1="366.89999" x1="203.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3370" y2="465.10001" x2="180" y1="466.70001" x1="180.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3372" y2="395.5" x2="163.3" y1="395.5" x1="163.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3374" y2="379.5" x2="199.39999" y1="379.29999" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3376" y2="366.89999" x2="203.3" y1="366.89999" x1="203.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3378" y2="380" x2="180.89999" y1="380.10001" x1="180.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3380" y2="371.60001" x2="162" y1="371.79999" x1="161.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3382" y2="371.39999" x2="162.2" y1="371.60001" x1="162" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3384" y2="371.20001" x2="162.5" y1="371.39999" x1="162.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3386" y2="371" x2="162.89999" y1="371.20001" x1="162.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3388" y2="371" x2="163.10001" y1="371" x1="162.89999" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline3390" points="178.6,451.9 180.3,457.8 180.4,458.2 181,460.6   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3392" y2="438.70001" x2="175" y1="451.89999" x1="178.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3394" y2="425.39999" x2="171.60001" y1="438.70001" x1="175" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3396" y2="411.89999" x2="168.5" y1="425.39999" x1="171.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3398" y2="398.29999" x2="165.60001" y1="411.89999" x1="168.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3400" y2="394.89999" x2="164.89999" y1="398.29999" x1="165.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3402" y2="377.10001" x2="161.3" y1="376.29999" x1="161.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3404" y2="378" x2="161.5" y1="377.10001" x1="161.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3406" y2="379" x2="161.7" y1="378" x1="161.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3408" y2="380" x2="161.89999" y1="379" x1="161.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3410" y2="384.70001" x2="162.8" y1="380" x1="161.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3412" y2="394.89999" x2="164.89999" y1="384.70001" x1="162.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3414" y2="372.10001" x2="161.5" y1="372.39999" x1="161.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3416" y2="371.79999" x2="161.7" y1="372.10001" x1="161.5" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3418" d="M 187,476.7" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3420" d="m 186.7,476.4 0.4,0.4 c 0,0 0.9,1 1.8,0.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3422" y2="475.89999" x2="186.3" y1="476.39999" x1="186.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3424" y2="475.29999" x2="185.89999" y1="475.89999" x1="186.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3426" y2="474.39999" x2="185.5" y1="475.29999" x1="185.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3428" y2="473.39999" x2="185.10001" y1="474.39999" x1="185.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3430" y2="472.10001" x2="184.60001" y1="473.39999" x1="185.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3432" y2="470.60001" x2="184.10001" y1="472.10001" x1="184.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3434" y2="375.60001" x2="161.10001" y1="376.29999" x1="161.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3436" y2="374.89999" x2="161.10001" y1="375.60001" x1="161.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3438" y2="374.29999" x2="161.10001" y1="374.89999" x1="161.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3440" y2="373.70001" x2="161.10001" y1="374.29999" x1="161.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3442" y2="373.20001" x2="161.10001" y1="373.70001" x1="161.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3444" y2="372.79999" x2="161.2" y1="373.20001" x1="161.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3446" y2="372.39999" x2="161.39999" y1="372.79999" x1="161.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3448" y2="462.79999" x2="181.7" y1="460.60001" x1="181" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3450" y2="465" x2="182.3" y1="462.79999" x1="181.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3452" y2="467" x2="182.89999" y1="465" x1="182.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3454" y2="468.89999" x2="183.5" y1="467" x1="182.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3456" y2="470.60001" x2="184.10001" y1="468.89999" x1="183.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3458" y2="382.10001" x2="160.2" y1="383.70001" x1="160.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3460" y2="383.70001" x2="160.60001" y1="384.70001" x1="160.8" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3462" d="m 171.4,359.1 -0.3,0.1 c -0.4,0.1 -8.1,2.1 -8.1,2.1" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3464" y2="359.10001" x2="171.39999" y1="359" x1="171.7" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3466" d="M 156.9,379.3" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3468" d="M 162.6,371.1" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3470" d="m 188,483.7 0.8,0.2 c 0,0 0.6,0.1 1.2,-0.1 l 0.2,-0.1" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3472" y2="483.70001" x2="188" y1="483.5" x1="187.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3474" y2="379.5" x2="199.39999" y1="380" x1="198.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3476" y2="380" x2="198.89999" y1="380" x1="198.89999" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3478" d="M 165.1,371.3" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3480" d="M 164.3,371.1" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3482" d="M 164.3,371.1" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3484" y2="478.10001" x2="203.2" y1="479.10001" x1="202.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3486" y2="479.10001" x2="202.39999" y1="479.29999" x1="202.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3488" y2="479.29999" x2="202.2" y1="479.39999" x1="202.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3490" y2="479.79999" x2="201.8" y1="479.5" x1="202.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3492" y2="479.5" x2="202.10001" y1="479.39999" x1="202.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3494" y2="474.79999" x2="204.5" y1="474.5" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3496" y2="474.5" x2="204.60001" y1="474.5" x1="204.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3498" y2="395.5" x2="161.10001" y1="395.5" x1="161.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3500" y2="395.5" x2="161.2" y1="395.5" x1="161.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3502" y2="395.5" x2="161.7" y1="395.5" x1="161.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3504" y2="374.39999" x2="158.7" y1="372.10001" x1="158.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3506" y2="372.10001" x2="158.10001" y1="370.29999" x1="157.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3508" y2="370.29999" x2="157.8" y1="368.79999" x1="157.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3510" y2="371" x2="162.89999" y1="371.10001" x1="162.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3512" y2="371.10001" x2="162.8" y1="371.10001" x1="162.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3514" y2="364.60001" x2="184.2" y1="363.60001" x1="189.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3516" y2="363.60001" x2="189.89999" y1="362.60001" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3518" y2="360.29999" x2="184.89999" y1="360.39999" x1="185.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3520" y2="360.39999" x2="185.2" y1="360.5" x1="185.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3522" y2="360.5" x2="185.89999" y1="360.70001" x1="187.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3524" y2="360.70001" x2="187.39999" y1="360.89999" x1="188.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3526" y2="360.89999" x2="188.39999" y1="361.10001" x1="189.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3528" y2="361.10001" x2="189.39999" y1="361.29999" x1="190.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3530" y2="361.29999" x2="190.39999" y1="361.5" x1="191.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3532" y2="361.5" x2="191.39999" y1="361.79999" x1="192.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3534" y2="361.79999" x2="192.5" y1="362" x1="193.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3536" y2="362" x2="193.5" y1="362.29999" x1="194.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3538" y2="362.29999" x2="194.5" y1="362.60001" x1="195.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3540" y2="361.29999" x2="164.2" y1="361.39999" x1="165.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3542" y2="361.39999" x2="165.39999" y1="361.5" x1="166.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3544" y2="361.5" x2="166.8" y1="361.70001" x1="168.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3546" y2="361.70001" x2="168.3" y1="362" x1="169.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3548" y2="362" x2="169.89999" y1="362.29999" x1="171.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3550" y2="362.29999" x2="171.60001" y1="362.60001" x1="173.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3552" y2="362.60001" x2="173.39999" y1="362.89999" x1="175.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3554" y2="362.89999" x2="175.39999" y1="363.29999" x1="177.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3556" y2="363.29999" x2="177.39999" y1="363.70001" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3558" y2="363.70001" x2="179.60001" y1="364.20001" x1="181.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3560" y2="364.20001" x2="181.8" y1="364.60001" x1="184.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3562" y2="359.39999" x2="177.39999" y1="359.29999" x1="176.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3564" y2="359.29999" x2="176.7" y1="359.20001" x1="176.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3566" y2="359.20001" x2="176.10001" y1="359.10001" x1="175.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3568" y2="359.10001" x2="175.5" y1="359.10001" x1="174.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3570" y2="359.10001" x2="174.89999" y1="359" x1="174.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3572" y2="359" x2="174.60001" y1="359" x1="174.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3574" y2="359" x2="174.3" y1="359" x1="173.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3576" y2="359" x2="173.89999" y1="359" x1="173.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3578" y2="379.79999" x2="198.7" y1="380.79999" x1="193" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3580" y2="380.79999" x2="193" y1="381.79999" x1="187.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3582" y2="379.79999" x2="198.7" y1="380.20001" x1="198.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3584" y2="380.20001" x2="198.3" y1="380.5" x1="198" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3586" y2="380.5" x2="198" y1="380.89999" x1="197.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3588" y2="380.89999" x2="197.60001" y1="381.29999" x1="197.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3590" y2="381.29999" x2="197.3" y1="381.79999" x1="196.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3592" y2="381.79999" x2="196.89999" y1="382.39999" x1="196.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3594" y2="382.39999" x2="196.5" y1="383.10001" x1="196.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3596" y2="383.10001" x2="196.10001" y1="383.70001" x1="195.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3598" y2="383.70001" x2="195.7" y1="384.5" x1="195.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3600" y2="384.5" x2="195.3" y1="385.29999" x1="195" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3602" y2="385.29999" x2="195" y1="386.20001" x1="194.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3604" y2="386.20001" x2="194.7" y1="387.20001" x1="194.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3606" y2="387.20001" x2="194.39999" y1="388.29999" x1="194.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3608" y2="388.29999" x2="194.2" y1="389.5" x1="194" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3610" y2="389.5" x2="194" y1="390.79999" x1="193.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3612" y2="390.79999" x2="193.89999" y1="392.10001" x1="193.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3614" y2="392.10001" x2="193.8" y1="393.39999" x1="193.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3616" y2="393.39999" x2="193.8" y1="394.79999" x1="193.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3618" y2="394.79999" x2="193.89999" y1="396.20001" x1="193.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3620" y2="396.20001" x2="193.89999" y1="397.60001" x1="194" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3622" y2="397.60001" x2="194" y1="399" x1="194.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3624" y2="399" x2="194.10001" y1="399.70001" x1="194.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3626" y2="380.79999" x2="156.39999" y1="380.79999" x1="156.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3628" y2="380.79999" x2="156.60001" y1="380.70001" x1="157.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3630" y2="380.70001" x2="157.3" y1="380.60001" x1="158.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3632" y2="380.60001" x2="158.3" y1="380.60001" x1="159.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3634" y2="394.5" x2="159.89999" y1="394.5" x1="160" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3636" y2="394.5" x2="160" y1="394.5" x1="160.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3638" y2="394.5" x2="160.10001" y1="394.5" x1="160.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3640" y2="394.5" x2="160.3" y1="394.5" x1="160.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3642" y2="394.5" x2="160.60001" y1="394.5" x1="160.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3644" y2="394.5" x2="160.89999" y1="394.5" x1="161.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3646" y2="394.5" x2="161.2" y1="394.5" x1="161.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3648" y2="394.5" x2="161.60001" y1="394.5" x1="162" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3650" y2="394.5" x2="162" y1="394.5" x1="162.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3652" y2="394.5" x2="162.39999" y1="394.60001" x1="162.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3654" y2="394.60001" x2="162.7" y1="394.70001" x1="163.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3656" y2="394.70001" x2="163.10001" y1="394.79999" x1="163.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3658" y2="366.39999" x2="157.7" y1="365.5" x1="158" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3660" y2="365.5" x2="158" y1="364.70001" x1="158.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3662" y2="364.70001" x2="158.39999" y1="364.10001" x1="158.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3664" y2="415.5" x2="169.3" y1="417.60001" x1="169.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3666" y2="417.60001" x2="169.8" y1="420.89999" x1="170.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3668" y2="420.89999" x2="170.5" y1="422.70001" x1="170.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3670" y2="422.70001" x2="170.89999" y1="424.39999" x1="171.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3672" y2="424.39999" x2="171.3" y1="426.20001" x1="171.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3674" y2="426.20001" x2="171.7" y1="428" x1="172.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3676" y2="428" x2="172.2" y1="430.20001" x1="172.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3678" y2="430.20001" x2="172.7" y1="432.39999" x1="173.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3680" y2="432.39999" x2="173.2" y1="434.5" x1="173.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3682" y2="434.5" x2="173.7" y1="436.60001" x1="174.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3684" y2="436.60001" x2="174.2" y1="439.29999" x1="174.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3686" y2="439.29999" x2="174.8" y1="441.20001" x1="175.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3688" y2="441.20001" x2="175.3" y1="443" x1="175.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3690" y2="443" x2="175.7" y1="444.70001" x1="176.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3692" y2="444.70001" x2="176.2" y1="446.39999" x1="176.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3694" y2="446.39999" x2="176.60001" y1="448.10001" x1="177" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3696" y2="448.10001" x2="177" y1="449.60001" x1="177.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3698" y2="449.60001" x2="177.39999" y1="451.20001" x1="177.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3700" y2="451.20001" x2="177.8" y1="454.10001" x1="178.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3702" y2="454.10001" x2="178.5" y1="456.5" x1="179.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3704" y2="456.5" x2="179.10001" y1="458.89999" x1="179.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3706" y2="458.89999" x2="179.8" y1="460.60001" x1="180.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3708" y2="460.60001" x2="180.2" y1="462.20001" x1="180.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3710" y2="462.20001" x2="180.7" y1="463.79999" x1="181.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3712" y2="463.79999" x2="181.10001" y1="465.29999" x1="181.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3714" y2="465.29999" x2="181.60001" y1="467.60001" x1="182.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3716" y2="467.60001" x2="182.3" y1="468.70001" x1="182.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3718" y2="468.70001" x2="182.60001" y1="469.79999" x1="183" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3720" y2="469.79999" x2="183" y1="470.79999" x1="183.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3722" y2="470.79999" x2="183.3" y1="471.79999" x1="183.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3724" y2="471.79999" x2="183.60001" y1="472.70001" x1="184" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3726" y2="472.70001" x2="184" y1="473.39999" x1="184.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3728" y2="473.39999" x2="184.3" y1="474.29999" x1="184.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3730" y2="474.29999" x2="184.60001" y1="474.79999" x1="184.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3732" y2="474.79999" x2="184.8" y1="475.5" x1="185.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3734" y2="475.5" x2="185.2" y1="476.10001" x1="185.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3736" y2="476.10001" x2="185.60001" y1="476.5" x1="185.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3738" y2="476.5" x2="185.89999" y1="476.79999" x1="186.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3740" y2="476.79999" x2="186.2" y1="477.10001" x1="186.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3742" y2="477.10001" x2="186.60001" y1="477.29999" x1="186.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3744" y2="477.29999" x2="186.89999" y1="477.39999" x1="187.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3746" y2="477.39999" x2="187.10001" y1="477.5" x1="187.39999" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3748" d="m 160.7,372.5 0.2,-0.3 c 0,0 0.3,-0.6 1.5,-0.9" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path3750" d="M 162.5,371.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3752" y2="374.5" x2="179.60001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3754" y2="374.5" x2="179.60001" y1="374.5" x1="179.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3756" y2="371.39999" x2="179.10001" y1="371" x1="179.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3758" y2="371" x2="179.2" y1="370.89999" x1="179.2" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline3760" points="179.2,370.9 179.3,370.8 180,370.7   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3762" y2="367" x2="197.8" y1="367" x1="197.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3764" y2="367" x2="197.8" y1="367" x1="198" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3766" y2="367" x2="198" y1="367" x1="198.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3768" y2="367" x2="198.10001" y1="367" x1="198.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3770" y2="367" x2="198.2" y1="367" x1="198.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3772" y2="367" x2="198.3" y1="367" x1="198.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3774" y2="367" x2="198.5" y1="367" x1="198.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3776" y2="367" x2="198.7" y1="367" x1="198.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3778" y2="367" x2="198.8" y1="367" x1="199" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3780" y2="367" x2="199" y1="367" x1="199.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3782" y2="367" x2="199.2" y1="367" x1="199.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3784" y2="367" x2="199.3" y1="367" x1="199.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3786" y2="367" x2="199.5" y1="367" x1="199.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3788" y2="367" x2="199.7" y1="367" x1="199.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3790" y2="367" x2="199.8" y1="367" x1="200" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3792" y2="367" x2="200" y1="367" x1="200.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3794" y2="367" x2="200.2" y1="367" x1="200.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3796" y2="367" x2="200.3" y1="367" x1="200.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3798" y2="367" x2="200.5" y1="367" x1="200.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3800" y2="367" x2="200.60001" y1="367" x1="200.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3802" y2="367" x2="200.8" y1="367" x1="200.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3804" y2="367" x2="200.89999" y1="367" x1="201.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3806" y2="367" x2="201.10001" y1="367" x1="201.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3808" y2="367" x2="201.2" y1="367" x1="201.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3810" y2="367" x2="201.39999" y1="367" x1="201.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3812" y2="367" x2="201.5" y1="367" x1="201.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3814" y2="367" x2="201.60001" y1="367" x1="201.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3816" y2="367" x2="201.8" y1="367" x1="201.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3818" y2="367" x2="201.89999" y1="367" x1="202" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3820" y2="367" x2="202" y1="367" x1="202.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3822" y2="367" x2="202.2" y1="367" x1="202.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3824" y2="367" x2="202.3" y1="367" x1="202.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3826" y2="367" x2="202.39999" y1="367" x1="202.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3828" y2="367" x2="202.5" y1="367" x1="202.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3830" y2="367" x2="202.60001" y1="367" x1="202.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3832" y2="367" x2="202.7" y1="367" x1="202.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3834" y2="367" x2="202.8" y1="367" x1="202.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3836" y2="367" x2="202.89999" y1="366.89999" x1="203" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3838" y2="366.89999" x2="203" y1="366.89999" x1="203" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3840" y2="366.89999" x2="203" y1="366.89999" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3842" y2="366.89999" x2="203.10001" y1="366.89999" x1="203.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3844" y2="375.5" x2="436.5" y1="375.5" x1="434" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3846" y2="375.5" x2="434" y1="375.5" x1="431.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3848" y2="375.5" x2="431.5" y1="375.5" x1="429" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3850" y2="375.5" x2="429" y1="375.5" x1="426.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3852" y2="375.5" x2="426.39999" y1="375.5" x1="423.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3854" y2="375.5" x2="423.89999" y1="375.60001" x1="421.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3856" y2="375.60001" x2="421.39999" y1="375.60001" x1="418.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3858" y2="375.60001" x2="418.89999" y1="375.60001" x1="416.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3860" y2="375.60001" x2="416.39999" y1="375.60001" x1="413.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3862" y2="375.60001" x2="413.89999" y1="375.70001" x1="411.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3864" y2="375.70001" x2="411.39999" y1="375.70001" x1="408.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3866" y2="375.70001" x2="408.79999" y1="375.70001" x1="406.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3868" y2="375.70001" x2="406.29999" y1="375.79999" x1="403.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3870" y2="375.79999" x2="403.79999" y1="375.89999" x1="401.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3872" y2="375.89999" x2="401.29999" y1="375.89999" x1="398.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3874" y2="375.89999" x2="398.70001" y1="376" x1="396.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3876" y2="376" x2="396.20001" y1="376.10001" x1="393.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3878" y2="376.10001" x2="393.60001" y1="376.20001" x1="391" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3880" y2="376.20001" x2="391" y1="376.29999" x1="388.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3882" y2="376.29999" x2="388.60001" y1="376.39999" x1="386.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3884" y2="376.39999" x2="386.10001" y1="376.5" x1="383.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3886" y2="376.5" x2="383.60001" y1="376.60001" x1="381.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3888" y2="376.60001" x2="381.10001" y1="376.70001" x1="378.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3890" y2="376.70001" x2="378.60001" y1="376.79999" x1="376.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3892" y2="376.79999" x2="376.10001" y1="376.89999" x1="373.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3894" y2="376.89999" x2="373.60001" y1="377" x1="371.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3896" y2="377" x2="371.10001" y1="377.10001" x1="368.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3898" y2="377.10001" x2="368.60001" y1="377.20001" x1="366.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3900" y2="377.20001" x2="366.10001" y1="377.29999" x1="363.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3902" y2="377.29999" x2="363.60001" y1="377.5" x1="361" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3904" y2="377.5" x2="361" y1="377.60001" x1="358.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3906" y2="377.60001" x2="358.5" y1="377.70001" x1="356" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3908" y2="377.70001" x2="356" y1="377.79999" x1="353.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3910" y2="377.79999" x2="353.5" y1="377.89999" x1="351" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3912" y2="377.89999" x2="351" y1="378" x1="348.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3914" y2="378" x2="348.5" y1="378.10001" x1="345.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3916" y2="378.10001" x2="345.89999" y1="378.29999" x1="343.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3918" y2="378.29999" x2="343.39999" y1="378.39999" x1="340.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3920" y2="378.39999" x2="340.89999" y1="378.5" x1="338.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3922" y2="378.5" x2="338.39999" y1="378.60001" x1="335.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3924" y2="378.60001" x2="335.89999" y1="378.70001" x1="333.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3926" y2="378.70001" x2="333.29999" y1="378.79999" x1="330.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3928" y2="378.79999" x2="330.79999" y1="378.89999" x1="328.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3930" y2="378.89999" x2="328.29999" y1="379" x1="325.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3932" y2="379" x2="325.79999" y1="379.10001" x1="323.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3934" y2="379.10001" x2="323.29999" y1="379.20001" x1="320.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3936" y2="379.20001" x2="320.79999" y1="379.29999" x1="318.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3938" y2="379.29999" x2="318.20001" y1="379.39999" x1="315.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3940" y2="379.39999" x2="315.70001" y1="379.5" x1="313.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3942" y2="379.5" x2="313.20001" y1="379.5" x1="310.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3944" y2="379.5" x2="310.70001" y1="379.60001" x1="308.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3946" y2="379.60001" x2="308.10001" y1="379.70001" x1="305.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3948" y2="379.70001" x2="305.60001" y1="379.70001" x1="303.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3950" y2="379.70001" x2="303.10001" y1="379.79999" x1="300.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3952" y2="379.79999" x2="300.60001" y1="379.79999" x1="298.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3954" y2="379.79999" x2="298.10001" y1="379.79999" x1="295.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3956" y2="379.79999" x2="295.5" y1="379.79999" x1="293" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3958" y2="379.79999" x2="293" y1="379.89999" x1="290.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3960" y2="379.89999" x2="290.5" y1="379.89999" x1="288" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3962" y2="379.89999" x2="288" y1="379.89999" x1="285.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3964" y2="379.89999" x2="285.5" y1="379.89999" x1="282.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3966" y2="379.89999" x2="282.89999" y1="379.89999" x1="280.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3968" y2="379.89999" x2="280.39999" y1="379.89999" x1="277.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3970" y2="379.89999" x2="277.89999" y1="379.89999" x1="275.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3972" y2="379.89999" x2="275.39999" y1="379.89999" x1="272.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3974" y2="379.89999" x2="272.79999" y1="379.89999" x1="270.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3976" y2="379.89999" x2="270.29999" y1="379.89999" x1="266.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3978" y2="379.89999" x2="266.39999" y1="379.89999" x1="262.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3980" y2="379.89999" x2="262.39999" y1="379.89999" x1="258.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3982" y2="379.89999" x2="258.5" y1="379.89999" x1="254.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3984" y2="379.89999" x2="254.5" y1="379.89999" x1="250.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3986" y2="379.89999" x2="250.5" y1="379.89999" x1="246.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3988" y2="379.89999" x2="246.60001" y1="379.89999" x1="242.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3990" y2="379.89999" x2="242.60001" y1="379.79999" x1="238.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3992" y2="379.79999" x2="238.7" y1="379.79999" x1="234.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3994" y2="379.79999" x2="234.7" y1="379.79999" x1="230.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3996" y2="379.79999" x2="230.8" y1="379.79999" x1="226.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line3998" y2="379.79999" x2="226.8" y1="379.79999" x1="222.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4000" y2="379.79999" x2="222.89999" y1="379.79999" x1="218.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4002" y2="379.79999" x2="218.89999" y1="379.79999" x1="214.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4004" y2="379.79999" x2="214.89999" y1="379.79999" x1="211" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4006" y2="379.79999" x2="211" y1="379.79999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4008" y2="362.60001" x2="289.70001" y1="362.60001" x1="289.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4010" y2="362.60001" x2="289.39999" y1="362.60001" x1="289.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4012" y2="362.60001" x2="289.10001" y1="362.60001" x1="288.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4014" y2="362.60001" x2="288.39999" y1="362.60001" x1="287.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4016" y2="362.60001" x2="287.10001" y1="362.60001" x1="285.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4018" y2="362.60001" x2="285.79999" y1="362.60001" x1="284.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4020" y2="362.60001" x2="284.5" y1="362.60001" x1="282" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4022" y2="362.60001" x2="282" y1="362.60001" x1="279.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4024" y2="362.60001" x2="279.39999" y1="362.60001" x1="276.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4026" y2="362.60001" x2="276.79999" y1="362.60001" x1="274.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4028" y2="362.60001" x2="274.20001" y1="362.60001" x1="271.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4030" y2="362.60001" x2="271.60001" y1="362.60001" x1="269.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4032" y2="362.60001" x2="269.10001" y1="362.60001" x1="266.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4034" y2="362.60001" x2="266.5" y1="362.60001" x1="263.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4036" y2="362.60001" x2="263.89999" y1="362.60001" x1="261.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4038" y2="362.60001" x2="261.29999" y1="362.60001" x1="258.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4040" y2="362.60001" x2="258.70001" y1="362.60001" x1="256.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4042" y2="362.60001" x2="256.20001" y1="362.60001" x1="253.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4044" y2="362.60001" x2="253.60001" y1="362.60001" x1="251" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4046" y2="362.60001" x2="251" y1="362.60001" x1="248.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4048" y2="362.60001" x2="248.39999" y1="362.60001" x1="245.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4050" y2="362.60001" x2="245.89999" y1="362.60001" x1="243.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4052" y2="362.60001" x2="243.3" y1="362.60001" x1="240.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4054" y2="362.60001" x2="240.7" y1="362.60001" x1="238.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4056" y2="362.60001" x2="238.10001" y1="362.60001" x1="235.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4058" y2="362.60001" x2="235.5" y1="362.60001" x1="233" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4060" y2="362.60001" x2="233" y1="362.60001" x1="230.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4062" y2="362.60001" x2="230.39999" y1="362.60001" x1="227.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4064" y2="362.60001" x2="227.8" y1="362.60001" x1="225.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4066" y2="362.60001" x2="225.2" y1="362.60001" x1="222.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4068" y2="362.60001" x2="222.60001" y1="362.60001" x1="220.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4070" y2="362.60001" x2="220.10001" y1="362.60001" x1="217.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4072" y2="362.60001" x2="217.5" y1="362.60001" x1="214.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4074" y2="362.60001" x2="214.89999" y1="362.60001" x1="212.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4076" y2="362.60001" x2="212.3" y1="362.60001" x1="209.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4078" y2="362.60001" x2="209.7" y1="362.60001" x1="207.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4080" y2="366.89999" x2="435.5" y1="366.89999" x1="437.20001" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline4082" points="436.5,375.5 438.1,375.5 438.1,366.9 437.2,366.9   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4084" y2="362.89999" x2="305" y1="362.89999" x1="306.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4086" y2="362.89999" x2="306.29999" y1="362.89999" x1="307.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4088" y2="362.60001" x2="291.5" y1="362.60001" x1="292.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4090" y2="362.60001" x2="292.10001" y1="362.70001" x1="293.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4092" y2="362.70001" x2="293.39999" y1="362.70001" x1="294.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4094" y2="362.70001" x2="294.70001" y1="362.70001" x1="297.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4096" y2="362.70001" x2="297.20001" y1="362.79999" x1="299.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4098" y2="362.79999" x2="299.79999" y1="362.79999" x1="302.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4100" y2="366.20001" x2="386.70001" y1="366.29999" x1="388.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4102" y2="366.29999" x2="388.60001" y1="366.29999" x1="390.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4104" y2="363" x2="309.10001" y1="363" x1="308.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4106" y2="363" x2="308.39999" y1="362.89999" x1="307.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4108" y2="362.89999" x2="305" y1="362.79999" x1="303.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4110" y2="362.79999" x2="303.70001" y1="362.79999" x1="302.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4112" y2="366.89999" x2="435.5" y1="366.89999" x1="433.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4114" y2="366.89999" x2="433.79999" y1="366.89999" x1="432" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4116" y2="366.89999" x2="432" y1="366.89999" x1="430.29999" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path4118" d="M 438.5,368.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4120" y2="368.5" x2="438.10001" y1="368.5" x1="434.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4122" y2="368.5" x2="434.20001" y1="368.60001" x1="432.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4124" y2="368.60001" x2="432.10001" y1="368.60001" x1="430" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4126" y2="368.60001" x2="430" y1="368.60001" x1="425.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4128" y2="368.60001" x2="425.70001" y1="368.60001" x1="421.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4130" y2="368.60001" x2="421.5" y1="368.5" x1="419" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4132" y2="368.5" x2="419" y1="368.5" x1="416.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4134" y2="368.5" x2="416.39999" y1="368.5" x1="413.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4136" y2="368.5" x2="413.89999" y1="368.5" x1="411.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4138" y2="368.5" x2="411.39999" y1="368.39999" x1="408.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4140" y2="368.39999" x2="408.89999" y1="368.39999" x1="406.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4142" y2="368.39999" x2="406.29999" y1="368.39999" x1="403.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4144" y2="368.39999" x2="403.79999" y1="368.29999" x1="401.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4146" y2="368.29999" x2="401.29999" y1="368.20001" x1="398.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4148" y2="368.20001" x2="398.70001" y1="368.20001" x1="396.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4150" y2="368.20001" x2="396.20001" y1="368.10001" x1="393.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4152" y2="368.10001" x2="393.60001" y1="368" x1="391" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4154" y2="368" x2="391" y1="367.89999" x1="388.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4156" y2="367.89999" x2="388.5" y1="367.79999" x1="386" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4158" y2="367.79999" x2="386" y1="367.70001" x1="383.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4160" y2="367.70001" x2="383.5" y1="367.60001" x1="381" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4162" y2="367.60001" x2="381" y1="367.5" x1="378.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4164" y2="367.5" x2="378.5" y1="367.39999" x1="376" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4166" y2="367.39999" x2="376" y1="367.29999" x1="373.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4168" y2="367.29999" x2="373.60001" y1="367.20001" x1="371.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4170" y2="367.20001" x2="371.10001" y1="367.10001" x1="368.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4172" y2="367.10001" x2="368.5" y1="367" x1="366" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4174" y2="367" x2="366" y1="366.89999" x1="363.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4176" y2="366.89999" x2="363.5" y1="366.79999" x1="361" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4178" y2="366.79999" x2="361" y1="366.70001" x1="358.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4180" y2="366.70001" x2="358.5" y1="366.60001" x1="355.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4182" y2="366.60001" x2="355.89999" y1="366.39999" x1="353.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4184" y2="366.39999" x2="353.39999" y1="366.29999" x1="350.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4186" y2="366.29999" x2="350.89999" y1="366.20001" x1="348.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4188" y2="366.20001" x2="348.39999" y1="366.10001" x1="345.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4190" y2="366.10001" x2="345.89999" y1="366" x1="343.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4192" y2="366" x2="343.29999" y1="365.89999" x1="340.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4194" y2="365.89999" x2="340.79999" y1="365.79999" x1="338.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4196" y2="365.79999" x2="338.29999" y1="365.70001" x1="335.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4198" y2="365.70001" x2="335.79999" y1="365.60001" x1="333.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4200" y2="365.60001" x2="333.29999" y1="365.5" x1="330.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4202" y2="365.5" x2="330.79999" y1="365.29999" x1="328.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4204" y2="365.29999" x2="328.20001" y1="365.20001" x1="325.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4206" y2="365.20001" x2="325.70001" y1="365.10001" x1="323.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4208" y2="365.10001" x2="323.20001" y1="365" x1="320.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4210" y2="365" x2="320.70001" y1="365" x1="318.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4212" y2="365" x2="318.20001" y1="364.89999" x1="315.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4214" y2="364.89999" x2="315.60001" y1="364.79999" x1="313.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4216" y2="364.79999" x2="313.10001" y1="364.70001" x1="310.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4218" y2="364.70001" x2="310.60001" y1="364.60001" x1="308.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4220" y2="364.60001" x2="308.10001" y1="364.60001" x1="305.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4222" y2="364.60001" x2="305.60001" y1="364.5" x1="303.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4224" y2="364.5" x2="303.10001" y1="364.5" x1="300.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4226" y2="364.5" x2="300.5" y1="364.39999" x1="298" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4228" y2="364.39999" x2="298" y1="364.39999" x1="295.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4230" y2="364.39999" x2="295.5" y1="364.39999" x1="293" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4232" y2="364.39999" x2="293" y1="364.39999" x1="290.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4234" y2="364.39999" x2="290.5" y1="364.39999" x1="288" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4236" y2="364.39999" x2="288" y1="364.39999" x1="285.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4238" y2="364.39999" x2="285.39999" y1="364.39999" x1="282.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4240" y2="364.39999" x2="282.89999" y1="364.39999" x1="280.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4242" y2="364.39999" x2="280.39999" y1="364.39999" x1="277.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4244" y2="364.39999" x2="277.89999" y1="364.39999" x1="275.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4246" y2="364.39999" x2="275.39999" y1="364.39999" x1="272.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4248" y2="364.39999" x2="272.79999" y1="364.39999" x1="270.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4250" y2="364.39999" x2="270.29999" y1="364.39999" x1="266.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4252" y2="364.39999" x2="266.39999" y1="364.39999" x1="262.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4254" y2="364.39999" x2="262.39999" y1="364.39999" x1="258.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4256" y2="364.39999" x2="258.39999" y1="364.39999" x1="254.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4258" y2="364.39999" x2="254.5" y1="364.39999" x1="250.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4260" y2="364.39999" x2="250.5" y1="364.39999" x1="246.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4262" y2="364.39999" x2="246.60001" y1="364.39999" x1="242.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4264" y2="364.39999" x2="242.60001" y1="364.39999" x1="238.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4266" y2="364.39999" x2="238.7" y1="364.39999" x1="234.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4268" y2="364.39999" x2="234.7" y1="364.29999" x1="230.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4270" y2="364.29999" x2="230.8" y1="364.29999" x1="226.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4272" y2="364.29999" x2="226.8" y1="364.29999" x1="222.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4274" y2="364.29999" x2="222.8" y1="364.29999" x1="218.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4276" y2="364.29999" x2="218.89999" y1="364.29999" x1="214.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4278" y2="364.29999" x2="214.89999" y1="364.29999" x1="211" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4280" y2="364.29999" x2="211" y1="364.29999" x1="207" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path4282" d="M 438.4,367.1" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4284" y2="367.10001" x2="438.10001" y1="367.10001" x1="436.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4286" y2="367.10001" x2="436.29999" y1="367.10001" x1="434.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4288" y2="367.10001" x2="434.20001" y1="367.10001" x1="432" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4290" y2="367.10001" x2="432" y1="367.10001" x1="429.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4292" y2="367.10001" x2="429.89999" y1="367.10001" x1="427.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4294" y2="367.10001" x2="427.79999" y1="367.10001" x1="425.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4296" y2="367.10001" x2="425.70001" y1="367.10001" x1="423.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4298" y2="367.10001" x2="423.5" y1="367.10001" x1="421.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4300" y2="367.10001" x2="421.39999" y1="367.10001" x1="418.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4302" y2="367.10001" x2="418.89999" y1="367.10001" x1="416.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4304" y2="367.10001" x2="416.39999" y1="367.10001" x1="413.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4306" y2="367.10001" x2="413.89999" y1="367" x1="411.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4308" y2="367" x2="411.39999" y1="367" x1="408.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4310" y2="367" x2="408.79999" y1="367" x1="406.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4312" y2="367" x2="406.29999" y1="366.89999" x1="403.79999" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline4314" points="401.3,366.9 401.5,366.9 403.8,366.9   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4316" y2="366.89999" x2="401.29999" y1="366.79999" x1="398.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4318" y2="366.79999" x2="398.70001" y1="366.70001" x1="396.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4320" y2="366.70001" x2="396.20001" y1="366.60001" x1="393.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4322" y2="366.60001" x2="393.60001" y1="366.60001" x1="391" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4324" y2="366.60001" x2="391" y1="366.5" x1="388.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4326" y2="366.5" x2="388.60001" y1="366.39999" x1="386.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4328" y2="366.39999" x2="386.10001" y1="366.29999" x1="383.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4330" y2="366.29999" x2="383.60001" y1="366.20001" x1="381.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4332" y2="366.20001" x2="381.10001" y1="366.10001" x1="378.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4334" y2="366.10001" x2="378.60001" y1="366" x1="376.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4336" y2="366" x2="376.10001" y1="365.89999" x1="373.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4338" y2="365.89999" x2="373.60001" y1="365.79999" x1="371.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4340" y2="365.79999" x2="371.10001" y1="365.70001" x1="368.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4342" y2="365.70001" x2="368.60001" y1="365.5" x1="366.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4344" y2="365.5" x2="366.10001" y1="365.39999" x1="363.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4346" y2="365.39999" x2="363.60001" y1="365.29999" x1="361" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4348" y2="365.29999" x2="361" y1="365.20001" x1="358.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4350" y2="365.20001" x2="358.5" y1="365.10001" x1="356" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4352" y2="365.10001" x2="356" y1="365" x1="353.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4354" y2="365" x2="353.5" y1="364.89999" x1="351" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4356" y2="364.89999" x2="351" y1="364.79999" x1="348.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4358" y2="364.79999" x2="348.5" y1="364.70001" x1="345.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4360" y2="364.70001" x2="345.89999" y1="364.5" x1="343.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4362" y2="364.5" x2="343.39999" y1="364.39999" x1="340.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4364" y2="364.39999" x2="340.89999" y1="364.29999" x1="338.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4366" y2="364.29999" x2="338.39999" y1="364.20001" x1="335.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4368" y2="364.20001" x2="335.89999" y1="364.10001" x1="333.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4370" y2="364.10001" x2="333.29999" y1="364" x1="330.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4372" y2="364" x2="330.79999" y1="363.89999" x1="328.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4374" y2="363.89999" x2="328.29999" y1="363.79999" x1="325.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4376" y2="363.79999" x2="325.79999" y1="363.70001" x1="323.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4378" y2="363.70001" x2="323.29999" y1="363.60001" x1="320.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4380" y2="363.60001" x2="320.79999" y1="363.5" x1="318.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4382" y2="363.5" x2="318.20001" y1="363.39999" x1="315.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4384" y2="363.39999" x2="315.70001" y1="363.29999" x1="313.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4386" y2="363.29999" x2="313.20001" y1="363.29999" x1="310.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4388" y2="363.29999" x2="310.70001" y1="363.20001" x1="308.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4390" y2="363.20001" x2="308.10001" y1="363.10001" x1="305.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4392" y2="363.10001" x2="305.60001" y1="363.10001" x1="303.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4394" y2="363.10001" x2="303.10001" y1="363" x1="300.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4396" y2="363" x2="300.60001" y1="363" x1="298.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4398" y2="363" x2="298.10001" y1="363" x1="295.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4400" y2="363" x2="295.5" y1="362.89999" x1="293" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4402" y2="362.89999" x2="293" y1="362.89999" x1="290.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4404" y2="362.89999" x2="290.5" y1="362.89999" x1="288" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4406" y2="362.89999" x2="288" y1="362.89999" x1="285.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4408" y2="362.89999" x2="285.5" y1="362.89999" x1="282.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4410" y2="362.89999" x2="282.89999" y1="362.89999" x1="280.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4412" y2="362.89999" x2="280.39999" y1="362.89999" x1="277.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4414" y2="362.89999" x2="277.89999" y1="362.89999" x1="275.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4416" y2="362.89999" x2="275.39999" y1="362.89999" x1="272.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4418" y2="362.89999" x2="272.79999" y1="362.89999" x1="270.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4420" y2="362.89999" x2="270.29999" y1="362.89999" x1="268.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4422" y2="362.89999" x2="268.29999" y1="362.89999" x1="266.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4424" y2="362.89999" x2="266.39999" y1="362.89999" x1="264.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4426" y2="362.89999" x2="264.39999" y1="362.89999" x1="262.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4428" y2="362.89999" x2="262.39999" y1="362.89999" x1="260.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4430" y2="362.89999" x2="260.39999" y1="362.89999" x1="258.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4432" y2="362.89999" x2="258.5" y1="362.89999" x1="256.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4434" y2="362.89999" x2="256.5" y1="362.89999" x1="254.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4436" y2="362.89999" x2="254.5" y1="362.89999" x1="252.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4438" y2="362.89999" x2="252.5" y1="362.89999" x1="250.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4440" y2="362.89999" x2="250.5" y1="362.89999" x1="248.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4442" y2="362.89999" x2="248.60001" y1="362.89999" x1="246.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4444" y2="362.89999" x2="246.60001" y1="362.89999" x1="244.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4446" y2="362.89999" x2="244.60001" y1="362.89999" x1="242.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4448" y2="362.89999" x2="242.60001" y1="362.89999" x1="240.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4450" y2="362.89999" x2="240.7" y1="362.89999" x1="238.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4452" y2="362.89999" x2="238.7" y1="362.89999" x1="236.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4454" y2="362.89999" x2="236.7" y1="362.89999" x1="234.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4456" y2="362.89999" x2="234.7" y1="362.89999" x1="232.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4458" y2="362.89999" x2="232.7" y1="362.89999" x1="230.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4460" y2="362.89999" x2="230.8" y1="362.89999" x1="228.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4462" y2="362.89999" x2="228.8" y1="362.89999" x1="226.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4464" y2="362.89999" x2="226.8" y1="362.89999" x1="224.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4466" y2="362.89999" x2="224.8" y1="362.89999" x1="222.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4468" y2="362.89999" x2="222.89999" y1="362.89999" x1="220.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4470" y2="362.89999" x2="220.89999" y1="362.89999" x1="218.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4472" y2="362.89999" x2="218.89999" y1="362.89999" x1="216.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4474" y2="362.89999" x2="216.89999" y1="362.89999" x1="214.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4476" y2="362.89999" x2="214.89999" y1="362.89999" x1="213" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4478" y2="362.89999" x2="213" y1="362.89999" x1="211" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4480" y2="362.89999" x2="211" y1="362.89999" x1="209" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4482" y2="362.89999" x2="209" y1="362.89999" x1="207" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4484" y2="363" x2="309.10001" y1="363" x1="309.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4486" y2="363" x2="309.79999" y1="363" x1="310.20001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4488" y2="363" x2="310.20001" y1="363" x1="310.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4490" y2="363" x2="310.29999" y1="363" x1="310.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4492" y2="363" x2="310.5" y1="363" x1="310.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4494" y2="363" x2="310.89999" y1="363" x1="311.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4496" y2="363" x2="311.29999" y1="363.10001" x1="312" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4498" y2="366.39999" x2="392.5" y1="366.5" x1="394.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4500" y2="366.5" x2="394.39999" y1="366.5" x1="396.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4502" y2="366.5" x2="396.29999" y1="366.60001" x1="399.20001" class="st0" />
        <polyline style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="polyline4504" points="402.1,366.7 401.5,366.6 399.2,366.6   " class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4506" y2="366.70001" x2="402.10001" y1="366.70001" x1="405" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4508" y2="366.70001" x2="405" y1="366.79999" x1="407.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4510" y2="366.79999" x2="407.89999" y1="366.79999" x1="409.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4512" y2="366.79999" x2="409.79999" y1="366.79999" x1="411.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4514" y2="366.79999" x2="411.70001" y1="366.79999" x1="413.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4516" y2="366.79999" x2="413.70001" y1="366.79999" x1="415.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4518" y2="366.79999" x2="415.60001" y1="366.89999" x1="417.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4520" y2="366.89999" x2="417.5" y1="366.89999" x1="419.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4522" y2="366.89999" x2="419.5" y1="366.89999" x1="421.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4524" y2="366.89999" x2="421.39999" y1="366.89999" x1="423.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4526" y2="366.89999" x2="423.29999" y1="366.89999" x1="425.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4528" y2="366.89999" x2="425.10001" y1="366.89999" x1="426.79999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4530" y2="366.89999" x2="426.79999" y1="366.89999" x1="428.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4532" y2="366.89999" x2="428.5" y1="366.89999" x1="430.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4534" y2="362.60001" x2="291.5" y1="362.60001" x1="290.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4536" y2="362.60001" x2="290.89999" y1="362.60001" x1="290.29999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4538" y2="362.60001" x2="290.29999" y1="362.60001" x1="290" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4540" y2="362.60001" x2="290" y1="362.60001" x1="289.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4542" y2="362.60001" x2="289.89999" y1="362.60001" x1="289.70001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4544" y2="367.10001" x2="197.8" y1="367.10001" x1="198" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4546" y2="367.10001" x2="198" y1="367.10001" x1="198.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4548" y2="367.10001" x2="198.10001" y1="367.10001" x1="198.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4550" y2="367.10001" x2="198.2" y1="367.10001" x1="198.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4552" y2="367.10001" x2="198.39999" y1="367.10001" x1="198.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4554" y2="367.10001" x2="198.5" y1="367.10001" x1="198.7" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4556" y2="367.10001" x2="198.7" y1="367.10001" x1="198.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4558" y2="367.10001" x2="198.8" y1="367.10001" x1="198.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4560" y2="367.10001" x2="198.89999" y1="367.10001" x1="199.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4562" y2="367.10001" x2="199.10001" y1="367.10001" x1="199.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4564" y2="367.10001" x2="199.2" y1="367.10001" x1="199.39999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4566" y2="367.10001" x2="199.39999" y1="367.10001" x1="199.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4568" y2="367.10001" x2="199.5" y1="367.10001" x1="199.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4570" y2="367.10001" x2="199.60001" y1="367.10001" x1="199.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4572" y2="367.10001" x2="199.8" y1="367.10001" x1="199.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4574" y2="367.10001" x2="199.89999" y1="367.10001" x1="200.10001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4576" y2="367.10001" x2="200.10001" y1="367.10001" x1="200.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4578" y2="367.10001" x2="200.2" y1="367.10001" x1="200.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4580" y2="367.10001" x2="200.3" y1="367.10001" x1="200.5" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4582" y2="367.10001" x2="200.5" y1="367.10001" x1="200.60001" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4584" y2="367.10001" x2="200.60001" y1="367.10001" x1="200.8" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4586" y2="367.10001" x2="200.8" y1="367.10001" x1="200.89999" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4588" y2="367.10001" x2="200.89999" y1="367.10001" x1="201" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4590" y2="367.10001" x2="201" y1="367.10001" x1="201.2" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4592" y2="367.10001" x2="201.2" y1="367.10001" x1="201.3" class="st0" />
        <line style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="line4594" y2="367.10001" x2="201.3" y1="367.10001" x1="201.5" class="st0" />
        <path style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          " id="path4596" d="m 160.2,370.2 c 0,0 0.4,1.1 1.4,1.1 l 0.9,-0.1" class="st0" />
      </g>
      <path style="
          fill: none;
          stroke: #030304;
          stroke-width: 0.15000001;
          stroke-linecap: round;
          stroke-linejoin: round;
        " id="path4600" d="M 364.4,387" class="st0" />
    </g>
    <path style="
        fill: none;
        stroke: #000000;
        stroke-width: 1px;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-opacity: 1;
        fill-opacity: 1;
        opacity: 0;
      " d="M 53.733556,15.155897 H 218.5016" id="asta-sx-ext-path" inkscape:connector-curvature="0" />
    <image class="astaColorImg"
      :href="`https://cdn.thema-optical.com/3d-models/colori_acetato_sito/${astaColor}.jpg`" />
    <defs>
      <clipPath id="myImg">
        <path
          d="M 50.77534,12.606054 V 5.1753461 l 44.716258,0.1136286 c 24.593952,0.062496 48.575342,0.2013058 53.291982,0.3084661 8.33984,0.1894763 22.84706,0.7993686 49.47532,2.0799724 11.25971,0.5415019 22.24771,0.9898807 45.98848,1.876615 3.00621,0.1122836 12.75737,0.2594651 21.66925,0.3270686 8.91188,0.067608 16.22919,0.1515342 16.26071,0.1865132 0.0315,0.03498 -0.008,1.089089 -0.0886,2.342469 l -0.1459,2.278872 -1.97901,0.131274 c -1.08846,0.07219 -5.96531,0.145614 -10.83745,0.163142 -13.38084,0.04814 -32.23814,0.557695 -46.64816,1.260515 -11.64309,0.56787 -20.10669,0.969078 -48.81564,2.314054 -22.39146,1.04901 -33.19718,1.226999 -82.977165,1.36677 L 50.77534,20.036765 Z"
          id="path26616" />
      </clipPath>
    </defs>
    <text id="text41">
      <textPath class="asta-sx-ext-text"
        :class="{ white: colore != null && colore.name == 'bianco', nocolore: colore != null && colore.name == 'nessun-colore'}"
        xlink:href="#asta-sx-ext-path" id="textPath39"
        :style="{ fill: colore ? colore.colorCode : '#000000', fontFamily: font }">{{
        name.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
        '') }}</textPath>
    </text>
  </svg>
</template>

<script>
  export default {
    props: ["name", "colore", "font", "astaColor"],
  };
</script>

<style>
  .astaColorImg {
    clip-path: url(#myImg);
  }
  .st0 {
    fill: none;
    stroke: #020203;
    stroke-width: 0.15;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .white {
    text-shadow: 0 0 0px black;
  }
  .nocolore {
    text-shadow: 0 0 2px black;
  }
  .asta-sx-ext-text {
    font-size: 10px;
  }
</style>
